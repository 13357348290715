import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Text from "@shared/ui/Text";
import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import Info from "@shared/ui/Info";


const AllStats = () => {
    return <Hbox wrap customJust="start" gap="10px">
        <GlassCard width="fit-content">
            <Text weight="bold">Активные пользователи</Text>
            <ElementCard>
                <Vbox gap="5px">
                    <Text>Агенты - 5 из 8 приглашенных</Text>
                    <Text>Трейдеры - 2 из 10 приглашенных</Text>
                    <Text>Мерчанты - 1 из 3 приглашенных</Text>
                </Vbox>
            </ElementCard>
        </GlassCard>
        <GlassCard>
            <Text weight="bold">Сводка по доходам</Text>
            <ElementCard>
                <Hbox gap="10px">
                    <Info title="Доход за день" value="12 USDT" />
                    <Info title="Доход за месяц" value="232 USDT" />
                    <Info title="Доход за год" value="2342 USDT" />
                </Hbox>
            </ElementCard>
        </GlassCard>
    </Hbox>
}

export default AllStats;