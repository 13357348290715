import React, { useState, useEffect, useMemo } from 'react';
import HBox from '@shared/ui/layouts/Hbox';
import SimpleButton from '@shared/ui/buttons/SimpleBtn';
import CreateShopModal from '@features/CreateShopModal';
import ChangeShopModal from '@features/ChangeShopModal';
import Table from "@shared/ui/Table";
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useModal } from '@shared/ModalContext';
import makeRequest from '@shared/api/makeRequest';
import Pagination from "@shared/ui/Pagination";
import PagePreloader from './elements/PagePreloader';
import getColumns from './lib/getColumns';
import RangePicker from "@shared/ui/RangePicker";
import Select from "@shared/ui/Select";

const WithdrawlHistory = () => {
    const today = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);
    const [transactions, setTransactions] = useState([]);
    const [shops, setShops] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(formatDate(oneWeekAgo));
    const [endDate, setEndDate] = useState(formatDate(today));
    const [status, setStatus] = useState('in_processing')
    const [shop, setShop] = useState(null)
    const statuses = [
        { label: 'Завершенные', value: 'completed' },
        { label: 'Отклонено', value: 'canceled' },
        { label: 'В процессе', value: 'in_processing' }
    ]
    useEffect(() => {
        getHistory()
    }, [currentPage, shop, status, startDate, endDate]);
    // useEffect(getShops, [])
    const columns = useMemo(() => getColumns(statuses), []);
    
    return (
        <PageWrapper ContentPreloader={PagePreloader} loading={loading} title="История выводов">
            <HBox alignItems='end' gap='10px'>
                <HBox gap='10px'>
                    <Select options={statuses} onChange={setStatus} value={status} />
                </HBox>
                <div><RangePicker label="Период" onChange={(e) => {setStartDate(e ? formatDate(e[0]?.$d) : null); setEndDate(e ? formatDate(e[1]?.$d) : null)}} /></div>
            </HBox>
            <Table loading={loading} columns={columns} dataSource={transactions} />
            <HBox customJust="center">{totalPages > 1 && <Pagination total={totalPages} onChange={setCurrentPage} />}</HBox>
        </PageWrapper>
    );

    function getHistory() { // Начинаем с 0 для синхронизации с react-paginate
        (async () => {
            setLoading(true);
            try {
                const data = await makeRequest(`/api/v1/wallets/withdrawl/withdrawals?page=${currentPage}&status=${status}&start_date=${startDate}&end_date=${endDate}`, {
                    method: 'GET'
                });
                setTransactions(data.withdrawals.map(el => ({...el})));
                setTotalPages(data.total_pages);
            } catch (error) {
                console.error('Ошибка при получении транзакции:', error);
                setTransactions([]);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            }
        })();
    };

    // function getShops() { // Начинаем с 0 для синхронизации с react-paginate
    //     (async () => {
    //         setLoading(true);
    //         try {
    //             const data = await makeRequest(`/api/v1/wallets/balance/shops`, {
    //                 method: 'GET'
    //             });
    //             const shopOptions = data.data.map(el => ({ label: el.name, value: el.shop_id }));
    //             setShops(shopOptions);
    //             if (shopOptions.length > 0) {
    //                 setShop(shopOptions[0]); // Устанавливаем первый элемент по умолчанию
    //             }
    //         } catch (error) {
    //             console.error('Ошибка при получении магазинов:', error);
    //         } finally {
    //             setTimeout(() => {
    //                 setLoading(false);
    //             }, 500);
    //         }
    //     })();
    // };
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
};

export default WithdrawlHistory;