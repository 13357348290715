import React, { useState, useEffect } from 'react';
import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import { useNavigate } from 'react-router-dom';
import Text from "@shared/ui/Text";
import InfoCard from '@shared/ui/InfoCard';
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import GlassCard from "@shared/ui/GlassCard";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import ElementCard from "@shared/ui/ElementCard";
import Pagination from "@shared/ui/Pagination";
import Info from "@shared/ui/Info";
import PagePreloader from './elements/PagePreloader';
import { DollarOutlined, WalletOutlined } from '@ant-design/icons';
import makeRequest from "@shared/api/makeRequest";
import {ThreeColumns} from '@shared/ui/containers/ListContainers';


const Assets = () => {
    const [balance, setBalance] = useState(0);
    const [shops, setShops] = useState([]);
    const [balanceLoading, setBalanceLoading] = useState(true);
    const [shopsLoading, setShopsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const itemsPerPage = 6;  // 3 items per row, 2 rows per page

    useEffect(fetchShops, [currentPage]);
    useEffect(fetchBalance, []);

    const handleDepositClick = (id) => {
        if (id) {
            console.log(id)
            navigate(`/deposit/shop/${id}`); // Переход на страницу депозита с shopId
        }
    };

    return (
        <PageWrapper ContentPreloader={PagePreloader} loading={shopsLoading || balanceLoading} title="Активы">
            <HBox customJust="end" gap="10px">
                <SimpleButton main icon={<WalletOutlined />} onClick={() => navigate('/assets/withdrawl')}>Вывод</SimpleButton>
                <SimpleButton main icon={<DollarOutlined />} onClick={() => navigate('/assets/internal_transfers')}>Внутренние переводы</SimpleButton>
            </HBox>
            <GlassCard>
                <Text weight="bold">Основные балансы</Text>
                <ThreeColumns>
                    <InfoCard currency="USDT" title="Общий баланс" value={balance}/>
                </ThreeColumns>
            </GlassCard>
            <GlassCard>
                <Text weight="bold">Магазины</Text>
                <VBox width='auto' gap='20px'>
                    <ThreeColumns>
                        {shops.map(({name, id, balance, payin, payout}) => {
                            return <ElementCard key={id}>
                                <Text weight="bold" size="subheader">{name}</Text>
                                <HBox wrap customJust="center" width="100%" gap="40px">
                                    <div><Info
                                        title="Баланс"
                                        value={balance.amount}
                                    /></div>
                                    <div><Info 
                                        title="Payin"
                                        value={payin}
                                    /></div>
                                    <div><Info 
                                        title="Payout"
                                        value={payout}
                                    /></div>
                                </HBox>
                                <SimpleButton onClick={() => handleDepositClick(id)}>Пополнение</SimpleButton>
                            </ElementCard>
                        })}
                    </ThreeColumns>
                </VBox>
                <Pagination total={totalPages} onChange={setCurrentPage} />
            </GlassCard>
        </PageWrapper>  
    );

    function fetchBalance() {
        (async () => {
            try {
                setBalanceLoading(true);

                const {overall_statistics} = await makeRequest(`/api/v1/wallets/balance/shops`, {
                    method: "GET"
                });
    
                setBalance(overall_statistics.total_balance);
            } catch (error) {
                console.log(error)
            } finally {
                setBalanceLoading(false);
            }
        })();
    }

    function fetchShops() {
        (async () => {
            setShopsLoading(true);

            try {
                const {data, total_pages} = await makeRequest(`/api/v1/core/shop/?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: "GET"
                });
                setShops(data);
                setTotalPages(total_pages);
            } catch (error) {
                console.error('Ошибка при получении магазинов:', error);
                setShops([]);
            } finally {
                setShopsLoading(false);
            }
        })();     
    }

    
};

export default Assets;
