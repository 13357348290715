import PageWrapper from "@shared/ui/layouts/PageWrapper";
import Table from "@shared/ui/Table";
import Pagination from "@shared/ui/Pagination";
import { useEffect, useMemo, useState } from "react";
import makeRequest from "@shared/api/makeRequest";
import { useNavigate } from "react-router-dom";
import getBanksApi from "@shared/api/services/getBanksApi";
import getPaymentMethodsApi from "@shared/api/services/getPaymentMethodsApi";
import getFiatCurrenciesApi from "@shared/api/services/getFiatCurrenciesApi";


const columns = [{title: 'ID', dataIndex: 'id'}, { title: "Банк", dataIndex: "bank" }, { title: "Валюта", dataIndex: "currency" }, { title: "Метод оплаты", dataIndex: "payment_method" }];


const PaymentsSettings = () => {
    const [loading, setLoading] = useState(false);
    const [tradingMethods, setTradingMethods] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [banks, setBanks] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate();
    useEffect(fetchTradingMethods, [currentPage]);
    useEffect(fetchPaymentMethods, []);
    useEffect(fetchCurrencies, []);
    useEffect(fetchBanks, []);

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/settings")}}>Настройки</a> }
    ]

    const items = useMemo(() => {
        return tradingMethods.map(({payment_method_id, bank_id, currency_id, ...rest}) => ({
            bank: banks.find(el => el.id === bank_id)?.name || "-",
            payment_method: paymentMethods.find(el => el.id === payment_method_id)?.name || "-",
            currency: currencies.find(el => el.id === currency_id)?.currency || "-",
            ...rest
        }))
    }, [banks, tradingMethods, paymentMethods, currencies]);

    return <PageWrapper breadcrumbs={breadcrumbs} title="Настройки способов оплаты">
        <Table loading={loading} columns={columns} dataSource={items} />
        <Pagination total={totalPages} onChange={setCurrentPage} />
    </PageWrapper>

    function fetchTradingMethods() {
        (async () => {
            try {
                setLoading(true);

                const {data, total_pages} = await makeRequest(`/api/v1/core/trading_methods/?page=${currentPage}`, { method: "GET" });

                setTradingMethods(data);
                setTotalPages(total_pages);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }

    function fetchBanks() {
        (async () => {
            try {
                const banks = await getBanksApi();

                setBanks(banks);
            } catch(e) {
                console.log(e);
            }
        })();
    }

    function fetchPaymentMethods() {
        (async () => {
            try {
                const paymentMethods = await getPaymentMethodsApi();

                setPaymentMethods(paymentMethods);
            } catch(e) {
                console.log(e)
            }
        })();
    }

    function fetchCurrencies() {
        (async () => {
            try {
                const currencies = await getFiatCurrenciesApi();

                console.log(currencies)

                setCurrencies(currencies);
            } catch(e) {
                console.log(e);
            }
        })();
    }
}

export default PaymentsSettings;