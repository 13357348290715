import { useEffect, useMemo, useState } from "react";
import getUnlinkedsUsersApi from "@shared/api/services/admin/assosiations/getUnlinkedUsersApi";
import Vbox from "@shared/ui/layouts/Vbox";
import SimpleInput from "@shared/ui/Simpleinput";
import Text from "@shared/ui/Text";
import Hbox from "@shared/ui/layouts/Hbox";
import Table from "@shared/ui/Table";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { Controller, useForm } from "react-hook-form";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import createAssosiationApi from "@shared/api/services/admin/assosiations/createAssosiationApi";
import {useModal} from "@shared/ModalContext";
import Pagination from "@shared/ui/Pagination";
import { CheckOutlined } from "@ant-design/icons";


const getColumns = (role, openCreateAssosiationModal) => {
    const columns = [
        {
            title: "Имя пользователя",
            dataIndex: "name",
        },
        {
            title: "Полное имя",
            dataIndex: "fullname",
        },
        {
            title: "Почта",
            dataIndex: "email"
        }
    ]
    if(role === "shop") {
        columns.push({
            title: "Мерчант",
            dataIndex: "username",
            key: "username"
        })
    }

    return [...columns, {
        title: "",
        dataIndex: "action",
        key: "action",
        width: "1px",
        render: (_, {entity_id, name, fullname}) => <SimpleButton onClick={() => {openCreateAssosiationModal(entity_id, name || fullname)}}>Привязать</SimpleButton>
    }]
}

// Страница для просмотра привязанных Трейдеров и (или) Шопов к агенту, чей id указан в квери
const Unlinkeds = ({agent_id, agentName, currentRole, searchValue}) => {
    const [unlinkeds, setUnlinkeds] = useState([]);
    const [loading, setLoading] = useState(true);
    const {openModal} = useModal();
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const columns = useMemo(() => getColumns(currentRole, openCreateAssosiationModal), [currentRole, openCreateAssosiationModal]); 

    useEffect(fetchUnlinkedsByRole, [currentRole, searchValue, currentPage]);

    return <Vbox gap="10px">
        <Table loading={loading} columns={columns} dataSource={unlinkeds} />
        <Hbox customJust="center"><Pagination total={totalPages} onChange={setCurrentPage} /></Hbox>
    </Vbox>
    
    
    function fetchUnlinkedsByRole() {
        (async () => {
            if(!currentRole) return;

            setLoading(true);
            try {
                const {data, total_pages} = await getUnlinkedsUsersApi({user_id: agent_id, role: currentRole, name: searchValue, page: currentPage});

                setUnlinkeds(data);
                setTotalPages(total_pages);

            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }

    function openCreateAssosiationModal(entity_id, entityName) {
        openModal(<CreateAssosiationModal agentName={agentName} agent_id={agent_id} entity_id={entity_id} entityName={entityName} type={currentRole} onSubmit={fetchUnlinkedsByRole} />, "Создание связи")
    }
}

function CreateAssosiationModal({entityName, agent_id, entity_id, type, agentName, closeModal, onSubmit}) {
    const { handleSubmit, control, formState: { errors } } = useForm();

    return <form onSubmit={handleSubmit(createAssosiation)}>
        <Vbox gap="10px">
            <Text>Подтвердите создание связи между</Text>
            <Text>Агент: <Text weight="bold">{agentName}</Text></Text>
            <Text>Пользователь: <Text weight="bold">{entityName}</Text></Text>
            <Hbox gap="10px">
                <Controller
                    name="payin"
                    control={control}
                    rules={{
                        required: "Обязательное поле",
                        validate: (value) => {
                            if (value <= 0) return "Значение должно быть больше нуля";
                            if (value > 100) return "Максимум 100 процентов";
                            return true;
                        },
                    }}
                    render={({ field }) => (
                        <SimpleInput
                            {...field}
                            label="Payin"
                            error={errors.payin?.message}
                            number
                            min={0}
                            max={100}
                            prefix="%"
                            placeholder="4"
                        />
                    )}
                />
                <Controller
                    name="payout"
                    control={control}
                    rules={{
                        required: "Обязательное поле",
                        validate: (value) => {
                            if (value <= 0) return "Значение должно быть больше нуля";
                            if (value > 100) return "Максимум 100 процентов";
                            return true;
                        },
                    }}
                    render={({ field }) => (
                        <SimpleInput
                            {...field}
                            label="Payout"
                            error={errors.payout?.message}
                            number 
                            min={0} 
                            max={100}
                            prefix="%" 
                            placeholder='4'
                        />
                    )}
                />
            </Hbox>
            <Hbox customJust="end">
                <SimpleButton main icon={<CheckOutlined />} type="submit">Подтвердить</SimpleButton>
            </Hbox>
        </Vbox>
    </form>
    
    async function createAssosiation({payin, payout}) {
        closeModal();
        await handleAsyncNotification(async () => {
            await createAssosiationApi({user_id: agent_id, entity_id, type, payin, payout});
            onSubmit();
        }, {
            successText: "Привязка успешна",
            errorText: "Ошибка привязки",
            loadingText: "Привязка"
        });
    }
}

export default Unlinkeds;