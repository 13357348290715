import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import SimpleInput from "@shared/ui/Simpleinput";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { useEffect, useState } from "react";
import getTradersApi from "@shared/api/services/getTradersApi";
import GlassCard from "@shared/ui/GlassCard";
import useDebounce from "@shared/hooks/useDebounce";
import Text from "@shared/ui/Text";
import Table from "@shared/ui/Table";
import useAddNavigate from "@shared/hooks/useAddNavigate";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "@shared/ui/Pagination";
import ElementCard from "@shared/ui/ElementCard";


// Страница на которой показываются все Трейдеры, хабы которых можно привязать к ранее выбранному магазину
const Assosiations = () => {
    const [searchValue, setSearchValue] = useState("");
    const [traders, setTraders] = useState([]);
    const [tradersLoading, setTradersLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate();

    const debouncedSearchValue = useDebounce(searchValue, 1000);
    useEffect(fetchTraders, [debouncedSearchValue, currentPage]);

    const addNavigate = useAddNavigate();

    const columns = [
        {
            title: "Имя пользователя",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            width: "1px ",
            render: (_, {id}) => <SimpleButton onClick={() => {addNavigate("/traders/" + id)}}>Посмотреть</SimpleButton>
        }
    ]

    return <ElementCard>
        <Vbox gap="10px">
            <Text weight="bold">Добавить связь</Text>
            <Hbox width="200px" gap="10px">
                <SimpleInput label="Поиск по трейдеру" value={searchValue} onChange={(e) => {setSearchValue(e.target.value)}} />
            </Hbox>
            <Table loading={tradersLoading} columns={columns} dataSource={traders} />
            <Pagination value={currentPage} total={totalPages} onChange={setCurrentPage} />
        </Vbox>
    </ElementCard>
    

    function fetchTraders() {
        (async () => {
            setTradersLoading(true);

            try {
                const {data, total_pages} = await getTradersApi(searchValue, currentPage);
                
                setTraders(data);
                setTotalPages(total_pages);
            } catch(e) {
                console.log(e);
            } finally {
                setTradersLoading(false);
            }
        })();
    }
}

export default Assosiations;