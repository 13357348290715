import { useEffect, useState, useRef } from "react";

export const useWebsocket = () => {
    const [websocket, setWebsocket] = useState(null);
    const reconnectAttempts = useRef(0); // Счётчик попыток переподключения
    const maxReconnectAttempts = 10; // Максимальное количество попыток
    const reconnectDelay = 2000; // Задержка перед переподключением (2 секунды)

    useEffect(() => {
        let ws;

        try {
            // Функция для создания WebSocket
            const connectWebSocket = () => {
                ws = new WebSocket(process.env.REACT_APP_API_HOST + "/ws");

                setWebsocket(ws);

                ws.onclose = () => {
                    console.log("WebSocket закрыт. Попытка переподключения...");
                    if (reconnectAttempts.current < maxReconnectAttempts) {
                        reconnectAttempts.current += 1;
                        setTimeout(connectWebSocket, reconnectDelay);
                    } else {
                        console.log("Достигнут лимит попыток переподключения.");
                    }
                };

                ws.onerror = (error) => {
                    console.error("WebSocket ошибка:", error);
                    ws.close(); // Закрыть соединение при ошибке, чтобы сработал onclose
                };
            };

            connectWebSocket();
        } catch(e) {
            console.log(e)
        }

        return () => {
            if (ws) {
                console.log("Закрытие WebSocket");
                ws.close();
            }
        };
    }, []);

    return websocket;
};
