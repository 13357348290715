import React, { useState } from 'react';
import styled from 'styled-components';
import { message } from 'antd';

const TruncatedText = styled.div`
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: #333;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const Tooltip = styled.div`
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Показываем сверху */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; /* Гарантируем, что строка не будет переноситься */
  
  ${TruncatedText}:hover & {
    visibility: visible;
  }

  &:before {
    content: '';
    position: absolute;
    top: 100%; /* Стрелочка снизу */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
`;

const CopyableText = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      message.success("Скопировано");
    });
  };

  if(!text) return "-";

  if(text.length < 6) return text;

  return (
    <TruncatedText onClick={handleCopy}>
      {text.length > 5 ? `${text.substring(0, 5)}...` : text}
    </TruncatedText>
  );
};

export default CopyableText;
