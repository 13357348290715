import PageWrapper from "@shared/ui/layouts/PageWrapper";
import InvitePeople from "@features/InvitePeople";
import {ThreeColumns} from "@shared/ui/containers/ListContainers";


const inviteRoles = [
    {label: 'Трейдер', value: 'trader'},
]

const Settings = () => {
    return <PageWrapper title="Настройки">
        <ThreeColumns>
            <InvitePeople roles={inviteRoles} />
        </ThreeColumns>
    </PageWrapper>
}

export default Settings;