import Hbox from "@shared/ui/layouts/Hbox";
import Vbox from "@shared/ui/layouts/Vbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { useState } from "react";
import BanksDropDown from "./elements/BanksDropDown";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import { useForm } from 'react-hook-form';
import AmountInput from "./elements/AmountInput";
import NameInput from "./elements/NameInput";
import createPhubRequestApi from "@shared/api/services/trader/phubsRequests/createPhubRequestApi";


const CreateRequestModal = ({phub_id, onSuccess, closeModal}) => {
    const [loading, setLoading] = useState(false);
    const { handleSubmit, control, formState: { errors } } = useForm();

    return <form onSubmit={handleSubmit(createPhubRequest)}>
        <Vbox gap="10px">
            <NameInput control={control} error={errors.name?.message} />
            <Hbox gap="10px">
                <AmountInput control={control} error={errors.amount?.message} />
                <BanksDropDown control={control} error={errors.bank?.message} />
            </Hbox>
            
            <Hbox customJust="end">
                <SimpleButton loading={loading} type="submit">Создать</SimpleButton>
            </Hbox>
        </Vbox>
    </form>

    async function createPhubRequest({amount, bank_id, name}) {
        setLoading(true);
        closeModal();

        try {
            await handleAsyncNotification(async () => {
                await createPhubRequestApi({phub_id, amount, bank_id, name})
                onSuccess();
            }, {
                successText: "Реквизит успешно создан",
                errorText: "Ошибка создания реквизита",
                loadingText: "Создание реквизита"
            });
        } catch(e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }
}

export default CreateRequestModal;