import Select from "@shared/ui/Select";
import { useEffect, useState } from "react";
import getTraficsApi from "@shared/api/services/getTraficsApi";
import { Controller } from "react-hook-form";


const HubTypeDropDown = ({control, error, defaultValue}) => {
    const [loading, setLoading] = useState(false);
    const [trafics, setTrafics] = useState([]);

    useEffect(fetchTrafics, []);

    return <Controller
        name="trafic_mode_id"
        control={control}
        defaultValue={defaultValue}
        rules={{ required: "Обязательное поле" }}
        render={({ field }) => (
            <Select
                {...field}
                loading={loading}
                options={trafics}
                defaultValue={defaultValue}
                fullWidth
                label="Тип распределения"
                error={error}
            />
        )}
    />

    function fetchTrafics() {
        (async () => {
            try {
                setLoading(true);

                const trafics = await getTraficsApi();

                setTrafics(trafics.map(({id, label}) => ({value: id, label})));
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }
}

export default HubTypeDropDown;