import PageWrapper from "@shared/ui/layouts/PageWrapper";
import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Text from "@shared/ui/Text";
import SimpleInput from "@shared/ui/Simpleinput";
import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox"
import Select from "@shared/ui/Select";
import {ThreeColumns} from "@shared/ui/containers/ListContainers";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";


const roles = [
    { label: "Мерчант", value: "merchant" },
    { label: "Трейдер", value: "trader" },
    { label: "Агент", value: "agent" },
]

const Invite = () => {
    return <PageWrapper title="Пригласить">
        <ThreeColumns>
            <GlassCard>
                <Text weight="bold">Заявка на приглашение</Text>
                <ElementCard>
                    <Vbox gap="10px">
                        <Select
                            label="Роль пользователя"
                            defaultValue={roles[0].value}
                            options={roles}
                        />
                        <Hbox gap="10px">
                            <SimpleInput label="Почта" />   
                            <SimpleInput label="Депозит" /> 
                        </Hbox>
                        <Hbox gap="10px">
                            <SimpleInput label="Ник ТГ" />
                            <SimpleInput label="Ник чата" />
                        </Hbox>
                        <SimpleButton main>Создать заявку</SimpleButton>                    
                    </Vbox>
                </ElementCard>
            </GlassCard>
        </ThreeColumns>
    </PageWrapper>
}

export default Invite;