import FormPage from "@shared/ui/widgets/FormPage";
import { useAuth } from "@shared/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { inputs } from "./lib/constants";
import {message} from "antd";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";


const SigninPage = () => {
    const { invitationId } = useParams();
    const { login, createUser } = useAuth();
    const navigate = useNavigate();

    return <FormPage 
        title="Регистрация"
        handleSubmit={handleSubmit}
        errorText="Ошибка регистрации"
        inputs={inputs}
    />
    
    async function handleSubmit({password, password2}) {
        if(password !== password2) {
            return message.error("Пароли не совпадают");
        }

        try {
            await handleAsyncNotification(async () => {
                await signin(password);
                navigate("/login");
            }, {
                successText: "Регистрация успешна",
                loadingText: "Создание аккаунта",
                errorText: "Ошибка регистрации"
            })

        } catch(e) {
            console.log(e);
            toast.error("Ошибка регистрации");
        }
    };

    async function signin(password) {
        await createUser(password, invitationId);
    };
}

export default SigninPage;