import { DatePicker } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import Label from "./Label";
import dayjs from "dayjs";


// Глобальные стили для календаря
const GlobalStyles = createGlobalStyle`
  .ant-picker-panel {
    background: ${(props) => props.theme.background} !important; /* Фон панели */
    color: ${(props) => props.theme.color} !important; /* Цвет текста */
  }

  .ant-picker-header {
    background: ${(props) => props.theme.background} !important; /* Фон заголовка */
    color: ${(props) => props.theme.color} !important; /* Цвет текста */

    .ant-picker-prev-icon,
    .ant-picker-next-icon,
    .ant-picker-super-prev-icon,
    .ant-picker-super-next-icon {
      color: ${(props) => props.theme.label} !important; /* Цвет стрелок */
    }
  }

  .ant-picker-content {
    background: ${(props) => props.theme.background} !important; /* Фон блока с днями недели */
  }

  .ant-picker-cell-header {
    color: ${(props) => props.theme.color} !important; /* Цвет дней недели */
  }

  .ant-picker-week-panel-row-cell,
  .ant-picker-content th {
    color: ${(props) => props.theme.color} !important; /* Цвет текста дней недели в заголовках */
  }

  .ant-picker-cell {
    color: ${(props) => props.theme.color} !important; /* Цвет текста в ячейках */
    background: ${(props) => props.theme.background} !important; /* Фон ячейки */
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background: ${(props) => props.theme.hover} !important; /* Фон при наведении */
    }

    &.ant-picker-cell-selected,
    &.ant-picker-cell-range-start,
    &.ant-picker-cell-range-end {
      background: ${(props) => props.theme.secondary} !important; /* Фон выбранной даты */
      
      .ant-picker-cell-inner {
        color: white !important; /* Белый текст для выбранной даты */
      }
    }

    &.ant-picker-cell-in-range::before {
      background: ${(props) => props.theme.secondary} !important; /* Цвет полоски в диапазоне */
    }
  }

  .ant-picker-cell-inner {
    color: ${(props) => props.theme.label} !important; /* Цвет чисел */
  }

  .ant-picker-footer {
    background: ${(props) => props.theme.background} !important; /* Фон футера */
  }

  // Уточненные стили для стрелок и иконок внутри RangePicker
  .ant-picker-range .ant-picker-input > .anticon,
  .ant-picker .anticon {
    color: ${props => props.theme.label} !important; /* Красный цвет для стрелок */
  }
`;

// Стили для RangePicker
const StyledRangePicker = styled(DatePicker.RangePicker)`
  background: ${(props) => props.theme.background} !important; /* Основной фон */
  color: ${(props) => props.theme.color} !important; /* Цвет текста */
  border: 1px solid ${(props) => props.theme.border} !important; /* Граница */
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;

  .ant-picker-input {
    background: ${(props) => props.theme.background} !important; /* Фон ввода */
    color: ${(props) => props.theme.color} !important; /* Цвет текста */
    border: none;
  }

  .ant-picker-input > input {
    background: ${(props) => props.theme.background} !important; /* Фон инпута */
    color: ${(props) => props.theme.color} !important; /* Цвет текста */

    &::placeholder {
      color: ${(props) => props.theme.label} !important; /* Цвет placeholder */
    }
  }

  &:hover {
    border-color: ${(props) => props.theme.border} !important; /* Граница при наведении */
  }

  &:focus-within,
  &.ant-picker-focused {
    border-color: ${(props) => props.theme.border} !important; /* Граница при фокусе */
    box-shadow: 0 0 0 2px ${(props) => props.theme.secondary} !important;
  }
`;

const RangePicker = ({ label, startDate, endDate, onChange, ...props }) => {
  return (
    <>
      <GlobalStyles />
      <Label value={label}>
        <StyledRangePicker
          value={[startDate, endDate]}
          style={{ minWidth: "200px" }}
          {...props}
          onChange={(e) => {onChange(e ? dayjs(e[0]?.$d) : null, e ? dayjs(e[1]?.$d) : null)}}
        />
      </Label>
    </>
  );
}

export default RangePicker;
