import { Controller } from "react-hook-form";
import SimpleInput from "@shared/ui/Simpleinput";


const MaxActiveTxInput = ({defaultValue, control, error}) => {
    return <Controller
        name="max_active_tx"
        control={control}
        defaultValue={defaultValue}
        rules={{ required: "Обязательное поле", min: { value: 0, message: "Не меньше 0" } }}
        render={({ field }) => (
            <SimpleInput
                {...field}
                label="Макс. кол-во активных транзакций"
                defaultValue={defaultValue}
                error={error}
                number
                placeholder="100"
            />
        )}
    />
}

export default MaxActiveTxInput;