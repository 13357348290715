
export default function formatReadableDate(isoDate, includeTime = true) {
    const date = new Date(isoDate);
  
    if (isNaN(date.getTime())) {
      return 'Invalid Date'; // Возвращаем ошибку, если передана некорректная дата
    }
    
    const options = {
      year: 'numeric',
      month: '2-digit',  // Месяц в виде двух цифр
      day: '2-digit',    // День в виде двух цифр
      ...(includeTime && {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }),
    };
  
    return date.toLocaleString('ru-RU', options); // Форматируем дату на русском языке
}