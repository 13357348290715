import GlassCard from "@shared/ui/GlassCard";
import Text from "@shared/ui/Text";
import Info from "./Info";
import Hbox from "@shared/ui/layouts/Hbox";
import Select from "@shared/ui/Select";
import {TwoColumns} from "@shared/ui/containers/ListContainers";
import Table from "@shared/ui/Table";
import Button from "@shared/ui/buttons/SimpleBtn";
import Pagination from "@shared/ui/Pagination";
import SimpleInput from "@shared/ui/Simpleinput";
import ElementCard from "@shared/ui/ElementCard";


const traders = [
    { name: "trader1", hubs_total: "2 шт.", active_hubs_total: "4 шт.", requisites_total: "2 шт.", min_balance: "500 USDT", real_balance: "200 USDT", set_deposit: "100 USDT", real_deposit: "50 USDT" },
    { name: "trader2", hubs_total: "3 шт.", active_hubs_total: "5 шт.", requisites_total: "3 шт.", min_balance: "600 USDT", real_balance: "300 USDT", set_deposit: "200 USDT", real_deposit: "150 USDT" },
    { name: "trader3", hubs_total: "4 шт.", active_hubs_total: "6 шт.", requisites_total: "4 шт.", min_balance: "700 USDT", real_balance: "400 USDT", set_deposit: "300 USDT", real_deposit: "250 USDT" },
    { name: "trader4", hubs_total: "5 шт.", active_hubs_total: "7 шт.", requisites_total: "5 шт.", min_balance: "800 USDT", real_balance: "500 USDT", set_deposit: "400 USDT", real_deposit: "350 USDT" },
    { name: "trader5", hubs_total: "6 шт.", active_hubs_total: "8 шт.", requisites_total: "6 шт.", min_balance: "900 USDT", real_balance: "600 USDT", set_deposit: "500 USDT", real_deposit: "450 USDT" },
    { name: "trader6", hubs_total: "7 шт.", active_hubs_total: "9 шт.", requisites_total: "7 шт.", min_balance: "1000 USDT", real_balance: "700 USDT", set_deposit: "600 USDT", real_deposit: "550 USDT" },
    { name: "trader7", hubs_total: "8 шт.", active_hubs_total: "10 шт.", requisites_total: "8 шт.", min_balance: "1100 USDT", real_balance: "800 USDT", set_deposit: "700 USDT", real_deposit: "650 USDT" },
    { name: "trader8", hubs_total: "9 шт.", active_hubs_total: "11 шт.", requisites_total: "9 шт.", min_balance: "1200 USDT", real_balance: "900 USDT", set_deposit: "800 USDT", real_deposit: "750 USDT" },
    { name: "trader9", hubs_total: "10 шт.", active_hubs_total: "12 шт.", requisites_total: "10 шт.", min_balance: "1300 USDT", real_balance: "1000 USDT", set_deposit: "900 USDT", real_deposit: "850 USDT" },
    { name: "trader10", hubs_total: "11 шт.", active_hubs_total: "13 шт.", requisites_total: "11 шт.", min_balance: "1400 USDT", real_balance: "1100 USDT", set_deposit: "1000 USDT", real_deposit: "950 USDT" }
];
  

const columns = [
    { title: "Пользователь", dataIndex: "name" },
    { title: "Кол-во хабов", dataIndex: "hubs_total" },
    { title: "Кол-во активных хабов", dataIndex: "active_hubs_total" },
    { title: "Кол-во реквизитов", dataIndex: "requisites_total" },
    { title: "Минимальный баланс", dataIndex: "min_balance" },
    { title: "Реальный баланс", dataIndex: "real_balance" },
    { title: "Установленный депозит", dataIndex: "set_deposit" },
    { title: "Реальный депозит", dataIndex: "real_deposit" },
    { title: "Скорость обработки диспутов", dataIndex: "dispute_resolve_speed", render: () => "5 минут" },
]


const Traders = () => {
    return <TwoColumns>
            <GlassCard>
                <Hbox customJust="start" gap="10px">
                    <Select 
                        items={[]}
                        label="Способ оплаты (payin / payout)"
                    />
                    <Select 
                        items={[]}
                        label="Валюта"
                    />
                </Hbox>
                <ElementCard>
                    <Info title="Общее количество трейдеров" value="20 шт." />
                    <Info title="Общее количество активных трейдеров" value="20 шт." />
                    <Info title="Общее количество хабов" value="20 шт." />
                    <Info title="Общее количество реквизитов" value="20 шт." />
                </ElementCard>
            </GlassCard>
            <GlassCard>
                <Text weight="bold">Трейдеры</Text>
                <Hbox gap="10px">
                    <Select 
                        items={[]}
                        label="Способ оплаты (payin / payout)"
                    />
                    <SimpleInput placeholder="testtrader1" label="Поиск по имени пользователя" />
                </Hbox>
                
                <Table 
                    dataSource={traders}
                    columns={columns}
                />
                <Pagination total={5} />
            </GlassCard>
    </TwoColumns>
}

export default Traders;