import {useState, useEffect} from 'react'
import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Switch from '@shared/ui/Switch'
import Table from '@shared/ui/Table'
import ReactPaginate from 'react-paginate';

const ReqsTrader = ({ trader_id }) => {
    const [activeBtn, setActiveBtn] = useState('PayIn')
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPage2, setCurrentPage2] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalPages2, setTotalPages2] = useState(1)
    const [reqs, setReqs] = useState({})

    useEffect(() => {
        const fetchReqs = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/core/traders/requisites/?user_id=${trader_id}&page=${currentPage}&page2=${currentPage2}`, {
                credentials: "include"
            })
            if (!response.ok) {
                console.log(response.status);
            }

            const data = await response.json();
            console.log(data)
            setReqs({ 'payin': data.payin.data})
            setTotalPages(data.payin.total_pages)
        }

        fetchReqs()
    }, [currentPage, currentPage2, activeBtn])
    const handleClick = (btn) => {
        setActiveBtn(btn);
    }
    const handlePageClick = (data) => {
        if (activeBtn === 'PayIn') {
            setCurrentPage(data.selected + 1);
        } else {
            setCurrentPage2(data.selected + 1)
        }
    };
    const getColumns = () => {
        return (activeBtn === 'PayIn') ? [{label: 'Id', value: 'id'}, {label: 'Банк', value: 'bank'}, {label: 'Устройство', value: 'device'}, {label: 'Статус', value: 'req_status'}] : ['Id', 'Банк', 'Метод', 'Мин. лимит', 'Макс. лимит', 'Статус']
    }
    const getTransactions = () => {
        if (!reqs) {
            return []; // Возвращаем пустой массив, если reqs не определен
        }
        return (activeBtn === 'PayIn') ?
            (reqs.payin ? reqs.payin.map(req => ({
                id: req.id,
                bank: req.bank_id,
                device: req.device_id,
                req_status: <Switch isOn={req.status} />
            })) : []) // Возвращаем пустой массив, если reqs.payin не определен
            :
            (reqs.payout ? reqs.payout.map(req => ({
                id: req.id,
                bank: req.bank,
                method: req.method,
                min: req.min_limit,
                max: req.max_limit,

                req_status: <Switch isOn={req.status} />
            })) : []); // Возвращаем пустой массив, если reqs.payout не определен
    }
    const getTotalPages = () => {
        return activeBtn === 'PayIn' ? totalPages : totalPages2
    }
    return (
        <VBox gap='10px'>
            <HBox customJust='unset' gap='10px'>
                <SimpleButton
                    main={activeBtn === 'PayIn'}
                    onClick={() => handleClick('PayIn')}>
                    PayIn</SimpleButton>
            </HBox>
            <Table columns={getColumns()} dataSource={getTransactions()} lastWidth='100px' />
            {getTotalPages() > 1 && (
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={getTotalPages()}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            )}
        </VBox>
    )
}
export default ReqsTrader