import React, { useState, useEffect, useMemo } from 'react';
import HBox from '@shared/ui/layouts/Hbox';
import SimpleButton from '@shared/ui/buttons/SimpleBtn';
import CreateShopModal from '@features/CreateShopModal';
import ChangeShopModal from '@features/ChangeShopModal';
import Table from "@shared/ui/Table";
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useModal } from '@shared/ModalContext';
import Pagination from "@shared/ui/Pagination";
import Text from "@shared/ui/Text";
import PagePreloader from './elements/PagePreloader';
import getColumns from './lib/getColumns';
import getShopsApi from '@shared/api/services/getShopsApi';
import GlassCard from "@shared/ui/GlassCard";
import { PlusOutlined } from '@ant-design/icons';


const Shops = () => {
    const [shops, setShops] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const {openModal} = useModal();

    useEffect(fetchShops, [currentPage]);
    const columns = useMemo(() => getColumns(openChangeModal, fetchShops), [fetchShops, currentPage]);
    
    return (
        <PageWrapper ContentPreloader={PagePreloader} loading={loading} title="Магазины">
            <HBox customJust="end">
                <SimpleButton main icon={<PlusOutlined />} onClick={openCreateModal}>Создать магазин</SimpleButton>
            </HBox>
            <GlassCard>
                <Text weight="bold">Список магазинов</Text>
                <Table loading={loading} columns={columns} dataSource={shops} />
                <Pagination total={totalPages} onChange={setCurrentPage} />
            </GlassCard>
        </PageWrapper>
    );

    function fetchShops() { // Начинаем с 0 для синхронизации с react-paginate
        (async () => {
            setLoading(true);
            try {
                const {data, total_pages} = await getShopsApi({page: currentPage});

                setShops(data.map(el => ({...el, fiat_currency: el.fiat_currency.currency, digital_currency: el.digital_currency.currency})));
                setTotalPages(total_pages);
            } catch (error) {
                console.error('Ошибка при получении магазинов:', error);
                setShops([]);
                setCurrentPage(1);
                setTotalPages(0);
            } finally {
                setLoading(false);
            }
        })();
    };

    function openCreateModal() {
        openModal(<CreateShopModal getShops={fetchShops}/>, "Создание магазина");
    }

    function openChangeModal(shopid) {
        openModal(<ChangeShopModal shopid={shopid} shops={shops} />, "Настройка магазина");
    }
};

export default Shops;
