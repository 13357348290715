import Vbox from "@shared/ui/layouts/Vbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Hbox from "@shared/ui/layouts/Hbox";
import Text from "@shared/ui/Text";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import deletePhubRequestApi from "@shared/api/services/trader/phubsRequests/deletePhubRequestApi";


const DeletePHubRequestModal = ({request_id, onSuccess, closeModal}) => {
    return <Vbox gap="10px">
        <Text>Подтвердите удаление заявки на вывод "{request_id}"</Text>
        <Hbox customJust="end"><SimpleButton onClick={deletePHub}>Удалить</SimpleButton></Hbox>
    </Vbox>

    async function deletePHub() {
        closeModal();
        await handleAsyncNotification(async () => {
            await deletePhubRequestApi(request_id);
            onSuccess();
        }, {
            successText: "Удаление заявки",
            errorText: "Ошибка удаления заявки",
            loadingText: "Удаление заявки"
        });
    }
}

export default DeletePHubRequestModal;