import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useEffect, useState } from "react";
import getShopApi from "@shared/api/services/shops/getShopApi";
import { useNavigate, useParams } from "react-router-dom";
import Hbox from "@shared/ui/layouts/Hbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { PlusOutlined } from "@ant-design/icons";


const CascadeHubs = () => {
    const {shop_id} = useParams();
    const [shopData, setShopData] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(fetchShop, []);

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/shops")}}>Магазины</a> },
        { title: <a onClick={() => {navigate(`/shops/${shop_id}`)}}>Магазин "{shopData.name || ""}"</a> }
    ];

    return <PageWrapper breadcrumbs={breadcrumbs} loading={loading} title="Хабы каскада">
        <Hbox customJust="end">
            <SimpleButton main icon={<PlusOutlined />}>Создать Payin-Hub</SimpleButton>
        </Hbox>
    </PageWrapper>

    function fetchShop() {
        (async () => {
            try {
                setLoading(true);

                const data = await getShopApi(shop_id);

                setShopData(data);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }
}

export default CascadeHubs