import styled from "styled-components";
import Vbox from "@shared/ui/layouts/Vbox";
import {RecHubCardSkeleton} from "@entities/RecHubCard";
import Hbox from "@shared/ui/layouts/Hbox";
import DropDownSkeleton from "@shared/ui/skeletons/DropDownSkeleton";


const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    width: 100%;

    @media(max-width: 1100px) {
        grid-template-columns: 1fr 1fr;
    }

    @media(max-width: 800px) {
        grid-template-columns: 1fr;
    }
`

const PagePreloader = () => {
    return <Vbox gap="10px">
        <Hbox customJust="end">
            <DropDownSkeleton />
        </Hbox>
        <Container>
            <RecHubCardSkeleton />
            <RecHubCardSkeleton />
            <RecHubCardSkeleton />
        </Container>
    </Vbox>
}

export default PagePreloader;