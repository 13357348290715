import styled from "styled-components";
import { Tabs } from "antd";
import { useState } from "react";

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0; /* Убираем стандартный отступ */
  }

  .ant-tabs-content {
    margin-top: 16px; /* Добавляем пространство над контентом */
  }

  font-family: Manrope;

  .ant-tabs-tab {
    color: ${(props) => props.theme.color}; /* Цвет текста вкладок */
  }

  .ant-tabs-tab:hover {
    color: ${(props) => props.theme.color}; /* Цвет при наведении */
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold; /* Выделение активной вкладки */
  }
`;

const ContentSwitcher = ({ items, ...props }) => {
  return <StyledTabs items={items} {...props} />;
};

export default ContentSwitcher;
