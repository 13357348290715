import makeRequest from "@shared/api/makeRequest";

// type это роль привязанного юзера - trader или shop
export default async function({user_id, association_id, type}) {
    return await makeRequest(`/api/v1/core/users/${user_id}/association/delete`, { 
        body: {
            association_id, // На беке перепутали букву
            type
        }
    });
}