import Hbox from "@shared/ui/layouts/Hbox";
import Text from "@shared/ui/Text";
import styled from "styled-components";

const Line = styled.div`
    border-bottom: solid 1px gray;
    flex-grow: 1;
`;

function Info({title, value}) {
    return <Hbox gap="10px">
        <Text>{title}</Text>
        <Line />
        <Text>{value}</Text>        
    </Hbox>
}

export default Info;