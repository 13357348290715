// src/components/sections/Section3.js
import React, {useState, useEffect, act} from 'react';
import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import Text from "@shared/ui/Text";
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import SimpleButton from '@shared/ui/buttons/SimpleBtn';
import GlassCard from "@shared/ui/GlassCard";
import RangePicker from "@shared/ui/RangePicker";
import Pagination from "@shared/ui/Pagination";
import CopyableText from "@shared/ui/CopyableText";
import formatReadableDate from "@shared/helpers/formateReadableDate";
import Table from "@shared/ui/Table";
import 'moment/locale/ru';
import { render } from '@testing-library/react';
import { titleize } from 'redoc';


const Archive = () => {
    const [columns, setColumns] = useState([])
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [activeBtn, setActiveBtn] = useState('statements');
    const [secondActiveBtn, setSecondActive] = useState('Balance');
    const [loading, setLoading] = useState(true);
    
    useEffect(fetchData, [currentPage, activeBtn, secondActiveBtn]);
    
    return (
        <PageWrapper loading={loading} title="Архив">
            <VBox gap='15px'>
                <VBox gap='10px'>
                    {activeBtn === 'statements' && (
                    <HBox wrap gap="10px">
                        <div>
                            <HBox customJust="start" wrap gap='10px'>
                                <SimpleButton 
                                    main={secondActiveBtn === 'Balance'} backGround='var(--accent)' borderRadius='4px'
                                    onClick={() => setSecondActive('Balance')}>
                                        По балансу
                                </SimpleButton>
                                <SimpleButton 
                                    main={secondActiveBtn === 'Dep/With'} backGround='var(--accent)' borderRadius='4px'
                                    onClick={() => setSecondActive('Dep/With')}>
                                        По депозитам & выводам
                                </SimpleButton>
                            </HBox>
                        </div>
                        
                        <div>
                            <RangePicker />
                        </div>
                        
                    </HBox>)}
                    <GlassCard>
                        <Text weight="bold">Список</Text>
                        <Table columns={columns} dataSource={transactions} />
                        <Pagination total={totalPages} onChange={setCurrentPage} value={currentPage} />
                    </GlassCard>
                    
                </VBox>
            </VBox>
        </PageWrapper>
        
    );


    function fetchBalanceTransactions() {
        (async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_HOST + `/api/v1/wallets/balance_transactions/?page=${currentPage}`, {
                    credentials: "include"
                })
                const data = await response.json()
                const formattedTransactions = (data.data || []).map((transaction) => ({
                    ...transaction,
                    created_at: formatReadableDate(transaction.created_at),
                    from_entity: transaction.from_entity
                        ? { name: transaction.from_entity.name, id: transaction.from_entity.id, type: transaction.from_entity.type === 'user' ? 'trader' : transaction.from_entity.type }
                        : { name: 'N/A', id: null, type: null },
                    to_entity: transaction.to_entity
                        ? { name: transaction.to_entity.name, id: transaction.to_entity.id, type: transaction.to_entity.type === 'user' ? 'trader' : transaction.to_entity.type }
                        : { name: 'N/A', id: null, type: null },
                    
                }));
                setTransactions(formattedTransactions || []); // Убедитесь, что это массив
                setTotalPages(data.total_pages || 1);
            } catch(e) {
                console.log(e);
            }
        })();
    }

    function fetchWalletTransactions() {
        (async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_HOST + `/api/v1/wallets/wallet_transactions/?page=${currentPage}`, {
                    credentials: 'include'
                })
                const data = await response.json()
                setTransactions(data.data || []); // Убедитесь, что это массив
                setTotalPages(data.total_pages || 1);
            } catch(e) {
                console.log(e);
            }
        })();
    }

    function fetchData() {
        setLoading(true);
        if (activeBtn === 'statements') {
           if (secondActiveBtn === 'Balance') {
                fetchBalanceTransactions()
                setColumns([{title: 'ID', dataIndex: 'transaction_id', render: (value) => <CopyableText text={value} />, width: "1px"}, 
                    {title: 'Сумма', dataIndex: 'amount'},
                    {
                        title: 'From',
                        dataIndex: 'from_entity',
                        render: (entity) =>
                            entity?.id ? (
                                <a href={`/${entity.type}s/${entity.id}`} target="_blank" rel="noopener noreferrer">
                                    {entity.name}
                                </a>
                            ) : (
                                entity?.name
                            ),
                    },
                    {
                        title: 'To',
                        dataIndex: 'to_entity',
                        render: (entity) =>
                            entity?.id ? (
                                <a href={`/${entity.type}s/${entity.id}`} target="_blank" rel="noopener noreferrer">
                                    {entity.name}
                                </a>
                            ) : (
                                entity?.name
                            ),
                    },
                    {title: 'Причина', dataIndex: 'transaction_type_description'}, 
                    {title: 'Дата', dataIndex: 'created_at'}])
            } else {
                fetchWalletTransactions()
                setColumns([{title: 'Id', dataIndex: 'id'}, 
                    {title: 'Сумма', dataIndex: 'amount'}, 
                    {title: 'Хэш', dataIndex: 'hash', render: (value) => <a href={`https://tronscan.org/#/transaction/${value}`}>Tronscan</a>}, 
                    {title: 'C кошелька', dataIndex: 'wallet_id'}, 
                    {title: 'На кошелек', dataIndex: 'to_wallet_id', render: (value) => <CopyableText text={value} />},
                    {title: 'Тип транзакции', dataIndex: 'transaction_type', width: "1px"},
                    {title: 'Статус', dataIndex: 'status'},
                    {title: 'Дата', dataIndex: 'created_at', render: (value) => formatReadableDate(value)},
                    {title: 'Комментарий', dataIndex: 'description'}])
            }
        } else {
            setCurrentPage(1)
            setTotalPages(0)
            setTransactions([])
        }
        setLoading(false);
    }
};



export default Archive;
