import TableSkeleton from "@shared/ui/skeletons/TableSkeleton";
import Hbox from "@shared/ui/layouts/Hbox";
import Vbox from "@shared/ui/layouts/Vbox";
import DropDownSkeleton from "@shared/ui/skeletons/DropDownSkeleton";


const PagePreloader = () => {
    return <Vbox gap="10px">
        <Hbox gap="10px" customJust="start">
            <DropDownSkeleton />
            <DropDownSkeleton />
        </Hbox>
        <TableSkeleton />
    </Vbox>
    
}

export default PagePreloader;