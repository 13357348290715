import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import makeRequest from "@shared/api/makeRequest";
import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Vbox from "@shared/ui/layouts/Vbox";
import Info from "@shared/ui/Info";
import Hbox from "@shared/ui/layouts/Hbox";
import Text from "@shared/ui/Text";
import styled from "styled-components";


const Card = styled(GlassCard)`
    padding: 5px;
    white-space: nowrap;
`


const Bank = () => {
    const {bank_id} = useParams();
    const [bankData, setBankData] = useState({});
    const [currencies, setCurrencies] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(fetchBankData, []);
    useEffect(fetchData, []);

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/settings")}}>Настройки</a> },
        { title: <a onClick={() => {navigate("/settings/tradings/banks")}}>Настройки банков</a> }
    ]

    return <PageWrapper loading={loading} breadcrumbs={breadcrumbs} title={`Просмотр банка "${bankData.name}"`}>
        <GlassCard>
            <Text weight="bold">Привязки</Text>
            <ElementCard>
                <Vbox gap="10px">
                    <Info title="Валюты" value={<Hbox wrap customJust="start" gap="10px">{currencies.map((el => <Card>{el}</Card>))}</Hbox>} />
                    <Info title="Способы оплаты" value={<Hbox wrap customJust="start" gap="10px">{paymentMethods.map(el => <Card>{el}</Card>)}</Hbox>} />
                </Vbox>
            </ElementCard>
        </GlassCard>
    </PageWrapper>

    function fetchBankData() {
        (async () => {
            try {
                setLoading(true);

                const [bankData] = await makeRequest(`/api/v1/core/banks?id=${bank_id}`, { method: "GET" });

                setBankData(bankData || {});
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }

    function fetchData() {
        (async () => {
            try {
                const {currencies, payment_methods} = await makeRequest(`/api/v1/core/trading_methods/details/${bank_id}?type=bank`, { method: "GET" });

                setCurrencies(currencies);
                setPaymentMethods(payment_methods);
            } catch(e) {
                console.log(e);
            }
        })();
    }
}

export default Bank;