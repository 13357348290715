import { useForm } from "react-hook-form";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Hbox from "@shared/ui/layouts/Hbox";
import NameInput from "./elements/NameInput";
import Vbox from "@shared/ui/layouts/Vbox";
import DescriptionInput from "./elements/DescriptionInput";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import editPHubApi from "@shared/api/services/admin/phubs/editPHubApi";
import { useEffect, useState } from "react";
import getPHubInfoApi from "@shared/api/services/admin/phubs/getPHubInfoApi";
import Loading from "@shared/ui/Loading";
import { toast } from "react-toastify";


const EditPHubModal = ({phub_id, closeModal, onSuccess}) => {
    const { handleSubmit, formState: {errors}, control } = useForm();
    const [defaultValues, setDefaultValues] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(fetchHubInfo, []);

    if(loading) return <Hbox customJust="center"><Loading /></Hbox>

    return <form onSubmit={handleSubmit(editPHub)}>
        <Vbox gap="10px">
            <NameInput defaultValue={defaultValues.name} control={control} error={errors.name?.message} />
            <DescriptionInput defaultValue={defaultValues.description} control={control} error={errors.description?.message} />
            <Hbox customJust="end"><SimpleButton type="submit">Изменить</SimpleButton></Hbox>
        </Vbox>
    </form>

    async function editPHub({name, description}) {
        if(validateNoChanges()) return toast.error("Измените значения");

        closeModal();
        await handleAsyncNotification(async () => {
            await editPHubApi({phub_id, name, description});
            onSuccess();
        }, {
            successText: "Payout-Hub изменен",
            errorText: "Ошибка изменения Payout-Hub",
            loadingText: "Изменение Payout-Hub"
        });

        function validateNoChanges() {
            return name === defaultValues.name && description === defaultValues.description
        }
    }

    function fetchHubInfo() {
        (async () => {
            setLoading(true);

            try {
                const defaultValues = await getPHubInfoApi(phub_id);

                setDefaultValues(defaultValues);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }
}

export default EditPHubModal;