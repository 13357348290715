import Vbox from "@shared/ui/layouts/Vbox";
import { useEffect, useState } from "react";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import setHubSettingsApi from "@shared/api/services/admin/hubs/setHubSettingsApi";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import ControllerInput from "./elements/ControllerInput";
import Text from "@shared/ui/Text";
import Hbox from "@shared/ui/layouts/Hbox";
import { toast } from "react-toastify";
import Loading from "@shared/ui/Loading";
import getHubDataApi from "@shared/api/services/hubs/getHubDataApi";
import { CheckOutlined } from "@ant-design/icons";
import { namings } from "../../app/constants";


const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
`

const EditHubLimitsModal = ({hub_id, closeModal, onSuccess = () => {}}) => {
    const { handleSubmit, control, formState: { errors } } = useForm();
    const [defaultValues, setDefaultValues] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(fetchHubData, []);

    const FormInput = (props) => <ControllerInput defaultValues={defaultValues} control={control} errors={errors} {...props} />

    if(loading) return <Hbox customJust="center"><Loading /></Hbox>

    return <form onSubmit={handleSubmit(saveSettings)}>
        <Vbox gap="10px">
            <Text>Payin-Hub "{defaultValues.name}"</Text>
            <Container>
                <FormInput name="min_transaction_amount" label={namings.min_transaction_amount} />
                <FormInput name="max_transaction_amount" label={namings.max_transaction_amount} />
                <FormInput name="max_hourly_orders" label={namings.max_hourly_orders} />
                <FormInput name="max_daily_orders" label={namings.max_dayly_orders} />
                <FormInput name="max_active_tx" label={namings.max_active_tx} />
                <FormInput name="max_daily_volume" label={namings.max_dayly_volume} />
                <FormInput name="max_hourly_volume" label={namings.max_hourly_volume} />
            </Container>
            <Hbox customJust="end">
                <SimpleButton main icon={<CheckOutlined />} type="submit">Сохранить</SimpleButton>
            </Hbox>
        </Vbox>
    </form>
    
    async function saveSettings({min_transaction_amount, max_transaction_amount, max_hourly_orders, max_daily_orders, max_hourly_volume, max_daily_volume, max_active_tx}) {
        if(validateChanges()) {
            return toast.error("Измените хотя бы одно поле");
        }

        closeModal();
        await handleAsyncNotification(async () => {
            await setHubSettingsApi(hub_id, {
                ...(min_transaction_amount ? {min_transaction_amount: +min_transaction_amount} : {}),
                ...(max_transaction_amount ? {max_transaction_amount} : {}),
                ...(max_hourly_orders ? {max_hourly_orders} : {}),
                ...(max_daily_orders ? {max_daily_orders} : {}),
                ...(max_hourly_volume ? {max_hourly_volume} : {}),
                ...(max_daily_volume ? {max_daily_volume} : {}),
                ...(max_active_tx ? {max_active_tx} : {}),
            })
        }, {
            successText: "Лимиты сохранены",
            errorText: "Ошибка сохранения лимитов",
            loadingText: "Сохранение лимитов"
        });
        onSuccess();

        function validateChanges() {
            return min_transaction_amount === defaultValues.min_transaction_amount &&
            max_transaction_amount === defaultValues.max_transaction_amount &&
            max_hourly_orders === defaultValues.max_hourly_orders &&
            max_daily_orders === defaultValues.max_daily_orders &&
            max_hourly_volume === defaultValues.max_hourly_volume &&
            max_daily_volume === defaultValues.max_daily_volume &&
            max_active_tx === defaultValues.max_active_tx
        }
    }

    function fetchHubData() {
        (async () => {
            try {
                setLoading(true);

                const defaultValues = await getHubDataApi(hub_id);

                setDefaultValues(defaultValues);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }
}

export default EditHubLimitsModal;