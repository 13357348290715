import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TronQRCode from '@shared/ui/widgets/TronQrWidget';
import Text from "@shared/ui/Text";
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import GlassCard from "@shared/ui/GlassCard";
import Loading from '@shared/ui/Loading';
import Vbox from "@shared/ui/layouts/Vbox";
import HBox from '@shared/ui/layouts/Hbox'
import CopyableText from "@shared/ui/CopyableText";
import Table from "@shared/ui/Table";
import Label from "@shared/ui/Label";
import makeRequest from '@shared/api/makeRequest'
import formatReadableDate from '@shared/helpers/formateReadableDate';
import Select from '@shared/ui/Select'
import Pagination from "@shared/ui/Pagination";
import RangePicker from '@shared/ui/RangePicker'
import styled from 'styled-components';
import ClipboardCopy from '@shared/ui/ClipboardCopy';
import dayjs from "dayjs";

const CodeContainer = styled.div`
    padding: 10px;
    background: white;
    width: fit-content;
    border-radius: 10px;
`

const Deposit = () => {
    const { shopName } = useParams();
    

    const today = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);
    // Состояния
    const [statuses] = useState([
        { label: 'Завершенные', value: 'completed' },
        { label: 'Отклонено', value: 'canceled' },
        { label: 'В процессе', value: 'in_processing' }
    ]);
    const columns = [
        { title: 'ID', dataIndex: 'transaction_id', render: (value) => <CopyableText text={value} />, width: "1px" },
        { title: 'С кошелька', dataIndex: 'to_wallet_address' },
        { title: 'Сумма', dataIndex: 'amount'},
        { title: 'Hash', dataIndex: 'hash', render: (value) => <a href={`https://tronscan.org/#/transaction/${value}`}>Tronscan</a> },
        { title: 'Статус', dataIndex: 'status' },
        { title: 'Дата & Время', dataIndex: 'created_at' }
    ];
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [walletAddress, setWalletAddress] = useState('');
    const [shop, setShop] = useState(shopName)
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(dayjs().subtract(7, "day"));
    const [endDate, setEndDate] = useState(dayjs());
    const [shopTransactions, setShopTransactions] = useState(shopName)
    const [status, setStatus] = useState('completed')
    const [shopList, setShops] = useState([]);
    const [statusOptions] = useState([
        { label: 'Завершенные', value: 'completed' },
        { label: 'Отклонено', value: 'canceled' },
        { label: 'В процессе', value: 'in_processing' }
    ]);

    useEffect(() => {
        console.log(startDate, endDate)
    }, [startDate, endDate])

    const textDivRef = useRef(null);

    // Функция для загрузки данных кошелька
    const fetchWalletData = async (shopName) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/wallets/wallet/shop/${shopName}`, {
                credentials: 'include'
            });
            const data = await response.json();
            console.log(data);
            setWalletAddress(data.wallet_address);
        } catch (error) {
            console.error('Ошибка при получении данных о кошельке', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchShops = async () => {
        const response = await makeRequest("/api/v1/wallets/balance/shops", {
            method: "GET"
        })
        const data = response;
        const transformedData = data.data.map(shop => ({
            label: shop.name || "",
            value: shop.shop_id || ""
        }));
        console.log(transformedData)
        setShops(transformedData)
    }
    const fetchDeposits = async (page = currentPage, timezone = "UTC") => {
        try {
            const params = new URLSearchParams({
                page: currentPage,
                shop_id: shop,
                ...(status && { status }),
                ...(startDate && { start_date: formatDate(startDate)  }),
                ...(endDate && { end_date: formatDate(endDate) })
            });

            const {total_pages, transactions} = await makeRequest(`/api/v1/wallets/wallet/transactions?${params.toString()}`, { method: "GET" });
            const deposits = transactions.map(item => ({
                ...item,
                status: statuses.find(s => s.value === item.status)?.label || '-',
                created_at: formatReadableDate(item.created_at)
            }));
            
            setTotalPages(total_pages);
            setTransactions(deposits);
        } catch (error) {
            console.error("Ошибка при получении данных о пополнениях:", error);
        }
    };
    // Загрузка данных при изменении магазина
    useEffect(() => {
        fetchWalletData(shopName);
    }, []);
    useEffect(() => {
        fetchShops()
    }, [])
    useEffect(() => {
        const fetchWithdrawals = async () => {
            setTransactions([])
            try {
                const data = await fetchDeposits();
            } catch (error) {
                console.error("Ошибка загрузки данных:", error);
            }
        };

        fetchWithdrawals();
    }, [status, currentPage, shop, startDate, endDate]);
    // Обработчики выбора
    
    const handleStatusSelect = (selectedOption) => {
        console.log('Выбранный статус:', selectedOption);
    };
    const handleDateChange = (fromDate, toDate) => {
        setStartDate(formatDate(fromDate));
        setEndDate(formatDate(toDate));
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1);
    };

    const navigate = useNavigate();

    // Условный рендеринг для загрузки
    if (loading) {
        return <Loading />;
    }
    const acceptDate = async () => {
        setCurrentPage(1);
        setTransactions([])
        await fetchDeposits();
    };    

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/assets")}}>Активы</a> }
    ]

    return <PageWrapper breadcrumbs={breadcrumbs} title="Пополнение">
        <Vbox gap="20px">           
            <GlassCard width="fit-content">
                <Text weight="bold">Пополнение</Text>
                <HBox gap="20px">
                    <Vbox gap="8px">                   
                        <Label value="Тип">
                            <Text>USDT TRC-20 Address</Text>
                        </Label>
                        <Label value="Адрес для пополнения">
                            <ClipboardCopy value={walletAddress} />
                        </Label>
                    </Vbox>
                    <CodeContainer>
                        <TronQRCode address={walletAddress} />
                    </CodeContainer>
                </HBox>
            </GlassCard>

            <GlassCard>
                <Text weight="bold">История пополнения</Text>
                <HBox gap='10px'>
                    <HBox gap='10px'>
                        <Select
                            label="Магазин"
                            options={shopList}
                            value={shop}
                            showSearch
                            onChange={setShop} // selectedShop — объект { value, label }
                        />
                        <Select
                            label="Тип ордера"
                            options={statuses}
                            value={status}
                            onChange={setStatus} // selectedShop — объект { value, label }
                        />
                    </HBox>
                    <HBox gap='10px'>
                        <RangePicker 
                            label="Период" 
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(startDate, endDate) => {
                                setStartDate(startDate);
                                setEndDate(endDate);
                            }} 
                        />
                    </HBox>
                </HBox>
                <Table columns={columns} dataSource={transactions} />
                <Pagination onChange={setCurrentPage} value={currentPage} total={totalPages} />
            </GlassCard>
        </Vbox>
    </PageWrapper>
        
    
    function formatDate(dayjsDate) {
        if(!dayjsDate) return "";
        const date = dayjsDate.toDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
};

export default Deposit;