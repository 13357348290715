import styled from "styled-components";
import GlassCard from "@shared/ui/GlassCard";


export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: solid 1px ${(props) => props.theme.border};
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
  background: ${(props) => props.theme.secondary};
  border-radius: 10px;
`;

export const MainContainer = styled(GlassCard)`
  height: 400px;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledInput = styled.input`
  background: none;
  border: none;
  width: 100%;
  outline: none;
  font-size: 12px;
  color: ${(props) => props.theme.color};
`;

export const StyledMessage = styled.div`
  padding: 5px 10px;
  background: ${(props) => props.theme.secondary};
  width: fit-content;
  border-radius: 10px;
  max-width: 80%;
  overflow: hidden;
  word-break: break-word;
`;

export const RightMessage = styled(StyledMessage)`
  background: #318ce7;
  color: white;
`;

export const LeftMessage = styled(StyledMessage)`
  background: ${(props) => props.theme.secondary};
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  gap: 10px;
  padding: 0 5px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
`;