// src/components/sections/Section1.js
import React from 'react';

const Section6 = () => {
    return (
        <div>
            <h2>Помощь</h2>
            <p>Это содержимое Помощь</p>
        </div>
    );
};

export default Section6;
