import React from 'react';

import Assets from '@trader/Assets';
import Deposit from '@trader/Deposit';
import Withdrawl from '@trader/Withdrawl';
import PayinHubs from '@trader/PayinHubs';
import PayoutHubs from '@trader/PayoutHubs';
import Orders from '@src/widgets/orders';
import PayinHubsRequisites from '@trader/PayinHubsRequisites';
import Devices from '@trader/Devices';
import PayoutHubsRequests from '@trader/PayoutHubsRequests';
import Order from '@src/pages/trader/Order';
import Statistics from '@trader/Statistics';
import Settings from '@trader/Settings';
import Help from '@trader/Help';

const traderRoutes = [
  { path: '/assets', element: <Assets /> },
  { path: '/assets/deposit', element: <Deposit /> },
  { path: '/assets/withdrawl', element: <Withdrawl /> },
  { path: '/payinhubs', element: <PayinHubs /> },
  { path: '/payouthubs', element: <PayoutHubs /> },
  { path: '/orders/:order_id', element: <Order /> },
  { path: '/payouthubs/:phub_id', element: <PayoutHubsRequests /> },
  { path: '/devices', element: <Devices /> },
  { path: '/payinhubs/:hub_id', element: <PayinHubsRequisites /> },
  { path: '/orders', element: <Orders /> },
  { path: '/statistics', element: <Statistics /> },
  { path: '/settings', element: <Settings /> },
  { path: '/help', element: <Help /> },
];

export default traderRoutes;


// import React, {lazy} from 'react';

// const Assets = lazy(() => import('@trader/Assets'))
// const Deposit = lazy(() => import('@trader/Deposit'))
// const Withdrawl = lazy(() => import('@trader/Withdrawl'))
// const PayinHubs = lazy(() => import('@trader/PayinHubs'))
// const PayoutHubs = lazy(() => import('@trader/PayoutHubs'))
// const Orders = lazy(() => import('@src/widgets/orders'))
// const PayinHubsRequisites = lazy(() => import('@trader/PayinHubsRequisites'))
// const Devices = lazy(() => import('@trader/Devices'))
// const PayoutHubsRequests = lazy(() => import('@trader/PayoutHubsRequests'))
// const Order = lazy(() => import('@src/pages/trader/Order'))
// const Statistics = lazy(() => import('@trader/Statistics'))
// const Settings = lazy(() => import('@trader/Settings'))
// const Help = lazy(() => import('@trader/Help'))


// const traderRoutes = [
//   { path: '/assets', element: <Assets /> },
//   { path: '/assets/deposit', element: <Deposit /> },
//   { path: '/assets/withdrawl', element: <Withdrawl /> },
//   { path: '/payinhubs', element: <PayinHubs /> },
//   { path: '/payouthubs', element: <PayoutHubs /> },
//   { path: '/orders/:order_id', element: <Order /> },
//   { path: '/payouthubs/:phub_id', element: <PayoutHubsRequests /> },
//   { path: '/devices', element: <Devices /> },
//   { path: '/payinhubs/:hub_id', element: <PayinHubsRequisites /> },
//   { path: '/orders', element: <Orders /> },
//   { path: '/statistics', element: <Statistics /> },
//   { path: '/settings', element: <Settings /> },
//   { path: '/help', element: <Help /> },
// ];

// export default traderRoutes;

