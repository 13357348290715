// src/routes/adminRoutes.js
import React from 'react';
import { Navigate } from 'react-router-dom';

import AdminAssets from '@admin/Assets';
import AdminOrders from '@src/widgets/orders';
import AdminShops from '@admin/Shops';
import AdminShopTraderAssosiations from '@admin/ShopTraderAssosiations';
import AdminTraders from '@admin/Traders';
import PHubs from '@admin/PHubs';
import AdminStatistics from '@admin/Statistics';
import Trader from '@admin/Trader';
import AdminHubs from '@admin/Hubs';
import Shop from '@admin/Shop';
import HubRequisites from '@admin/Requisites';
import PhubsRequests from '@admin/PHubsRequests';
import AdminArchive from '@admin/Archive';
import AdminSettings from '@admin/Settings';
import AdminAgentAssosiations from '@admin/AgentAssosiations';
import AdminAgents from '@admin/Agents';
import CascadeHubs from '@admin/CascadeHubs';
import AdminCascades from '@admin/Cascades';
import Order from '@src/pages/admin/Order';
import BanksSettings from '@admin/settings/BanksSettings';
import CurrenciesSettings from '@admin/settings/CurrenciesSettings';
import TradingMethodsSettings from '@admin/settings/TradingMethodsSettings';
import PaymentsSettings from '@admin/settings/PaymentsSettings';
import Bank from '@admin/Bank';
import Payment from '@admin/Payment';
import Currency from '@admin/Currency';
import Users from '@admin/Users';


const adminRoutes = [
    { path: '/assets', element: <AdminAssets /> },
    { path: '/orders', element: <AdminOrders /> },
    { path: '/orders/:order_id', element: <Order /> },
    { path: '/users', element: <Users /> },
    { path: '/shops', element: <AdminShops /> },
    { path: '/shops/:shop_id/traders/:trader_id', element: <AdminShopTraderAssosiations /> },
    { path: '/shops/:shop_id', element: <Shop /> },
    { path: '/shops/:shop_id/cascades/:cascade_id/hubs', element: <CascadeHubs /> },
    { path: '/test', element: <AdminShopTraderAssosiations /> },
    { path: '/traders/:trader_id', element: <Trader /> },
    { path: '/agents', element: <AdminAgents /> },
    { path: '/agents/:agent_id', element: <AdminAgentAssosiations /> },
    { path: '/traders', element: <AdminTraders /> },
    { path: '/cascades', element: <AdminCascades /> },
    { path: '/statistics', element: <AdminStatistics /> },
    { path: '/traders/:trader_id/hubs', element: <AdminHubs /> },
    { path: '/traders/:trader_id/hubs/:hub_id', element: <HubRequisites /> },
    { path: '/traders/:trader_id/phubs', element: <PHubs /> },
    { path: '/traders/:trader_id/phubs/:phub_id', element: <PhubsRequests /> },
    { path: '/archive', element: <AdminArchive /> },
    { path: '/settings', element: <AdminSettings /> },
    { path: '/settings/tradings/banks', element: <BanksSettings /> },
    { path: '/settings/tradings/banks/:bank_id', element: <Bank /> },
    { path: '/settings/tradings/currencies', element: <CurrenciesSettings /> },
    { path: '/settings/tradings/currencies/:currency_id', element: <Currency /> },
    { path: '/settings/tradings/trading_methods', element: <TradingMethodsSettings /> },
    { path: '/settings/tradings/payments', element: <PaymentsSettings /> },
    { path: '/settings/tradings/payments/:payment_id', element: <Payment /> },
];

export default adminRoutes;


// // src/routes/adminRoutes.js
// import React, { lazy } from 'react';
// import { Navigate } from 'react-router-dom';

// const AdminAssets = lazy(() => import('@admin/Assets'));
// const AdminOrders = lazy(() => import('@src/widgets/orders'));
// const AdminShops = lazy(() => import('@admin/Shops'));
// const AdminShopTraderAssosiations = lazy(() => import('@admin/ShopTraderAssosiations'));
// const AdminTraders = lazy(() => import('@admin/Traders'));
// const PHubs = lazy(() => import('@admin/PHubs'))
// const AdminStatistics = lazy(() => import('@admin/Statistics'));
// const Trader = lazy(() => import('@admin/Trader'));
// const AdminHubs = lazy(() => import('@admin/Hubs'));
// const Shop = lazy(() => import('@admin/Shop'));
// const HubRequisites = lazy(() => import('@admin/Requisites'));
// const PhubsRequests = lazy(() => import('@admin/PHubsRequests'));
// const AdminArchive = lazy(() => import('@admin/Archive'));
// const AdminSettings = lazy(() => import('@admin/Settings'));
// const AdminAgentAssosiations = lazy(() => import('@admin/AgentAssosiations'));
// const AdminAgents = lazy(() => import('@admin/Agents'));
// const CascadeHubs = lazy(() => import('@admin/CascadeHubs'));
// const AdminCascades = lazy(() => import('@admin/Cascades'));
// const Order = lazy(() => import('@src/pages/admin/Order'));
// const BanksSettings = lazy(() => import('@admin/settings/BanksSettings'));
// const CurrenciesSettings = lazy(() => import('@admin/settings/CurrenciesSettings'));
// const TradingMethodsSettings = lazy(() => import('@admin/settings/TradingMethodsSettings'));
// const PaymentsSettings = lazy(() => import('@admin/settings/PaymentsSettings'));
// const Bank = lazy(() => import('@admin/Bank'));
// const Payment = lazy(() => import('@admin/Payment'));
// const Currency = lazy(() => import('@admin/Currency'));


// const adminRoutes = [
//     { path: '/assets', element: <AdminAssets /> },
//     { path: '/orders', element: <AdminOrders /> },
//     { path: '/orders/:order_id', element: <Order /> },
//     { path: '/shops', element: <AdminShops /> },
//     { path: '/shops/:shop_id/traders/:trader_id', element: <AdminShopTraderAssosiations /> },
//     { path: '/shops/:shop_id', element: <Shop /> },
//     { path: '/shops/:shop_id/cascades/:cascade_id/hubs', element: <CascadeHubs /> },
//     { path: '/test', element: <AdminShopTraderAssosiations /> },
//     { path: '/traders/:trader_id', element: <Trader /> },
//     { path: '/agents', element: <AdminAgents /> },
//     { path: '/agents/:agent_id', element: <AdminAgentAssosiations /> },
//     { path: '/traders', element: <AdminTraders /> },
//     { path: '/cascades', element: <AdminCascades /> },
//     { path: '/statistics', element: <AdminStatistics /> },
//     { path: '/traders/:trader_id/hubs', element: <AdminHubs /> },
//     { path: '/traders/:trader_id/hubs/:hub_id', element: <HubRequisites /> },
//     { path: '/traders/:trader_id/phubs', element: <PHubs /> },
//     { path: '/traders/:trader_id/phubs/:phub_id', element: <PhubsRequests /> },
//     { path: '/archive', element: <AdminArchive /> },
//     { path: '/settings', element: <AdminSettings /> },
//     { path: '/settings/tradings/banks', element: <BanksSettings /> },
//     { path: '/settings/tradings/banks/:bank_id', element: <Bank /> },
//     { path: '/settings/tradings/currencies', element: <CurrenciesSettings /> },
//     { path: '/settings/tradings/currencies/:currency_id', element: <Currency /> },
//     { path: '/settings/tradings/trading_methods', element: <TradingMethodsSettings /> },
//     { path: '/settings/tradings/payments', element: <PaymentsSettings /> },
//     { path: '/settings/tradings/payments/:payment_id', element: <Payment /> },
// ];

// export default adminRoutes;
