import { useState } from "react";
import { useSearchParams as reactRouterSearchParamsHook } from "react-router-dom";


// Кастомный хук, смысл которого в том, чтобы возвращать pdateSearchParams - т.е. метод для обновления квери параметров
function useSearchParams() {
    const [searchParams, setSearchParams] = reactRouterSearchParamsHook();

    function updateSearchParams(paramsToUpdate) {
        const newParams = new URLSearchParams(searchParams); // Копируем текущие параметры
    
        // Обновляем параметры из переданного объекта
        Object.entries(paramsToUpdate).forEach(([key, value]) => {
            if (value === null || value === undefined) {
                newParams.delete(key); // Удаляем параметр, если значение null или undefined
            } else {
                newParams.set(key, value); // Устанавливаем или обновляем параметр
            }
        });
    
        setSearchParams(newParams); // Применяем обновлённые параметры
    };

    return {searchParams, setSearchParams, updateSearchParams}
}

export default useSearchParams;