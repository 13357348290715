import {useState, useEffect} from 'react'
import SimpleInput from '@shared/ui/Simpleinput'
import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import Label  from '@shared/ui/Label';
import SimpleButton from '@shared/ui/buttons/SimpleBtn';
import makeRequest from '@shared/api/makeRequest'

const InsuranceDeposit = ({ trader_id }) => {
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [insurance, setInsurance] = useState(0);

    useEffect(fetchInsurance, []);
    
    return (
        <VBox gap='24px'>
            <HBox wrap gap='12px'>
                <Label value="Текущий обязательный страховой депозит">
                    <Label value={String(insurance)}></Label>
                </Label>
                <Label value='Новый обязательный страховой депозит'><SimpleInput value={amount} onChange={(e) => setAmount(e.target.value)}></SimpleInput></Label>
            </HBox>
            <HBox customJust="end">
                <SimpleButton main onClick={changeInsurance}>Сохранить</SimpleButton>
            </HBox>
            
        </VBox>
    )

    function fetchInsurance() {
        (async () => {
            setLoading(true);

            try {
                const insurance = await makeRequest(`/api/v1/core/traders/current_insurance?trader_id=${trader_id}`, {
                    method: 'GET'
                });

                setInsurance(insurance);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    } 

    function changeInsurance() {
        (async () => {
            setLoading(true);

            try {
                await makeRequest('/api/v1/core/traders/change_insurance', {
                    body: {
                        trader_id,
                        amount: parseFloat(amount)
                    }
                })
            } catch(e) {
                console.log(e)
            } finally {
                setLoading(false)
            }
        })();
    }
};
export default InsuranceDeposit;