import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import {useState, useEffect} from 'react'
import Switch from '@shared/ui/Switch'
import SimpleButton from '@shared/ui/buttons/SimpleBtn';
import Table from '@shared/ui/Table'
import ReactPaginate from 'react-paginate';

const Devices = ({ trader_id }) => {
    const columns = [{label: 'ID', value: 'id'}, {label: 'Название', value: 'name'}, {label: 'API', value: 'api'}]
    const [devices, setDevices] = useState([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const handlePageClick = (data) => {
        setPage(data.selected + 1);
    };
    useEffect(() => {
        const fetchDevices = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/core/traders/devices?trader_id=${trader_id}&page=${page}`, {
                    credentials: "include"
                });
                if (!response.ok) {
                    throw new Error(`Ошибка: ${response.status}`);
                }
                const data = await response.json();
                setTotalPages(data.total_pages);
                const devices_list = data.data.map(device => ({
                    id: device.id,
                    name: device.name,
                    api: device.api,
                    onoffbtn: <Switch isOn={device.status} />,
                    btn: <SimpleButton color='var(--background-light)' backGround='var(--red)' hoverBack='var(--dark_red)' borderRadius='8px'>Удалить</SimpleButton>
                }))
                setDevices(devices_list)
            } catch (error) {
                console.error("Ошибка при загрузке устройств:", error);
            }
        };

        fetchDevices(); // Вызов асинхронной функции

    }, [page]);
    return (
        <VBox gap='20px'>
            <Table columns={columns} dataSource={devices} />
            {totalPages > 1 && (
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            )}
        </VBox>
    )
}
export default Devices