import React from "react";
import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import { useEffect, useState } from "react";
import Select from "@shared/ui/Select";
import handleAsyncNotification from '@shared/helpers/handleAsyncNotification';
import getAllStatuses from "@shared/api/services/getAllStatuses";
import getReasonsByStatusApi from "@shared/api/services/getReasonsByStatusApi";
import closeDisputeApi from "@shared/api/services/closeDisputeApi";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";


function SupportCloseDisputeModal({transaction, closeModal, onSuccess}) {
    const [statuses, setStatuses] = useState([]);
    const [reasons, setReasons] = useState([]);
    const [chosenStatus, setChosenStatus] = useState();
    const [chosenReason, setChosenReason] = useState();
    const [loading, setLoading] = useState();

    useEffect(() => {
        fetchStatuses();
    }, []);

    useEffect(() => {
        if(!chosenStatus) return;

        setReasons([]);
        fetchReasons();
    }, [chosenStatus]);

    useEffect(() => {
        if(!reasons.length) {
            return setChosenReason(undefined);
        }

        setChosenReason(reasons[0].value);
    }, [reasons]);

    useEffect(() => {
        if(!statuses.length) return;

        setChosenStatus(statuses[0].value);
    }, [statuses]);

    return <VBox gap="10px">
        {!!statuses.length && <Select
            label="Статус"
            options={statuses}
            onChange={setChosenStatus}
        />}
        {!!reasons.length && <Select
            label="Основание"
            options={reasons}
            onChange={setChosenReason}
        />}

        {chosenReason && chosenStatus && <HBox customJust="end" gap="10px">
            <SimpleButton onClick={closeDispute}>Отправить</SimpleButton>
        </HBox>}
    </VBox>

    async function fetchStatuses() {
        
        try {
            setLoading(true);

            const statuses = await getAllStatuses();

            setStatuses(statuses.map(status => ({value: status.id, label: status.label})));
        } catch(e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }
    
    async function fetchReasons() {
        try {
            setLoading(true);

            const reasons = await getReasonsByStatusApi(chosenStatus);

            setReasons(reasons.map(reason => ({value: reason.id, label: reason.label})));
        } catch(e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    async function closeDispute() {     
        await handleAsyncNotification(async () => {
            await closeDisputeApi({
                transaction_id: transaction.id,
                reason_id: chosenReason
            })
            onSuccess();
        }, {
            loadingText: "Закрытие диспута по выбранной причине",
            successText: "Диспут успешно закрыт",
            errorText: "Ошибка закрытия диспута"
        })
        closeModal();
    }
}

function Statuses({values}) {
    return <HBox color="white" backGround="var(--primary)" padding="20px" borderRadius="10px">
        {values.map((value, index) => {
            const last = index >= (values.length - 1)
            return <div key={index} style={{...(last ? {fontWeight: "800"} : {})}}>{value} {last ? "" : "—" }</div>
        })}
    </HBox>
}

function Info({values}) {
    return <HBox color="white" backGround="var(--primary)" padding="20px" borderRadius="10px">
        {values.map((value) => <HBox key={value.value}>
            <VBox>
                <div>{value.title}</div>
                <div style={{fontWeight: "800"}}>{value.value}</div>
            </VBox>
        </HBox>)}
    </HBox>
}

export default SupportCloseDisputeModal;