import PageWrapper from "@shared/ui/layouts/PageWrapper";
import Hbox from "@shared/ui/layouts/Hbox";
import styled from "styled-components";
import {useModal} from "@shared/ModalContext";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import EditRecHubModal from "@features/EditRecHubModal";
import PagePreloader from "./elements/PagePreloader";
import CreateRecHubModal from "@features/CreateRecHubModal";
import getHubsApi from "@shared/api/services/hubs/getHubsApi";
import RecHubCard from "@entities/RecHubCard";
import Text from "@shared/ui/Text";
import Pagination from "@shared/ui/Pagination";
import { useEffect, useState } from "react";
import { RecHubCardSkeleton } from "@entities/RecHubCard";
import { useNavigate } from "react-router-dom";
import DeleteRecHubModal from "@features/DeleteRecHubModal";
import Empty from "@shared/ui/Empty";
import getPaymentMethodsApi from "@shared/api/services/getPaymentMethodsApi";
import getTraficsApi from "@shared/api/services/getTraficsApi";
import { PlusOutlined } from "@ant-design/icons";
import GlassCard from "@shared/ui/GlassCard";
import {TwoColumns} from "@shared/ui/containers/ListContainers";


const Reqs = () => {
    const {openModal} = useModal();
    const [hubs, setHubs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [traficModes, setTraficModes] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const navigate = useNavigate();

    useEffect(fetchHubs, [currentPage]);
    useEffect(fetchPaymentMethods, []);
    useEffect(fetchTraficModes, []);

    return <PageWrapper loading={loading} ContentPreloader={PagePreloader} title="Payin-Hubs">
        <Hbox customJust="end">
            <SimpleButton main icon={<PlusOutlined />} onClick={openCreateRecHub}>Создать Payin-Hub</SimpleButton>
        </Hbox>
        <GlassCard>
            <Text weight="bold">Список Payin-Hubs</Text>
            {hubs.length < 1 && !loading && <Empty description="Ничего не найдено" />}
            <TwoColumns>
                {loading ? <>
                    <RecHubCardSkeleton />
                    <RecHubCardSkeleton />
                    <RecHubCardSkeleton />
                </>
                :
                <>
                    {hubs.map(({name, turbo_mode, id, payment_method_id, trafic_mode_id, ...rest}) => <RecHubCard
                        traficMode={traficModes.find(el => el.id === trafic_mode_id)}
                        paymentMethod={paymentMethods.find(el => el.id === payment_method_id)}
                        key={id}
                        id={id}
                        name={name}
                        turbo={turbo_mode}
                        {...rest}
                        onOpen={() => {navigate("/payinhubs/" + id)}}
                        onEdit={() => {openEditRecHub(id)}}
                        onDelete={() => {openDeleteRecHub({id, name})}}
                    />)}
                </>}
            </TwoColumns>
            <Hbox customJust="center"><Pagination total={totalPages} onChange={setCurrentPage} value={currentPage} /></Hbox>
        </GlassCard>
        
    </PageWrapper>

    function openEditRecHub(hubId) {
        openModal(<EditRecHubModal hubId={hubId} onSuccess={fetchHubs}/>, "Изменение группы реквизитов");
    }

    function openCreateRecHub() {
        openModal(<CreateRecHubModal onSuccess={fetchHubs}/>, "Создание Payin-Hub");
    }

    function openDeleteRecHub({id, name}) {
        openModal(<DeleteRecHubModal id={id} name={name} onSuccess={fetchHubs} />, "Подтвердите удаление записи");
    }

    function fetchHubs() {
        (async () => {
            setLoading(true);
            try {
                const {data, total_pages} = await getHubsApi(currentPage);

                setHubs(data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
                setTotalPages(total_pages);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }

    function fetchPaymentMethods() {
        (async () => {
            const paymentMethods = await getPaymentMethodsApi();

            setPaymentMethods(paymentMethods);
        })();
    }

    function fetchTraficModes() {
        (async () => {
            const traficModes = await getTraficsApi();

            setTraficModes(traficModes);
        })();
    }
}



export default Reqs;