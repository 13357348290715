import { Controller } from "react-hook-form";
import SimpleInput from "@shared/ui/Simpleinput";


const MaxTransactionAmountInput = ({defaultValue, control, error}) => {
    return <Controller
        name="max_transaction_amount"
        control={control}
        defaultValue={defaultValue}
        rules={{ required: "Обязательное поле", min: { value: 0, message: "Не меньше 0" } }}
        render={({ field }) => (
            <SimpleInput
                {...field}
                label="Макс. сумма транзакции в фиате"
                defaultValue={defaultValue}
                error={error}
                number
                placeholder="100 000"
            />
        )}
    />
}

export default MaxTransactionAmountInput;