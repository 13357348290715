import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import {useAuth} from "@shared/AuthContext";


export const api = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    withCredentials: true // Включаем куки для междоменных запросов
});

export default function() {
    const {setUser} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        api.interceptors.response.use(
            (response) => response,
            (error) => {
                if(error.response?.data?.detail) {
                    toast.error(error.response.data.detail)
                }
                
                if(error?.response?.status === 401) {
                    setUser(null);
                    navigate("/login");
                }
            }
        );
    }, []);
}