import TableSkeleton from "@shared/ui/skeletons/TableSkeleton";
import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import DropDownSkeleton from "@shared/ui/skeletons/DropDownSkeleton";
import SmallButtonSkeleton from "@shared/ui/skeletons/SmallButtonSkeleton";


const PagePreloader = () => {
    return <Vbox gap="10px">
        <Hbox gap="10px">
            <Hbox gap="10px">
                <DropDownSkeleton />
                <DropDownSkeleton />
            </Hbox>
            <Hbox gap="10px">
                <SmallButtonSkeleton />
                <SmallButtonSkeleton />
            </Hbox>
        </Hbox>
        <TableSkeleton columnsAmount={6} />
    </Vbox>
}

export default PagePreloader;