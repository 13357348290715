// src/components/sections/Section1.js
import React from 'react';
import {ThreeColumns} from "@shared/ui/containers/ListContainers";
import PageWrapper from "@shared/ui/layouts/PageWrapper";  
import TwoFactorAuth from '@features/TwoFactorAuth';
import TradingSettings from './elements/TradingSettings';
import TelegramLogin from '@features/TelegramLogin';
import InvitePeople from '@features/InvitePeople';

const inviteRoles = [
    {label: 'Трейдер', value: 'trader'},
    {label: 'Мерчант', value: 'merchant'}, 
    {label: 'Агент', value: 'agent'},
    {label: 'Саппорт', value: 'support'},
    {label: 'Каскад', value: 'cascade'},
    {label: 'Аналитик', value: 'analyst' }
]


const Settings = () => {
    return (
        <PageWrapper title="Настройки">
            <ThreeColumns>
                <TwoFactorAuth />
                <InvitePeople roles={inviteRoles} />
                <TradingSettings />
                <TelegramLogin bot="many_push_bot" />
            </ThreeColumns>
        </PageWrapper>
    );
};

export default Settings;