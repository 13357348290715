import Vbox from "@shared/ui/layouts/Vbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Text from "@shared/ui/Text";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import deleteDeviceApi from "@shared/api/services/devices/deleteDeviceApi";
import Hbox from "@shared/ui/layouts/Hbox";
import { DeleteOutlined } from "@ant-design/icons";


const DeleteDeviceModal = ({deviceId, closeModal, onSuccess}) => {
    return <>
        <Text>ID устройства - {deviceId}</Text>
        <Hbox customJust="end">
            <SimpleButton main icon={<DeleteOutlined />} onClick={deleteDevice}>Удалить</SimpleButton>
        </Hbox>
    </>

    async function deleteDevice() {
        try {
            closeModal();
            await handleAsyncNotification(async () => {
                await deleteDeviceApi(deviceId);
                onSuccess();
            }, {
                loadingText: "Удаление устройства",
                successText: "Устройство удалено",
                errorText: "Ошибка удаления"
            });
        } catch(e) {
            console.log(e);
        }
    }
}

export default DeleteDeviceModal;