// src/components/sections/Section1.js
import React, { useState, useEffect, useMemo } from 'react';
import SimpleButton from '@shared/ui/buttons/SimpleBtn'
import SimpleInput from '@shared/ui/Simpleinput';
import makeRequest from '@shared/api/makeRequest'
import formatReadableDate from '@shared/helpers/formateReadableDate'
import handleAsyncNotification from '@shared/helpers/handleAsyncNotification'
import GlassCard from "@shared/ui/GlassCard";
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useModal } from '@shared/ModalContext';
import Select from "@shared/ui/Select";
import dayjs from "dayjs";
import {ThreeColumns} from "@shared/ui/containers/ListContainers";
import Pagination from "@shared/ui/Pagination";
import RangePicker from "@shared/ui/RangePicker";
import Table from "@shared/ui/Table";
import getBalanceApi from "@shared/api/services/getBalanceApi";
import Text from "@shared/ui/Text";
import CopyableText from "@shared/ui/CopyableText";
import Hbox from "@shared/ui/layouts/Hbox";
import Vbox from "@shared/ui/layouts/Vbox";
import { DollarOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';


const columns = [{title: 'ID', dataIndex: 'id', width: "1px", render: (value) => <CopyableText text={value} />}, {title: 'Сумма', dataIndex: 'amount', render: (value) => (value - 2).toFixed(2)}, {title: 'Hash', dataIndex: 'hash', render: (value) => <a href={`https://tronscan.org/#/transaction/${value}`}>Tronscan</a>}, {title: 'Статус', dataIndex: 'status'}, {title: 'Адрес', dataIndex: 'address'}, {title: 'Дата & Время', dataIndex: 'created_at'}];
const statuses = [{ label: 'Завершенные', value: 'completed' }, { label: 'Отклонено', value: 'cancel' }, { label: 'В процессе', value: 'in_processing' }];

const Withdraw = () => {
    const [address, setAdress] = useState("");
    const [currentPage, setCurrentPage] = useState(1) 
    const [totalPages, setTotalPages] = useState(1)
    const [transactions, setTransactions] = useState([])
    const [status, setStatus] = useState('completed');
    const [startDate, setStartDate] = useState(dayjs().subtract(7, "day"));
    const [endDate, setEndDate] = useState(dayjs());
    const [balance, setBalance] = useState();
    const [loading, setLoading] = useState(true);
    const blockChainFee = 2;

    const { handleSubmit, control, setValue, watch, formState: { errors } } = useForm();

    const amount = watch("amount")

    const freeAmount = useMemo(calculateAmountToReceive, [amount]);
    const {openModal} = useModal();
    const navigate = useNavigate();

    useEffect(fetchBalance, []);
    useEffect(fetchWithdrawals, [currentPage, startDate, endDate, status]);

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/assets")}}>Активы</a> }
    ]

    return (
        <PageWrapper loading={loading} breadcrumbs={breadcrumbs} title="Вывод">
            <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                <ThreeColumns>
                    <form onSubmit={handleSubmit(openConfirmModal)}>
                        <GlassCard>
                            <Vbox gap="10px">
                                <Hbox alignItems="end" gap="10px">
                                <Controller
                                    name="address"
                                    control={control}
                                    rules={{
                                        required: "Обязательное поле",
                                        validate: {
                                            isCorrectLength: (value) => value.length === 34 || `Должно быть 34 символа. Сейчас ${value.length}`,
                                            startsWithT: (value) => value.startsWith("T") || "Должно начинаться с буквы 'T'",
                                        },
                                    }}
                                    render={({ field }) => (
                                        <SimpleInput 
                                            label="Wallet Address" 
                                            placeholder="Введите кошелек" 
                                            error={errors.address?.message}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="amount"
                                    control={control}
                                    rules={{ required: "Обязательное поле", min: {value: 10, message: "Минимум 10"}}}
                                    render={({ field }) => (
                                        <SimpleInput
                                            label="Сумма" 
                                            placeholder="2400" 
                                            number
                                            error={errors.amount?.message}
                                            {...field}
                                        />
                                    )}
                                />
                                
                                </Hbox>
                                <SimpleButton onClick={() => {setValue("amount", balance)}}>Вывести все</SimpleButton>
                                <Text weight="bold">Форма вывода</Text>
                                
                                <Vbox gap="10px">
                                    <Hbox>    
                                        <Text>Комиссия блокчейна</Text>
                                        <Text>{blockChainFee} USDT</Text>
                                    </Hbox>
                                    <Hbox>
                                        <Text>Сумма к получению</Text>
                                        <Text>{freeAmount} USDT</Text>
                                    </Hbox>
                                    <Hbox>
                                        <Text>Текущий баланс</Text>
                                        <Text>{balance} USDT</Text>
                                    </Hbox>
                                </Vbox>
                            </Vbox>
                            <SimpleButton type="submit" main icon={<DollarOutlined />}>Подтвердить вывод</SimpleButton>
                        </GlassCard>
                    </form>
                </ThreeColumns>
                <GlassCard>
                    <Text weight="bold">История вывода</Text>
                    <Hbox wrap gap="10px">
                        <Select defaultValue={statuses[0].value} options={statuses} onChange={setStatus} />
                        <div><RangePicker onChange={({startDate, endDate}) => {setStartDate(startDate); setEndDate(endDate)}} /></div>
                    </Hbox>
                    <Table columns={columns} dataSource={transactions}/>
                    <Pagination total={totalPages} onChange={setCurrentPage} />
                </GlassCard>
            </div>
        </PageWrapper>
    );

    function openConfirmModal({amount, address}) {
        openModal(<ConfirmModal amount={amount} address={address} />, "Вывод средств")
    }

    function fetchBalance() {
        (async () => {
            try {
                const {total_balance} = await getBalanceApi();

                setBalance(total_balance)
            } catch(e) {
                console.log(e)
            }
        })();
    }

    function ConfirmModal({amount, address, closeModal}) {
        return <Vbox gap="10px">
            <Text>Подтвердите вывод средств:</Text>
            <Text>Сумма <Text weight="bold">{amount}</Text></Text>
            <Text>Кошелек <Text weight="bold">{address}</Text></Text>
            <Hbox><SimpleButton onClick={createOrder}>Подтвердить</SimpleButton></Hbox>
        </Vbox>

        function createOrder() {
            (async () => {
                closeModal();
                await handleAsyncNotification(async () => {
                    await makeRequest("/api/v1/wallets/withdrawl/withdrawals", {
                        body: { 
                            amount: parseFloat(amount), 
                            address: address, 
                        },
                    });
                    fetchWithdrawals();
                    setAdress("");
                }, {
                    errorText: 'Недостаточный баланс',
                    successText: 'Заявка на вывод успешна создана',
                    loadingText: 'Заявка формируется'
                });
            })();
        };
    }

    function formatDate(dayjsDate) {
        if(!dayjsDate) return "";
        const date = dayjsDate.toDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    function fetchWithdrawals() {
        (async () => {
            try {
                setLoading(true);

                const params = new URLSearchParams({
                    page: currentPage,
                    ...(status && { status }),
                    ...(startDate && { start_date: formatDate(startDate)  }),
                    ...(endDate && { end_date: formatDate(endDate)  })
                });
        
                const data = await makeRequest(`/api/v1/wallets/withdrawl/withdrawals?${params.toString()}`, {
                    method: 'GET',
                });
        
                const withdrawals = data.withdrawals.map(item => {
                    const statusLabel = statuses.find(status => status.value === item.status)?.label || '-';
                    return {
                        ...item,
                        status: statusLabel,
                        created_at: formatReadableDate(item.created_at)
                    };
                    });
                setTotalPages(data.total_pages)
                setTransactions(withdrawals)
                return data;
            } catch (error) {
                console.error("Ошибка при получении данных о выводах:", error);
                throw error;
            } finally {
                setLoading(false);
            }
        })();
    }

    function calculateAmountToReceive() {
        const parsedAmount = parseFloat(amount);

        if (!isNaN(parsedAmount)) {
            return (parsedAmount - blockChainFee).toFixed(2);
        }
        return '-';
    }
};

export default Withdraw;