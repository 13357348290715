import { useLocation, useNavigate } from "react-router-dom";



function useAddNavigate() {
    const navigate = useNavigate();
    const location = useLocation();

    return (path) => navigate(`${location.pathname}${path}`);
}

export default useAddNavigate;