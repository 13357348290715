import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useEffect, useState } from "react";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Hbox from "@shared/ui/layouts/Hbox";
import {useModal} from "@shared/ModalContext";
import AddDeviceModal from "@features/AddDeviceModal";
import Vbox from "@shared/ui/layouts/Vbox";
import Text from "@shared/ui/Text";
import DeleteDeviceModal from "@features/DeleteDeviceModal";
import getDevicesApi from "@shared/api/services/devices/getDevicesApi";
import GlassCard from "@shared/ui/GlassCard";
import { PlusOutlined } from "@ant-design/icons";
import ElementCard from "@shared/ui/ElementCard";


const Devices = () => {
    const {openModal} = useModal();
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(fetchDevices, []);

    return <PageWrapper loading={loading} title="Устройства">
        <Hbox customJust="end"><SimpleButton icon={<PlusOutlined />} main onClick={openAddDeviceModal}>Добавить устройство</SimpleButton></Hbox>
        <GlassCard>
            <Text weight="bold">Список устройств</Text>
            <Vbox gap="10px">
                {devices.map(({id, name, api, user_id}) => <ElementCard>
                    <Hbox>
                        <Hbox gap="10px" wrap>
                            <div><Info title="Название" value={name} /></div>
                            <div><Info title="ID пользователя" value={user_id} /></div>
                            <div><Info title="API KEY" value={api} /></div>
                        </Hbox>
                        <div>
                            <SimpleButton onClick={() => {openDeleteDeviceModal(id)}}>Удалить</SimpleButton>
                        </div>
                    </Hbox>
                </ElementCard>)}
            </Vbox>
        </GlassCard>
        
    </PageWrapper>

    function fetchDevices() {
        (async () => {
            setLoading(true);
            try {
                const devices = await getDevicesApi();

                setDevices(devices);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }

    function openAddDeviceModal() {
        openModal(<AddDeviceModal onSuccess={fetchDevices} />, 'Добавление устройства');
    }

    function openDeleteDeviceModal(deviceId) {
        openModal(<DeleteDeviceModal deviceId={deviceId} onSuccess={fetchDevices} />, 'Удаление устройства')
    }
}


function Info({title, value}) {
    return <Vbox gap="5px">
        <Text size="small">{title}</Text>
        <Text>{value || "-"}</Text>
    </Vbox>
}


export default Devices;