import React, { useState } from 'react';
import styled from 'styled-components';
import Switch from "@shared/ui/Switch"
import SimpleButton from '@shared/ui/buttons/SimpleBtn';
import SimpleInput from '@shared/ui/Simpleinput';
import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import GlassCard from "@shared/ui/GlassCard";
import CopyableText from "@shared/ui/CopyableText";
import { CreateTraders } from '@shared/api/traders_admin_api';
import Text from "@shared/ui/Text";
import CloseButton from '@shared/ui/buttons/CloseBtn';
import Table from "@shared/ui/Table";
import PageWrapper from '@shared/ui/layouts/PageWrapper';
import Pagination from '@shared/ui/Pagination';
import makeRequest from '@shared/api/makeRequest'
import ReqsModel from './elements/ReqsModel'
import { useNavigate } from 'react-router-dom';
import { LoginOutlined } from '@ant-design/icons';


const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const Modal = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--background-light);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    gap: 10px;
    width: ${props => props.modalType === 'devices' ? 'auto%' : 'auto'};
    min-width: 30%;
    height: ${props => props.height === 'devices' ? 'none' : 'none'};
    z-index: 1001;
`;



const Traders_admin = () => {
    const columnsPayin = ['ID', 'Трейдер', 'Устройство', 'Лимиты', 'Статус'];
    const [page, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1)
    const [traders, setTraders] = useState([]);
    const [currentTrader, setCurrentTrader] = useState([]);
    const [secondActive, setSecondActive] = useState('All_reqs')    

    const secondHandleClick = (name) => {
        if (secondActive !== name) {
            setSecondActive(name);
        }
    };

    const openModal = (type, trader) => {
        setModalType(type);
        setIsModalOpen(true);
        setCurrentTrader(trader);
    };

    CreateTraders({ setTraders, openModal, setTotalPages, page });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState('reqs');
    const [secondaryMenuOpen, setSecondaryMenuOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
        setModalType('');
        setSecondaryMenuOpen(false);
    };

    const navigate = useNavigate();


    const transactions_devices = [{ id: '1', name: <SimpleInput placeholder='Ксяоми 321' />, api: '1^a2S3ASd3o-?', trader: 'Помидорка', btn: <Switch />, second_btn: <Switch />, del_btn: <SimpleButton color='var(--background-light)' backGround='var(--red)' hoverBack='var(--dark_red)' borderRadius='8px'>Удалить</SimpleButton> }]
    const columns_payin = [
        { title: "ID", dataIndex: "id", width: "1px", render: (id) => <CopyableText text={id}/> },
        { title: "Ник", dataIndex: "name" },
        { title: "Почта", dataIndex: "balance" },
        { title: "", dataIndex: "action", render: ((_, {id}) => <SimpleButton onClick={() => {navigate("/traders/" + id)}} icon={<LoginOutlined />}>Перейти</SimpleButton>), width: "1px" }
    ]
    const createPayIn = async() => {
        await makeRequest('/api/v1/cascade/create_payout', {
            method: 'POST',
            body: ({
                clientUniqueId: "12d6c560-1db7-4f7c-9653-efab9f493a60",
                destination: "",
                amount: parseFloat(2000.00).toFixed(2),
                walletId: "",
                expiredTime: "",
                expiredOfferTime: "",
                sbp_bank: "",
                callback_url: "",
            })
        })
    }
    return (
        <PageWrapper title="Трейдеры">
                <HBox customJust="end">
                            <SimpleButton main onClick={createPayIn}>Тест каскада</SimpleButton>
                        </HBox>
                <GlassCard>
                <Text weight="bold">Список трейдеров</Text>
                <VBox gap='20px'>
                    <Table columns={columns_payin} dataSource={traders} />
                    <Pagination total={totalPages} onChange={setCurrentPage} />
                    {isModalOpen && (
                        <Overlay onClick={closeModal}>
                            <Modal onClick={(e) => e.stopPropagation()} modalType={modalType} height={modalType} width='auto'>
                                <HBox alignItems="center">
                                    <Text size="medium">
                                        {modalType === 'settings' ? 'Настройки' : modalType === 'reqs' ? 'Реквизиты' : 'Информация'}
                                    </Text>
                                    <CloseButton onClick={closeModal} />
                                </HBox>
                                {modalType === 'reqs' && (
                                    <ReqsModel columnsPayin={columnsPayin} secondHandleClick={secondHandleClick} secondActive={secondActive} transactions_devices={transactions_devices} trader_id={currentTrader.id} />
                                )}
                            </Modal>
                        </Overlay>
                    )}
                </VBox>
            </GlassCard>
        </PageWrapper>
        
    );
};













export default Traders_admin;