import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import getUserInfoApi from "@shared/api/services/admin/getUserInfoApi";
import getHubsListByTraderIdApi from "@shared/api/services/admin/getHubsListByTraderIdApi";
import AdminRecHubCard, { RecHubCardSkeleton } from "../../entities/AdminReqHubCard";
import Text from "@shared/ui/Text";
import styled from "styled-components";
import {useModal} from "@shared/ModalContext";
import GlassCard from "@shared/ui/GlassCard";
import EditHubLimitsModal from "@features/EditHubLimitsModal";
import getPaymentMethodsApi from "@shared/api/services/getPaymentMethodsApi";
import Pagination from "@shared/ui/Pagination";
import Hbox from "@shared/ui/layouts/Hbox";
import getTraficsApi from "@shared/api/services/getTraficsApi";
import useAddNavigate from "@shared/hooks/useAddNavigate";

// Страница с хабами конкрентного трейдера. Админ видит список его хабов и настраивает по ним лимиты

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    width: 100%;

    @media(max-width: 1100px) {
        grid-template-columns: 1fr 1fr;
    }

    @media(max-width: 800px) {
        grid-template-columns: 1fr;
    }
`

const Hubs = () => {
    const {trader_id} = useParams();
    const [hubs, setHubs] = useState([]);
    const { openModal } = useModal();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [traficModes, setTraficModes] = useState([]);
    const [username, setUsername] = useState("");
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const addNavigate = useAddNavigate();

    useEffect(fetchTraderHubs, [currentPage]);
    useEffect(fetchPaymentMethods, []);
    useEffect(fetchTraficModes, []);
    useEffect(fetchUsername, []);

    const title = `Payin-Hubs трейдера "${username}"`;
    const breadcrumbs = useMemo(getBreadcrumbs, [navigate, username]);

    const navigateToHubRequisites = (hub_id) => {addNavigate(`/${hub_id}`)};

    return <PageWrapper breadcrumbs={breadcrumbs} title={title}>
        <GlassCard>
            <Text weight="bold">Список Payin-Hubs</Text>
            <Container>
                {loading ? <>
                    <RecHubCardSkeleton />
                    <RecHubCardSkeleton />
                    <RecHubCardSkeleton />
                </> : hubs.map(({name, turbo_mode, description, id, payment_method_id, trafic_mode_id, ...rest}) => <AdminRecHubCard {...rest} id={id} traficMode={traficModes.find(el => el.id === trafic_mode_id)} paymentMethod={paymentMethods.find(el => el.id === payment_method_id)} name={name} description={description} onEdit={() => {openEditLimitsModal(id)}} onOpen={() => {navigateToHubRequisites(id)}} turbo={turbo_mode} />)}
            </Container>
            <Hbox customJust="center"><Pagination total={totalPages} onChange={setCurrentPage} value={currentPage} /></Hbox>
        </GlassCard>
    </PageWrapper>

    function fetchTraderHubs() {
        (async () => {
            setLoading(true);

            try {
                const {data, total_pages} = await getHubsListByTraderIdApi({trader_id, page: currentPage});

                setHubs(data);
                setTotalPages(total_pages);
            } catch(e) {
                console.log(e);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            }            
        })();
    }

    function fetchUsername() {
        (async () => {
            setLoading(true);

            try {
                const {username} = await getUserInfoApi(trader_id);

                setUsername(username);
            } catch(e) {
                console.log(e);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            }
        })();
    }

    function openEditLimitsModal(hub_id) {
        openModal(<EditHubLimitsModal onSuccess={fetchTraderHubs} hub_id={hub_id} />, "Правка лимитов");
    }

    function fetchPaymentMethods() {
        (async () => {
            try {
                const paymentMethods = await getPaymentMethodsApi();

                setPaymentMethods(paymentMethods);
            } catch(e) {
                console.log(e);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            }
        })();
    }

    function fetchTraficModes() {
        (async () => {
            try {
                const traficModes = await getTraficsApi();

                setTraficModes(traficModes);
            } catch(e) {
                console.log(e);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            }
            
        })();
    }

    function getBreadcrumbs() {
        return [
            { title: <a onClick={() => {navigate("/traders")}}>Трейдеры</a> },
            { title: <a onClick={() => {navigate(`/traders/${trader_id}`)}}>Трейдер "{username}"</a> },
        ]
    }
} 

export default Hubs;