import { Controller } from "react-hook-form";
import SimpleInput from "@shared/ui/Simpleinput";


const NameInput = ({control, error}) => {
    return <Controller
        name="name"
        control={control}
        rules={{ required: "Обязательное поле", minLength: { value: 5, message: "Минимум 5 символов" }, maxLength: { value: 70, message: "Максимум 70 символов" } }}
        render={({ field }) => (
            <SimpleInput
                {...field}
                label="Название"
                error={error}
                placeholder="Payin-Hub 1"
            />
        )}
    />
}

export default NameInput;