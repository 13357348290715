import makeRequest from "@shared/api/makeRequest";


export default async function({phub_id, name, amount, bank_id}) {
    return await makeRequest(`/api/v1/orders/hubs/phubs/${phub_id}/requests/create`, {
        body: {
            name, amount, bank_id
        }
    });
}
