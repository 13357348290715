import PageWrapper from '@shared/ui/layouts/PageWrapper';
import Text from "@shared/ui/Text";
import GlassCard from "@shared/ui/GlassCard";
import PagePreloader from './elements/PagePreloader';
import TransactionsTable from './elements/TransactionsTable';
import Selects from './elements/Selects';
import Hbox from "@shared/ui/layouts/Hbox";
import Filters from './elements/Filters';
import ClearFiltersButton from './elements/ClearFiltersButton';
import AttentionButton from './elements/AttentionButton';


const Orders = () => {
    return <PageWrapper title="Ордера" ContentPreloader={PagePreloader}>
        <GlassCard>
            <Text weight="bold">Список транзакций</Text>
            <Hbox wrap gap="10px" alignItems="end">
                <Hbox customJust="start" wrap gap="10px" alignItems="end">
                    <Selects />
                    <Filters />
                    <ClearFiltersButton />
                </Hbox>
                <AttentionButton />
            </Hbox>
            <TransactionsTable />
        </GlassCard>
    </PageWrapper>
};

export default Orders;
