import PageWrapper from "@shared/ui/layouts/PageWrapper";
import Table from "@shared/ui/Table";
import Pagination from "@shared/ui/Pagination";
import { useEffect, useState } from "react";
import makeRequest from "@shared/api/makeRequest";
import { useNavigate } from "react-router-dom";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import useAddNavigate from "@shared/hooks/useAddNavigate";
import { LoginOutlined } from "@ant-design/icons";




const BanksSettings = () => {
    const [loading, setLoading] = useState(false);
    const [banks, setBanks] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate();
    const addNavigate = useAddNavigate();
    useEffect(fetchBanks, [currentPage]);

    const columns = [{title: 'Id', dataIndex: 'id'}, {title: 'Название', dataIndex: 'name'}, {title: 'Code', dataIndex: 'code'}, { title: "", dataIndex: "", render: (_, {id}) => <SimpleButton onClick={() => {addNavigate(`/${id}`)}} icon={<LoginOutlined />}>Перейти</SimpleButton>, width: "1px" }];

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/settings")}}>Настройки</a> }
    ]

    return <PageWrapper breadcrumbs={breadcrumbs} title="Настройки банков">
        <Table loading={loading} columns={columns} dataSource={banks} />
        <Pagination total={totalPages} onChange={setCurrentPage} />
    </PageWrapper>

    function fetchBanks() {
        (async () => {
            try {
                setLoading(true);

                const {data, total_pages} = await makeRequest(`/api/v1/core/banks/list/?page=${currentPage}`, { method: "GET" });

                setBanks(data);
                setTotalPages(total_pages);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }

    // function fetchData() {
    //     (async () => {
    //         try {
    //             const data = await makeRequest(`/api/v1/core//api/v1/core/trading_methods/details/${id}?type=bank`)
    //         } catch(e) {
    //             console.log(e);
    //         }
    //     })();
    // }
}

export default BanksSettings;