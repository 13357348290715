import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Select from "@shared/ui/Select";
import Hbox from "@shared/ui/layouts/Hbox";
import Text from "@shared/ui/Text";
import Info from "./Info";
import {TwoColumns} from "@shared/ui/containers/ListContainers";


const Trafic = () => {
    return <GlassCard>
        <Hbox customJust="start" gap="10px">
            <Select items={[]} label="Дата" />
            <Select items={[]} label="Валюта" />
            <Select items={[]} label="Мерчант" />
            <Select items={[]} label="Магазин" />
            <Select items={[]} label="Трейдер" />
            <Select items={[]} label="Хаб" />
            <Select items={[]} label="Метод" />
            <Select items={[]} label="Банк" />
            <Select items={[]} label="Способ оплаты (payin / payout)" />
        </Hbox>

        <TwoColumns>
            <ElementCard>
                <Info title="Объем трафика" value="500 000 руб. " />
                <Info title="Объем ожидаемого трафика" value="500 000 руб. " />
                <Info title="Количество ордеров" value="500 шт. " />
                <Info title="Количество успешных ордеров" value="500 шт. " />
                <Info title="Количество отмененных ордеров" value="500 шт. " />
                <Info title="Количество диспутов" value="500 шт." />
                <Info title="Скорость обработки диспутов" value="1 диспут за 3 минуты" />
            </ElementCard>
            <ElementCard>
                <Info title="Средний чек" value="500 000 руб. " />
                <Info title="Конверсия автоматики" value="50 %" />
                <Info title="Конверсия в выдачу" value="50 %" />
                <Info title="Конверсия по оплате клиентом" value="50 %" />
                <Info title="Кешбек площадки" value="500 USDT" />
                <Info title="Кешбек трейдеров" value="500 USDT" />
            </ElementCard> 
        </TwoColumns>
         
    </GlassCard>
}



export default Trafic;