import { Upload as AntdUpload, message } from 'antd';
import Label from "@shared/ui/Label";
import axios from 'axios';

const Upload = ({ fileList = [], request, onChange, label }) => {
    const handleChange = ({ file, fileList }) => {
        // const isPDF = file.type === 'application/pdf';
        // if (!isPDF) {
        //     message.error(`${file.name} не является PDF файлом!`);
        // }
        // // Фильтруем fileList, оставляя только PDF-файлы
        // const filteredFileList = fileList.filter((file) => file.type === 'application/pdf');
        // onChange(filteredFileList);
        onChange(fileList)
    };

    return (
        <Label value={label}>
            <AntdUpload
                customRequest={request} // Используем кастомный запрос
                listType="picture-card"
                fileList={fileList}
                onChange={handleChange}
                // accept=".pdf" // Ограничиваем выбор файлов только PDF
                multiple // Если нужно разрешить множественный выбор
            >
                Загрузить PDF
            </AntdUpload>
        </Label>
    );
};

export default Upload;
