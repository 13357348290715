import { Controller } from "react-hook-form";
import SimpleInput from "@shared/ui/Simpleinput";


const ControllerInput = ({control, defaultValues, label, name, errors}) => {
    return <Controller
        name={name}
        control={control}
        defaultValue={defaultValues[name] || 0}
        rules={{ required: "Обязательное поле", min: { value: 0, message: "Минимум 1" } }}
        render={({ field }) => (
            <SimpleInput
                {...field}
                defaultValue={defaultValues[name] || 0}
                label={label}
                error={errors[name]?.message}
                number
                placeholder="10 000"
            />
        )}
    />
}

export default ControllerInput;