import { useNavigate } from "react-router-dom";
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useEffect, useState, useMemo } from "react";
import Hbox from "@shared/ui/layouts/Hbox";
import {useModal} from "@shared/ModalContext";
import Pagination from "@shared/ui/Pagination";
import getPhubsApi from "@shared/api/services/trader/phubs/getPHubsApi";
import EditPHubModal from "./elements/EditPHubModal";
import DeletePHubModal from "./elements/DeletePHubModal";
import Vbox from "@shared/ui/layouts/Vbox";
import { DeleteOutlined, EditOutlined, LoginOutlined } from "@ant-design/icons";
import getPaymentMethodsApi from "@shared/api/services/getPaymentMethodsApi";
import getFiatCurrenciesApi from "@shared/api/services/getFiatCurrenciesApi";
import Text from "@shared/ui/Text";
import Table from "@shared/ui/Table";
import CopyableText from "@shared/ui/CopyableText";
import GlassCard from "@shared/ui/GlassCard";
import SwitchPHubStatus from "./elements/SwitchPHubStatus";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";


const PayoutHubs = () => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [phubs, setPHubs] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const { openModal } = useModal();
    const navigate = useNavigate();

    useEffect(fetchPaymentMethods, []);
    useEffect(fetchCurrencies, []);
    useEffect(fetchPHubs, [currentPage]);

    const columns = useMemo(getColumns, [paymentMethods, currencies, currentPage]);

    return (
        <PageWrapper loading={loading} title="Просмотр Payout-Hubs">
            <Vbox gap="10px">
                <GlassCard>
                    <Text weight="bold">Список Payout-Hubs</Text>
                    <Table loading={loading} columns={columns} dataSource={phubs} />
                    <Hbox customJust="center">
                        <Pagination value={currentPage} total={totalPages} onChange={setCurrentPage} />
                    </Hbox>
                </GlassCard>
            </Vbox>
        </PageWrapper>
    );

    function fetchPaymentMethods() {
        (async () => {
            try {
                const paymentMethods = await getPaymentMethodsApi();
                setPaymentMethods(paymentMethods);
            } catch (e) {
                console.log(e);
            }
        })();
    }

    function fetchCurrencies() {
        (async () => {
            try {
                const currencies = await getFiatCurrenciesApi();
                setCurrencies(currencies);
            } catch (e) {
                console.log(e);
            }
        })();
    }

    function getColumns() {
        return [
            { title: "ID", dataIndex: "id", render: (value) => <CopyableText text={value} />, width: "1px" },
            { title: "Название", dataIndex: "name" },
            { title: "Метод оплаты", dataIndex: "payment_method_id", render: (value) => paymentMethods.find(({ id }) => id === value)?.name },
            { title: "Валюта", dataIndex: "currency_id", render: (value) => currencies.find(({ id }) => id === value)?.currency },
            { title: "Мин. сумма транзакции", dataIndex: "min_transaction_amount" },
            { title: "Макс. сумма транзакции", dataIndex: "max_transaction_amount" },
            { title: "Максимальное количество транзакций", dataIndex: "max_active_tx" },
            { title: "Статус (Трейдер)", dataIndex: "user_enabled", render: (value, { id }) => <SwitchPHubStatus defaultValue={value} onSuccess={fetchPHubs} phub_id={id} /> },
            { title: "Статус (Админ)", dataIndex: "admin_enabled", render: (value) => value ? "Включен" : "Не включен" },
            {
                title: "", width: "1px", dataIndex: "action", render: (_, { id, name }) => (
                    <Hbox gap="10px">
                        <SimpleButton onClick={() => {navigate("/payouthubs/" + id)}} icon={<LoginOutlined />}>К заявкам</SimpleButton>
                        <SimpleButton onClick={() => {openEditPHubModal(id)}} icon={<EditOutlined />} />
                        <SimpleButton onClick={() => {openDeletePHubModal(id, name)}} icon={<DeleteOutlined />} />
                    </Hbox>
                )
            },
        ];
    }

    function openEditPHubModal(phub_id) {
        openModal(<EditPHubModal phub_id={phub_id} onSuccess={fetchPHubs} />, "Изменение Payout-Hub");
    }

    function openDeletePHubModal(phub_id, phub_name) {
        openModal(<DeletePHubModal phub_id={phub_id} phub_name={phub_name} onSuccess={fetchPHubs} />, "Удаление Payout-Hub");
    }

    function fetchPHubs() {
        (async () => {
            setLoading(true);
            try {
                const { data, total_pages } = await getPhubsApi(currentPage);

                setTotalPages(total_pages);
                setPHubs(data);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }
};


export default PayoutHubs;