import Vbox from "@shared/ui/layouts/Vbox";
import Table from "@shared/ui/Table";
import Skeleton from "@shared/ui/Skeleton";
import { useMemo } from "react";

const HeaderSkeleton = () => <Skeleton height="20px" width="150px" />
const RowSkeleton = () => <Skeleton height="20px" width="100px"/>

// Загрузка работает только один раз
const TableSkeleton = ({columnsAmount = 2, rowsAmount = 2, lastButton = false}) => {
    const columns = useMemo(getColumns, [columnsAmount]);
    const rows = useMemo(getRows, [columnsAmount, rowsAmount]);


    return <Table columns={columns} dataSource={rows} />

    function getColumns() {
        const columns = [];

        for(let i = 0; i < columnsAmount; i++) {
            columns.push(getColumn(i));
        }

        return columns;

        function getColumn(index) {
            return {
                title: <HeaderSkeleton />,
                key: `key${index}`,
                dataIndex: `key${index}`,
            }
        }
    }

    function getRows() {
        const rows = [];

        const row = getRow();

        for(let i = 0; i < rowsAmount; i++) {
            rows.push(row)
        }

        return rows;

        function getRow() {
            const row = {};

            for(let i = 0; i < columnsAmount; i++) {
                row[`key${i}`] = <RowSkeleton />;
            }

            return row;
        }
    }
}

export default TableSkeleton;


