import { Table as AntdTable, Empty as AntdEmpty, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";

// Стилизация таблицы через styled-components
const StyledTable = styled(AntdTable)`
  .ant-table {
    background-color: ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.color};
    border: 1px solid ${(props) => props.theme.border};
  }

  .ant-table-thead > tr > th {
    background-color: ${(props) => props.theme.native};
    color: ${(props) => props.theme.color};
    border-bottom: 1px solid ${(props) => props.theme.border};
  }

  .ant-table-tbody > tr > td {
    background-color: ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.color};
    border-bottom: 1px solid ${(props) => props.theme.border};
    position: relative; /* Для внутренних элементов */
  }

  /* Подсветка строки при наведении */
  .ant-table-tbody > tr:hover > td {
    background-color: ${(props) => props.theme.hover} !important;
  }
`;


// Стилизация Empty через styled-components
const StyledEmpty = styled(AntdEmpty)`
  .ant-empty-description {
    color: ${(props) => props.theme.color}; /* Белый текст в описании */
  }
`;

const Table = ({ loading: outerLoading = false, columns: outerColumns, dataSource, ...props }) => {
  const [loading, setLoading] = useState(outerLoading);
  const columns = useMemo(() => outerColumns.map(normalize), [outerColumns]);

  useEffect(() => {
    if (outerLoading === false) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      setLoading(true);
    }
  }, [outerLoading]);

  return (
    <StyledTable
      columns={columns}
      dataSource={dataSource.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))} // Дефолтная сортировка по полю created_at
      loading={{
        spinning: loading,
        indicator: <Spin indicator={<LoadingOutlined />} />, // Используем свой лоадер
      }}
      scroll={{ x: "max-content" }}
      size="small"
      locale={{
        emptyText: loading ? null : (
          <div>
            <StyledEmpty
              image={AntdEmpty.PRESENTED_IMAGE_SIMPLE}
              description="Ничего не найдено"
            />
          </div>
        ),
      }}
      pagination={false}
      {...props}
    />
  );

  function normalize({ dataIndex, title, ...rest }) {
    return { dataIndex, title, key: dataIndex, ...rest };
  }
};

export default Table;
