import Vbox from "@shared/ui/layouts/Vbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Hbox from "@shared/ui/layouts/Hbox";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import addDeviceApi from "@shared/api/services/addDeviceApi";
import SimpleInput from "@shared/ui/Simpleinput";
import { useForm, Controller } from "react-hook-form";
import { PlusOutlined } from "@ant-design/icons";


const AddDeviceModal = ({closeModal, onSuccess}) => {
    const { handleSubmit, control, formState: { errors } } = useForm();

    return <form onSubmit={handleSubmit(addDevice)}>
        <Vbox gap="10px">
            <Controller
                name="name"
                control={control}
                rules={{ required: "Обязательное поле", minLength: { value: 5, message: "Минимум 5 символов" }, maxLength: { value: 100, message: "Максимум 100 символов" } }}
                render={({ field }) => (
                    <SimpleInput
                        {...field}
                        label="Название устройства"
                        error={errors.name?.message}
                        placeholder="Устройство 1"
                    />
                )}
            />
            <Hbox customJust="end"><SimpleButton main icon={<PlusOutlined />} type="submit">Добавить</SimpleButton></Hbox>
        </Vbox>
    </form>
    

    async function addDevice({name}) {
        try {
            closeModal();
            await handleAsyncNotification(async () => {
                await addDeviceApi(name);
                onSuccess();
            }, {
                loadingText: "Добавление устройства",
                successText: "Устройство добавлено",
                errorText: "Ошибка добавления устройства"
            });
        } catch(e) {
            console.log(e);
        }
    }
}

export default AddDeviceModal;