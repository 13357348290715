import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import makeRequest from "@shared/api/makeRequest";
import formatReadableDate from "@shared/helpers/formateReadableDate";
import HBox from "@shared/ui/layouts/Hbox";
import SimpleBtn from "@shared/ui/buttons/SimpleBtn";
import Table from "@shared/ui/Table";
import CopyableText from "@shared/ui/CopyableText";
import Pagination from "@shared/ui/Pagination";
import Select from "@shared/ui/Select";


const statuses = [{ label: 'Завершенные', value: 'completed' }, { label: 'В процессе', value: 'in_processing' },  { label: 'Отклонено', value: 'canceled' }]




const Withdrawals = () => {
    const [status, setStatus] = useState(statuses[0].value);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [transactions, setTransactions] = useState([]);

    useEffect(fetchWithdrawalOrders, [status, currentPage]);
    const navigate = useNavigate();

    const confirmWithdrawal = async (withdrawalId) => {
        await handleAsyncNotification(async () => {
            await makeRequest(`/api/v1/wallets/withdrawl/withdrawals/${withdrawalId}/confirm`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });     
            }, {
                errorText: 'Ошибка подтверждения вывода',
                successText: 'Заявка на вывод подтверждена',
                loadingText: 'Заявка в процессе выполнения'
            }
        )
        fetchWithdrawalOrders();
    };
    
    // Функция для отклонения вывода
    const rejectWithdrawal = async (withdrawalId) => {
        await handleAsyncNotification(async () => {
            await makeRequest(`/api/v1/wallets/withdrawl/withdrawals/${withdrawalId}/reject`);
            }, {
                errorText: 'Ошибка отмены заявки вывода',
                successText: 'Заявка на вывод успешно отменена',
                loadingText: 'Заявка в процессе выполнения'
            })
        fetchWithdrawalOrders();
        
    };
    const columns = [
        { dataIndex: "id", title: "ID", render: (value) => <CopyableText text={value} /> },
        { dataIndex: "username", title: "Пользователь" },
        { dataIndex: "amount", title: "Сумма" },
        { dataIndex: "address", title: "Адрес", render: (value) => <CopyableText text={value} /> },
        { dataIndex: "status", title: "Статус" },
        { dataIndex: "created_at", title: "Создана", render: (value) => formatReadableDate(value) },
        { dataIndex: "hash", title: "Хэш", render: (value) => <CopyableText text={value} /> },
        { dataIndex: "updated_at", title: "Обновлена", render: (value) => formatReadableDate(value) },
        {
            dataIndex: "actions",
            title: "Действия",
            render: (_, record) =>
              record.status === 'В процессе' && (
                <HBox gap="10px">
                  <SimpleBtn onClick={() => confirmWithdrawal(record.id)}>Подтвердить</SimpleBtn>
                  <SimpleBtn onClick={() => rejectWithdrawal(record.id)}>Отклонить</SimpleBtn>
                </HBox>
              ),
        },
    ]
    return <>
        <HBox>
            <Select value={status} onChange={setStatus} options={statuses} />
        </HBox>
        <Table loading={loading} dataSource={transactions} columns={columns} />
        <Pagination total={totalPages} onChange={setCurrentPage}/>
    </>

    function fetchWithdrawalOrders() {
        (async () => {
            setLoading(true);

            try {
                const params = new URLSearchParams({
                    page: currentPage,
                    ...(status && { status })
                });
                const data = await makeRequest(`/api/v1/wallets/withdrawl/withdrawals?${params.toString()}`, {
                    method: 'GET',
                });
                const withdrawals = data.withdrawals.map(item => {
                    const statusLabel = statuses.find(status => status.value === item.status)?.label || '-';
                    return {
                        ...item,
                        status: statusLabel,
                        created_at: formatReadableDate(item.created_at),
                        ...(item.status === 'in_processing' && {
                            btn: (
                                <HBox gap="10px">
                                    <SimpleBtn onClick={() => confirmWithdrawal(item.id)}>Подтвердить</SimpleBtn>
                                    <SimpleBtn onClick={() => rejectWithdrawal(item.id)}>Отклонить</SimpleBtn>
                                </HBox>
                            )
                        })
                    };
                });
                setTotalPages(data.total_pages)
                setTransactions(withdrawals)
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }
}

export default Withdrawals;