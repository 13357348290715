export default function formatReadableTime(isoDate) {
    const date = new Date(isoDate);

    if (isNaN(date.getTime())) {
        return 'Invalid Date'; // Возвращаем ошибку, если передана некорректная дата
    }

    // Извлекаем часы и минуты
    const hours = String(date.getHours()).padStart(2, '0'); // Дополняем до двух цифр
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Дополняем до двух цифр

    return `${hours}:${minutes}`; // Возвращаем время в формате HH:MM
}
