import HBox from '@shared/ui/layouts/Hbox';
import Select from "@shared/ui/Select";
import useSearchParams from '@shared/hooks/useSearchParams';
import { allTransactionStatuses } from "@app/constants";
import { useEffect } from 'react';


const transactionTypes = [
    { label: "Payin", value: "payin" },
    { label: "Payout", value: "payout" }
]

const Selects = () => {
    const {searchParams, updateSearchParams} = useSearchParams();
    const type = searchParams.get("type") || transactionTypes[0].value;
    const section = searchParams.get("section") || "all";
    const transactionStatuses = allTransactionStatuses[section][type];
    const status = searchParams.get("status") || transactionStatuses[0].value;

    return <HBox wrap alignItems="end" customJust="start" gap="10px">
        <Select label="Статус транзакции" options={transactionStatuses} onChange={(status) => {updateSearchParams({status, type})}} value={status} />
        <Select label="Тип транзакции" options={transactionTypes} onChange={(type) => {updateSearchParams({type, status})}} value={type} />
    </HBox>
};

export default Selects;
