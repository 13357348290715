import makeRequest from "../makeRequest"


export default async function({transaction_id, reason_id, amount, receipts}) {
    return await makeRequest("/api/v1/orders/dispute/open", {
        body: {
            transaction_id,
            reason_id,
            amount,
            receipts
        }
    });
}