import PageWrapper from "@shared/ui/layouts/PageWrapper"
import { useEffect, useMemo, useState } from "react";
import Text from "@shared/ui/Text";
import GlassCard from "@shared/ui/GlassCard";
import Table from "@shared/ui/Table";
import { useNavigate } from "react-router-dom";
import PagePreloader from "./elements/PagePreloader";
import makeRequest from "@shared/api/makeRequest"
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { LoginOutlined } from "@ant-design/icons";
import useAddNavigate from "@shared/hooks/useAddNavigate";


const getColumns = (addNavigate) => {
    return [
        {
            title: "Платежная система",
            dataIndex: "name",
        },
        {
            title: "Баланс",
            dataIndex: "balance",
            render: (value) => value ? value.toFixed(2) : "0.00"
        },
        {
            title: "Тип ордера",
            dataIndex: "type_order",
            render: (value) => value ? value.toUpperCase() : ""
        },
        {
            title: "Метод",
            dataIndex: "method",
        },
        {
            title: "Комиссия",
            dataIndex: "commission",
            render: (value) => value ? value.toFixed(2) : "0.00"
        },
        {
            title: "Base Rate",
            dataIndex: "base_rate",
        },
        {
            title: "Final Rate",
            dataIndex: "final_rate",
        },
        {
            title: "",
            dataIndex: "",
            render: (_, {name}) => <SimpleButton onClick={() => {addNavigate(`/${name.toLowerCase()}`)}} icon={<LoginOutlined />}>Перейти</SimpleButton>,
            width: "1px"
        }
    ]
}

const Cascades = () => {
    const [cascades, setCascades] = useState([]);
    const [loading, setLoading] = useState(true);
    const addNavigate = useAddNavigate();

    useEffect(fetchCascades, []);
    const columns = useMemo(() => getColumns(addNavigate), [addNavigate]);

    return <PageWrapper ContentPreloader={PagePreloader} loading={loading} title="Каскад">
        <GlassCard>
            <Text weight="bold">Список каскадов</Text>
            <Table loading={loading} columns={columns} dataSource={cascades} />
        </GlassCard>
    </PageWrapper>

    function fetchCascades() {
        (async () => {
            setLoading(true);

            try {
                const cascades = await makeRequest("/api/v1/cascade/all", {
                    method: 'GET'
                });
                
                setCascades(cascades);
            } catch(e) {
                console.log(e);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 800);
            }
        })();
    }
}

export default Cascades;