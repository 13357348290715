import { useEffect, useMemo, useState } from "react";
import getLinkedUsersApi from "@shared/api/services/admin/assosiations/getLinkedUsersApi";
import Vbox from "@shared/ui/layouts/Vbox";
import Text from "@shared/ui/Text";
import Hbox from "@shared/ui/layouts/Hbox";
import Table from "@shared/ui/Table";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import deleteAssosiationApi from "@shared/api/services/admin/assosiations/deleteAssosiationApi";
import {useModal} from "@shared/ModalContext";
import Pagination from "@shared/ui/Pagination";
import { DeleteOutlined } from "@ant-design/icons";


const getColumns = (role, openDeleteModal) => {
    const columns = [
        {
            title: "Имя пользователя",
            dataIndex: "name",
        },
        {
            title: "Полное имя",
            dataIndex: "fullname",
        },
        {
            title: "Payin",
            dataIndex: "payin"
        },
        {
            title: "Payout",
            dataIndex: "payout"
        }
    ]
    if(role === "shop") {
        columns.push({
            title: "Мерчант",
            dataIndex: "username",
        })
    }

    return [...columns, {
        title: "",
        dataIndex: "action",
        width: "1px",
        render: (_, {association_id, name, fullname}) => <SimpleButton onClick={() => {openDeleteModal(association_id, name || fullname)}}>Отвязать</SimpleButton>
    }]
}

// Страница для просмотра привязанных Трейдеров и (или) Шопов к агенту, чей id указан в квери
const Linkeds = ({agent_id, agentName, currentRole, searchValue}) => {
    const [linkeds, setLinkeds] = useState([]);
    const [linkedsLoading, setLinkedsLoading] = useState(true);
    const {openModal} = useModal();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const columns = useMemo(() => getColumns(currentRole, openDeleteModal), [currentRole, openDeleteModal]);

    useEffect(fetchLinkedsByRole, [currentRole, searchValue, currentPage]);

    return <Vbox gap="10px">
        <Table loading={linkedsLoading} columns={columns} dataSource={linkeds} />
        <Hbox customJust="center">
            <Pagination total={totalPages} onChange={setCurrentPage} />
        </Hbox>
    </Vbox>
    
    function fetchLinkedsByRole() {
        (async () => {
            if(!currentRole) return;

            setLinkedsLoading(true);
            try {
                const {data, total_pages} = await getLinkedUsersApi({user_id: agent_id, role: currentRole, name: searchValue, page: currentPage});
                
                setLinkeds(data);
                setTotalPages(total_pages);
            } catch(e) {
                console.log(e);
            } finally {
                setLinkedsLoading(false);
            }
        })();
    }

    function openDeleteModal(association_id, entityName) {
        openModal(<DeleteAssosiationModal agentName={agentName} agent_id={agent_id} association_id={association_id} entityName={entityName} type={currentRole} onSubmit={fetchLinkedsByRole} />, "Удаление связи")
    }
}

function DeleteAssosiationModal({entityName, agent_id, association_id, type, agentName, closeModal, onSubmit}) {
    return <Vbox gap="10px">
        <Text>Подтвердите удаление связи между</Text>
        <Text>Агент: <Text weight="bold">{agentName}</Text></Text>
        <Text>Пользователь: <Text weight="bold">{entityName}</Text></Text>
        <Hbox customJust="end">
            <SimpleButton fullWidth={false} main icon={<DeleteOutlined />} onClick={deleteAssosiation}>Удалить связь</SimpleButton>
        </Hbox>
    </Vbox>

    async function deleteAssosiation() {
        closeModal();
        await handleAsyncNotification(async () => {
            await deleteAssosiationApi({user_id: agent_id, association_id, type});
        }, {
            successText: "Отвязка успешна",
            errorText: "Ошибка отвязки",
            loadingText: "Отвязка"
        });
        onSubmit();
    }
}

export default Linkeds;