import Skeleton from "@shared/ui/Skeleton";
import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import DropDownSkeleton from "@shared/ui/skeletons/DropDownSkeleton";
import GlassCardSkeleton from "@shared/ui/skeletons/GlassCardSkeleton";
import styled from "styled-components";


const List = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
`

const CardSkeleton = () => <Skeleton height="180px" width="100%" customStyle={{borderRadius: "20px"}} />


const PagePreloader = () => {
    return <Vbox gap="10px">
        <Hbox gap="10px" customJust="end"><DropDownSkeleton /><DropDownSkeleton /></Hbox>
        <GlassCardSkeleton />
        <DropDownSkeleton />
        <List>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
        </List>
    </Vbox>
}

export default PagePreloader;