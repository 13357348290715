import Vbox from "@shared/ui/layouts/Vbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Hbox from "@shared/ui/layouts/Hbox";
import Text from "@shared/ui/Text";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import deletePHubApi from "@shared/api/services/admin/phubs/deletePHubApi";


const DeletePHubModal = ({phub_id, phub_name, onSuccess, closeModal}) => {
    return <Vbox gap="10px">
        <Text>Подтвердите удаление Payout-Hub "{phub_name}"</Text>
        <Hbox customJust="end"><SimpleButton onClick={deletePHub}>Удалить</SimpleButton></Hbox>
    </Vbox>

    async function deletePHub() {
        closeModal();
        await handleAsyncNotification(async () => {
            await deletePHubApi(phub_id);
            onSuccess();
        }, {
            successText: "Удаление Payout-Hub",
            errorText: "Ошибка удаления Payout-Hub",
            loadingText: "Удаление Payout-Hub"
        });
    }
}

export default DeletePHubModal;