import React, { useState, useEffect, useMemo } from 'react';
import getPayinTransactionsApi from '@shared/api/services/getPayinTransactionsApi';
import getPayoutTransactionsApi from '@shared/api/services/getPayoutTransactionsApi';
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import CopyableText from "@shared/ui/CopyableText";
import Table from '@shared/ui/Table';
import formatReadableDate from "@shared/helpers/formateReadableDate";
import Pagination from '@shared/ui/Pagination';
import useDebounce from "@shared/hooks/useDebounce";
import { useNavigate } from 'react-router-dom';
import { LoginOutlined } from '@ant-design/icons';
import Timer from "@shared/ui/Timer";
import { transactionStatuses } from '@app/constants';
import useSearchParams from "@shared/hooks/useSearchParams";
import { Tag } from 'antd';
import { transactionTypes } from '@app/constants';
import dayjs from "dayjs";
import getAttentionPayinTransactions from '@shared/api/services/getAttentionPayinTransactions';


const TransactionsTable = () => {
    const {searchParams, updateSearchParams} = useSearchParams();
    const transactionType = searchParams.get("type") || transactionTypes[0].value;
    const transactionStatus = searchParams.get("status") || transactionStatuses[0].value;
    const chosenBanks = useChosenBanksFromQuery();
    const cardNumber = searchParams.get("card_number");
    const method = searchParams.get("method");
    const order_id = searchParams.get("order_id");
    const amount = searchParams.get("amount");
    const date_from = searchParams.get("date_from");
    const date_to = searchParams.get("date_to");
    const page = +searchParams.get("page") || 1;
    const section = searchParams.get("section");
    const [transactions, setTransactions] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const filters = useMemo(getFilters, [chosenBanks, cardNumber, method, order_id, amount, date_from, date_to]);
    const debouncedFilters = useDebounce(filters, 600);
    const navigate = useNavigate();
    const transactionsPerPage = 10;

    const columns = useMemo(getColumns, []);

    useEffect(initTransactionsLoopFetching, [transactionStatus, transactionType, page, debouncedFilters, section]);
    useEffect(fetchTransactions, [transactionStatus, transactionType, page, debouncedFilters, section]);

    return (
        <>
            <Table columns={columns} dataSource={transactions} />
            <Pagination value={page} total={totalPages} onChange={(page) => {updateSearchParams({page})}} />
        </>
    );

    function initTransactionsLoopFetching() {
        const interval = setInterval(fetchTransactions, 5000);

        return () => {
            clearInterval(interval);
        };
    }

    function fetchTransactions() {
        (async () => {            
            try {
                const skip = (page - 1) * transactionsPerPage;
                const data = section === "attention" ? await fetchAttentionOrders() :  await fetchOrders();
                if (!data.data || !Array.isArray(data.data)) {
                    setTransactions([]);
                } else {
                    // const formattedTransactions = formatTransactions({transactions: data.data, openModal, closeModal, userRole: user.role, transactionStatus});
                    setTransactions(data.data);
                }
                validatePage();
                setTotalPages(data.total_pages);

                async function fetchOrders() {
                    const methods = {
                        payin: getPayinTransactionsApi,
                        payout: getPayoutTransactionsApi
                    }
    
                    return await methods[transactionType]({skip, limit: transactionsPerPage, transactionStatus, ...debouncedFilters});
                }

                async function fetchAttentionOrders() {
                    const methods = {
                        payin: getAttentionPayinTransactions,
                        payout: getAttentionPayinTransactions
                    }
    
                    return await methods[transactionType]({skip, limit: transactionsPerPage, transactionStatus, ...debouncedFilters});
                }

                function validatePage() { // Следим, чтобы текущая страница не была больше общего кол-ва страниц
                    if(page > data.total_pages) {
                        updateSearchParams({page: data.total_pages || 1});
                    }
                }
            } catch (err) {
                console.error('Failed to fetch transactions:', err);
                setTransactions([]);
            }
        })();
    }

    function getColumns() {
        return [
            { dataIndex: "id", title: "ID", render: (value) => <CopyableText text={value} />, width: "1px" },
            { dataIndex: "customer", title: "ID клиента", render: (value) => <CopyableText text={value?.id} />, width: "1px" },
            { dataIndex: "requisites", title: "Номер реквизита", render: (value) => value?.requisite_number },
            { dataIndex: "shop", title: "Магазин", render: (value) => value?.name },
            { dataIndex: "expiration_time", title: "Таймер", render: (value) => <Timer endDate={new Date(value)} />  },
            { dataIndex: "reaction_time", title: "Время на оспаривание", render: (value, {attention_status}) => <Tag color={attention_status || "green"}><Timer endDate={new Date(value)} /></Tag> },
            { dataIndex: "status", title: "Статус транзакции" },
            { dataIndex: "fiat", title: "Валюта", render: (value) => value?.currency },
            { dataIndex: "update_date", title: "Дата изменения", render: (value) => formatReadableDate(value) },
            { dataIndex: "requisites", title: "Банк", render: (value) => value?.bank?.name },
            { dataIndex: "amount", title: "Сумма" },
            { dataIndex: "payment_type", title: "Способ" },
            { dataIndex: "action", key: "_", render: (_, {id}) => <SimpleButton onClick={() => {navigate(`/orders/${id}`)}} icon={<LoginOutlined />}>Перейти</SimpleButton>, width: "1px", fixed: "right" }
        ];
    }

    function getFilters() {
        return {
            ...(chosenBanks ? {chosenBanks} : {}), 
            ...(cardNumber ? {card_number: cardNumber} : {}), 
            ...(method ? {method} : {}),
            ...(order_id ? {order_id} : {}), 
            ...(amount ? {amount} : {}), 
            ...(date_from ? {date_from: dayjs(date_from).toISOString()} : {}), 
            ...(date_to ? {date_to: dayjs(date_to).toISOString()} : {})
        }
    }

    function useChosenBanksFromQuery() {
        const chosenBanksQuery = searchParams.get("banks");
        const chosenBanks = useMemo(() => {
            return chosenBanksQuery?.split("_").filter(el => el.length > 0);
        }, [chosenBanksQuery]);
    
        return chosenBanks;
    }
};

export default TransactionsTable;
