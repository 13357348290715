import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

// Подключаем плагины
dayjs.extend(utc);
dayjs.extend(timezone);

function convertUTCToLocal(utcTimeString) {
    // Преобразуем строку UTC в Day.js объект
    const utcTime = dayjs.utc(utcTimeString);
    // Преобразуем в локальную временную зону
    return utcTime.local().format("YYYY-MM-DD HH:mm:ss");
}

export default convertUTCToLocal;