// src/components/sections/Section1.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from "@shared/ui/Table";
import GlassCard from "@shared/ui/GlassCard";
import Hbox from "@shared/ui/layouts/Hbox";
import Text from "@shared/ui/Text";
import makeRequest from "@shared/api/makeRequest";
import InfoCard from '@shared/ui/InfoCard';
import PageWrapper from "@shared/ui/layouts/PageWrapper"

const data1 = [
    { name: 'Day 1', value: 1000 },
    { name: 'Day 2', value: 1300 },
    { name: 'Day 3', value: 4000 },
    { name: 'Day 4', value: 1200 },
    { name: 'Day 5', value: 1400 },
    { name: 'Day 6', value: 2000 },
    { name: 'Day 7', value: 1700 },
    { name: 'Day 8', value: 7000 },
];


const Assets = ( {onSectionChange} ) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    let columns = [
        { title: "Телефон ID", dataIndex: "txid" },
        { title: "Отправитель", dataIndex: "address" },
        { title: "Сообщение", dataIndex: "sms" },
        { title: "Дата & Время", dataIndex: "dateTime" },
        { title: "Категория", dataIndex: "status" }
    ];

    const [balance, setBalance] = useState({})
    useEffect(fetchBalance, []);
    return (
        <PageWrapper loading={loading} title="Активы">
            <GlassCard>
                <Text weight="bold">Основные балансы</Text>
                <Hbox gap="10px">
                    <InfoCard data={data1} currency="USDT" title="Баланс площадки" value={930732}/>
                    <InfoCard data={data1} currency="USDT" title="Баланс для выплат" value={930732} />
                </Hbox>
            </GlassCard>       
            <GlassCard>
                <Text weight="bold">Уведомления</Text>
                <Table columns={columns} dataSource={[]} />
            </GlassCard> 
        </PageWrapper>
    );

    function fetchBalance() {
        (async () => {
            try {
                setLoading(true);

                const data = await makeRequest("/api/v1/wallets/balance", { method: "GET" });
                setBalance(data)
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }
};

export default Assets;