import { message, Upload } from "antd";
import axios from "axios";
import { InboxOutlined } from "@ant-design/icons";
import Text from "@shared/ui/Text"
import Vbox from "@shared/ui/layouts/Vbox"
import styled from "styled-components";

const Title = styled(Text)`
    text-align: center;
`

const ReceiptsUploader = ({ onSuccess: onSuccessCallback, transaction_id }) => {
    return (
        <Upload.Dragger
            label="Подтверждающие чеки"
            transaction_id={transaction_id}
            customRequest={uploadReceipt}
            showUploadList={false} // Отключение списка загрузок
            onChange={() => {}} // Оставляем пустым, если не требуется дополнительная логика
        >
            <Vbox customJust="center">
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <div><Title weight="bold">Загрузка чеков</Title></div>
                <div><Text>Нажмите или перетащите файл</Text></div>
            </Vbox>
        </Upload.Dragger>
    );

    async function uploadReceipt({ file, onSuccess, onError }) {
        const formData = new FormData();
        formData.append("recept", file);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_HOST}/api/v1/orders/transactions/${transaction_id}/receipts/upload`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    withCredentials: true,
                }
            );
            onSuccess(response.data.receipt_id); // Сообщаем Ant Design об успешной загрузке
            message.success(`${file.name} успешно загружен!`);
            onSuccessCallback(response.data.receipt_id);
        } catch (error) {
            onError(error); // Сообщаем Ant Design об ошибке
            message.error(`Ошибка загрузки ${file.name}.`);
        }
    }
};

export default ReceiptsUploader;
