import { Select as AntdSelect } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import Label from "./Label";
import { memo } from "react";
import ErrorLabel from "@shared/ui/ErrorLabel";
import Empty from "./Empty";

// Глобальные стили для выпадающего списка
const GlobalStyles = createGlobalStyle`
  .ant-select-dropdown {
    background: ${(props) => props.theme.background} !important; /* Фон выпадающего списка */
    color: ${(props) => props.theme.color} !important; /* Цвет текста */
    border: 1px solid ${(props) => props.theme.border} !important; /* Граница выпадающего списка */
  }

  .ant-select-item {
    color: ${(props) => props.theme.color} !important; /* Цвет текста элементов */
    background: ${(props) => props.theme.background} !important; /* Фон элементов */
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background: ${(props) => props.theme.hover} !important; /* Фон при наведении */
    }

    &.ant-select-item-option-selected {
      background-color: ${(props) => props.theme.secondary} !important; /* Фон для выбранного элемента */
      color: ${(props) => props.theme.color} !important; /* Цвет текста выбранного элемента */
    }
  }

  // Текст выбранной опции в заголовке
  .ant-select-selection-item {
    color: ${(props) => props.theme.color} !important; /* Цвет текста выбранных элементов */
    border-radius: 2px; /* Закругление углов */
    padding: 2px 8px; /* Отступы внутри выбранных элементов */
    transition: background-color 0.3s;
  }

  .ant-select-item-option-disabled {
    color: ${(props) => props.theme.color} !important; /* Цвет текста отключенного элемента */
    background-color: ${(props) => props.theme.background} !important; /* Фон отключенного элемента */
  }

  // Цвет крестика (удаления опции)
  .ant-select-selection-item-remove {
    color: ${(props) => props.theme.label} !important; /* Цвет крестика */
    transition: color 0.3s;

    &:hover {
      color: ${(props) => props.theme.color} !important; /* Цвет при наведении */
    }
  }
`;

// Локальные стили для контейнера Select
const StyledSelect = styled(AntdSelect)`
  .ant-select-selector {
    background: ${(props) => props.theme.background} !important; /* Темный фон */
    color: ${(props) => props.theme.color} !important; /* Цвет текста */
    border: 1px solid ${(props) => props.theme.border} !important; /* Цвет границы */
    transition: border-color 0.3s;

    &:hover {
      border-color: ${(props) => props.theme.border} !important; /* Граница при наведении */
    }

    &:focus-within {
      border-color: ${(props) => props.theme.border} !important; /* Граница при фокусе */
      box-shadow: 0 0 0 2px ${(props) => props.theme.border} !important;
    }
  }

  .ant-select-arrow {
    color: ${(props) => props.theme.color} !important; /* Цвет стрелки */
  }

  // Стили для состояния ошибки
  &.ant-select-status-error .ant-select-selector {
    border-color: red !important; /* Красная граница */
    background: ${(props) => props.theme.background} !important; /* Фон */
  }

  &.ant-select-status-error:hover .ant-select-selector {
    border-color: ${(props) => props.theme.secondary} !important; /* Красная граница при наведении */
  }
`;

const Select = memo(({ label, fullWidth = false, showSearch, error, disabled, loading, ...props }) => {
  const Component = () => (
    <>
      <GlobalStyles />
      <ErrorLabel value={error}>
        <Label value={label}>
          <StyledSelect
            style={{ minWidth: "150px", width: "100%" }}
            showSearch={showSearch}
            notFoundContent={<Empty />} // Собственная заглушка
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            disabled={disabled || loading}
            loading={loading}
            status={error ? "error" : undefined}
            {...props}
          />
        </Label>
      </ErrorLabel>
    </>
  );

  if (fullWidth) return <Component />;

  return <div><Component /></div>;
});

export default Select;
