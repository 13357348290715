import Hbox from "@shared/ui/layouts/Hbox";
import Vbox from "@shared/ui/layouts/Vbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import deleteHubApi from "@shared/api/services/hubs/deleteHubApi";
import Text from "@shared/ui/Text";
import { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";


const DeleteRecHubModal = ({closeModal, onSuccess, name, id}) => {
    return <Vbox gap="10px">
        <Text weight="bold">{name}</Text>
        <Text weight="bold">{id}</Text>
        <Hbox customJust="end"><SimpleButton main icon={<DeleteOutlined />} onClick={deleteHub}>Удалить</SimpleButton></Hbox>
    </Vbox>

    async function deleteHub() {
        closeModal();

        try {
            await handleAsyncNotification(async () => {
                await deleteHubApi(id);
                onSuccess();
            }, {
                successText: "Запись удалена",
                errorText: "Ошибка удаления",
                loadingText: "Удаление записи"
            });
        } catch(e) {
            console.log(e);
        }
    }
}

export default DeleteRecHubModal;