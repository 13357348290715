import ElementCard from "@shared/ui/ElementCard";
import makeRequest from "@shared/api/makeRequest";
import Text from "@shared/ui/Text";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { useState } from "react";
import { UnlockOutlined } from "@ant-design/icons";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import Vbox from "@shared/ui/layouts/Vbox";
import Label from "@shared/ui/Label";


const TurnOffAuth = ({setStatus}) => {
    const [loading, setLoading] = useState(false);

    return <ElementCard>
        <Vbox>
            <Label value="Статус">
                <Text>Включено</Text> 
            </Label>

            <SimpleButton loading={loading} onClick={turnOffAuth} icon={<UnlockOutlined />}>Выключить</SimpleButton>
        </Vbox>
        
    </ElementCard>

    async function turnOffAuth() {
        setLoading(true);
        await handleAsyncNotification(async () => {
            await makeRequest("/api/v1/core/2fa/disable");
            setStatus(false);
        }, {
            successText: "2FA аутентификация выключена",
            errorText: "Ошибка выключения 2FA аутентификации",
            loadingText: "Выключение 2FA аутентификации"
        });
        setLoading(false);
    }

}

export default TurnOffAuth;