import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import SimpleInput from "@shared/ui/Simpleinput";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { useEffect, useState } from "react";
import getTradersApi from "@shared/api/services/getTradersApi";
import GlassCard from "@shared/ui/GlassCard";
import useDebounce from "@shared/hooks/useDebounce";
import Text from "@shared/ui/Text";
import ElementCard from "@shared/ui/ElementCard";
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import Table from "@shared/ui/Table";
import useAddNavigate from "@shared/hooks/useAddNavigate";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "@shared/ui/Pagination";
import makeRequest from '@shared/api/makeRequest'
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import { DeleteOutlined, EditOutlined, LoginOutlined } from "@ant-design/icons";

// Страница на которой показываются все Трейдеры, хабы которых можно привязать к ранее выбранному магазину
const CascadeAssosiations = ({shop_id}) => {
    const [searchValue, setSearchValue] = useState("");
    const [cascades, setCascades] = useState([]);
    const [tradersLoading, setTradersLoading] = useState(true);;
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [editingId, setEditingId] = useState(""); // ID каскада, который редактируется
    const [editingPriority, setEditingPriority] = useState(null); // Новый приоритет
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const debouncedSearchValue = useDebounce(searchValue, 1000);
    useEffect(fetchCascades, [debouncedSearchValue]);

    const addNavigate = useAddNavigate();

    const columns = [
        {
            title: "Имя платежной системы",
            dataIndex: "name",
        },
        {
            title: "Приоритет",
            dataIndex: "priority",
            width: "120px",
            render: (priority, record) =>
                record.associated_id === editingId ? (
                    <SimpleInput
                        value={editingPriority}
                        onChange={(e) => setEditingPriority(e.target.value)} // Обновляем через setEditingPriority
                    />
                ) : (
                    <span>{priority ?? "Не установлен"}</span>
                ),
        },
        {
            title: "Действия",
            dataIndex: "action",
            width: "1px",
            render: (_, record) => <Hbox customJust="end" gap="10px">
                {record.associated ? (
                    <Hbox gap="10px">
                        {record.associated_id === editingId ? (
                            <SimpleButton onClick={() => savePriority()}>
                                Сохранить
                            </SimpleButton>
                        ) : (
                            <SimpleButton icon={<EditOutlined />} onClick={() => {
                                setEditingId(record.associated_id);
                                setEditingPriority(record.priority);
                            }} />
                        )}
                        <SimpleButton icon={<DeleteOutlined />} onClick={() => deleteCascadeFromShop(record.associated_id)} />
                    </Hbox>
                ) : (
                    <SimpleButton onClick={() => addCascadeToShop(shop_id, record.id)}>
                        Добавить
                    </SimpleButton>
                )}
                <SimpleButton onClick={() => {addNavigate(`/cascades/${record.id}/hubs`)}} icon={<LoginOutlined />}>Хабы</SimpleButton>
            </Hbox>,
        }
    ];

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/shops")}}>Магазины</a> },
    ]

    // Функция отмены редактирования
    const cancelEditing = () => {
        setEditingId("");
        setEditingPriority(null);
    };

    // Функция сохранения изменений
    const savePriority = async () => {
        try {
            console.log(editingId, editingPriority)
            setLoading(true); // Включаем индикатор загрузки
            updateCascadePriority(editingId, editingPriority)
            fetchCascades(); // Обновляем данные из сервера
            cancelEditing(); // Завершаем редактирование
        } catch (e) {
            console.error("Ошибка обновления приоритета:", e);
        } finally {
            setLoading(false); // Выключаем индикатор загрузки
        }
    };

    return (
        <ElementCard>
            <Text weight="bold">Каскады по приоритету</Text>
            <Vbox gap="10px">
                <Hbox width="200px" gap="10px">
                    <SimpleInput label="Поиск по каскаду" value={searchValue} onChange={(e) => {setSearchValue(e.target.value)}} />
                </Hbox>
                <Table loading={tradersLoading} columns={columns} dataSource={cascades} />
                <Pagination value={currentPage} total={totalPages} onChange={setCurrentPage} />
            </Vbox>
        </ElementCard>
    )
    

    function fetchCascades() {
        (async () => {
            setTradersLoading(true);

            try {
                const data = await makeRequest(`/api/v1/cascade/list?search=${searchValue}&shop_id=${shop_id}`, {
                    method: 'GET'
                });
                const clear_data = data.map(cascade => ({name: cascade.username, id: cascade.id, priority: cascade.priority, associated: cascade.associated, associated_id: cascade.associated_id}))
                setCascades(clear_data);
                setTotalPages(data.total_pages);
            } catch(e) {
                console.log(e);
            } finally {
                setTradersLoading(false);
            }
        })();
    }

    async function addCascadeToShop(shop_id, cascade_id) {
        console.log(cascade_id)
        await handleAsyncNotification (async() => {await makeRequest(`/api/v1/cascade/add`, {
            method: 'POST',
            body: { shop_id, cascade_id},
        })} , {
            errorText: "Ошибка привязки какскада",
            successText: "Привязка успешна",
            loadingText: "Привязка каскада к магазину"
        })
        fetchCascades();
    }
    
    async function deleteCascadeFromShop(association_id) {
        await handleAsyncNotification(async () => {await makeRequest(`/api/v1/cascade/del`, {
            method: 'POST',
            body: ({ association_id }),
        })}, {
            errorText: "Ошибка удаления какскада",
            successText: "Привязка успешна удалена",
            loadingText: "Удаление каскада к магазину"
        })
        fetchCascades();
        setEditingId("")
        setEditingPriority(null)
    }
    
    async function updateCascadePriority(association_id, priority) {
        await handleAsyncNotification(async () => {await makeRequest(`/api/v1/cascade/update`, {
            method: 'POST',
            body: ({ association_id, priority }),
        })}, {
            errorText: "Ошибка обновления приоритета какскада, возможно число некорректно поставлено",
            successText: "Приоритет обновлен",
            loadingText: "Приоритет каскада обновляется"
        }
        )
        fetchCascades();
    }
    
}

export default CascadeAssosiations;