import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useNavigate } from "react-router-dom";
import Table from "@shared/ui/Table";
import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Text from "@shared/ui/Text";


const columns = [
    { title: "Валюта", dataIndex: "currency" },
    { title: "Метод", dataIndex: "method" },
    { title: "Банк", dataIndex: "bank" },
    { title: "Реквизит", dataIndex: "requisite" },
    { title: "ФИО", dataIndex: "fio" },
    { title: "Хаб", dataIndex: "hub" },
    { title: "Трейдер", dataIndex: "trader" },
    { title: "Время активности", dataIndex: "time_active" },
    { title: "Объем за все время", dataIndex: "total_volume" },
    { title: "Емкость", dataIndex: "capacity" },
]

const items = [
    { currency: "RUB", method: "Card to Card", bank: "Sberbank", requisite: "3443 2342 3243 2134", fio: "Руслан Николаевич Попков", hub: "Hub1", trader: "trader1", time_active: "22 часа, 23 минуты", total_volume: "243 324 USDT", capacity: "500 000 RUB" },
    { currency: "USD", method: "Wire Transfer", bank: "Chase", requisite: "1234 5678 9101 1121", fio: "John Doe", hub: "Hub2", trader: "trader2", time_active: "12 часов, 10 минут", total_volume: "123 000 USDT", capacity: "300 000 USD" },
    { currency: "EUR", method: "SEPA", bank: "Deutsche Bank", requisite: "9876 5432 1098 7654", fio: "Hans Müller", hub: "Hub3", trader: "trader3", time_active: "15 часов, 45 минут", total_volume: "150 000 USDT", capacity: "400 000 EUR" },
    { currency: "RUB", method: "Card to Card", bank: "VTB", requisite: "2233 4455 6677 8899", fio: "Анна Сергеевна Смирнова", hub: "Hub4", trader: "trader4", time_active: "8 часов, 30 минут", total_volume: "200 000 USDT", capacity: "600 000 RUB" },
    { currency: "USD", method: "Cash Pickup", bank: "Wells Fargo", requisite: "4455 6677 8899 0011", fio: "Jane Smith", hub: "Hub5", trader: "trader5", time_active: "5 часов, 20 минут", total_volume: "180 000 USDT", capacity: "250 000 USD" },
    { currency: "RUB", method: "Online Transfer", bank: "Alfa-Bank", requisite: "3344 5566 7788 9900", fio: "Иван Алексеевич Петров", hub: "Hub6", trader: "trader6", time_active: "18 часов, 50 минут", total_volume: "220 000 USDT", capacity: "450 000 RUB" },
    { currency: "EUR", method: "SEPA", bank: "BNP Paribas", requisite: "5566 7788 9900 1122", fio: "Marie Dubois", hub: "Hub7", trader: "trader7", time_active: "25 часов, 10 минут", total_volume: "170 000 USDT", capacity: "380 000 EUR" },
    { currency: "USD", method: "Wire Transfer", bank: "Bank of America", requisite: "7788 9900 1122 3344", fio: "Michael Johnson", hub: "Hub8", trader: "trader8", time_active: "30 часов, 5 минут", total_volume: "190 000 USDT", capacity: "310 000 USD" },
    { currency: "RUB", method: "Card to Card", bank: "Tinkoff", requisite: "8899 0011 2233 4455", fio: "Светлана Игоревна Михайлова", hub: "Hub9", trader: "trader9", time_active: "20 часов, 40 минут", total_volume: "240 000 USDT", capacity: "550 000 RUB" },
    { currency: "USD", method: "Cash Pickup", bank: "CitiBank", requisite: "9900 1122 3344 5566", fio: "Chris Evans", hub: "Hub10", trader: "trader10", time_active: "10 часов, 15 минут", total_volume: "210 000 USDT", capacity: "270 000 USD" }
];



const HubRequisites = () => {
    const navigate = useNavigate();

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/statistics?section=requisites")}}>Статистика</a> }
    ]

    return <PageWrapper breadcrumbs={breadcrumbs} title="Статистика по реквизитам хаба testHub1">
        <GlassCard>
            <Text>Реквизиты</Text>
            <Table 
                columns={columns}
                dataSource={items}
            />
        </GlassCard>
    </PageWrapper>
}

export default HubRequisites;