import { useEffect, useState } from 'react';
import getAvailableReasonsForTransactionApi from '@shared/api/services/getAvailableReasonsForTransactionApi';
import VBox from "@shared/ui/layouts/Vbox";
import openTransactionDispute from '@shared/api/services/openTransactionDispute';
import handleAsyncNotification from '@shared/helpers/handleAsyncNotification';
import Select from "@shared/ui/Select";
import Loading from '@shared/ui/Loading';
import SimpleButton from '@shared/ui/buttons/SimpleBtn';
import SimpleInput from "@shared/ui/Simpleinput";
import HBox from "@shared/ui/layouts/Hbox";
import ReceiptsUploader from './elements/ReceiptsUploader';


function OpenDisputeModal({onSuccess, transaction, closeModal}) {
    const [reasons, setReasons] = useState([]);
    const [selectedReasonId, setSelectedReasonId] = useState();
    const [loading, setLoading] = useState(false); // Загрузка ризонов
    const [amount, setAmount] = useState(0);
    const [fileList, setFileList] = useState([]);

    useEffect(fetchReasons, []);

    if(loading) return <HBox customJust="center">
        <Loading />
    </HBox>

    return <VBox gap='10px'>
        <h4>Выберите причину открытия диспута</h4>
        <Select
            label="Причина"
            value={selectedReasonId}
            onChange={(value) => {setSelectedReasonId(value)}}
            options={reasons}
        />
        {(selectedReasonId === 16 || selectedReasonId === 13) && <SimpleInput number placeholder="100 000" label="Сумма" value={amount} onChange={(e) => {setAmount(e.target.value)}}  />}
        <ReceiptsUploader transaction_id={transaction.id} setFileList={setFileList} fileList={fileList} />
        <HBox customJust="end">
            <SimpleButton onClick={openDispute}>Открыть диспут</SimpleButton>
        </HBox>
    </VBox>

    function fetchReasons() {
        (async () => {
            try {
                setLoading(true);
    
                const reasons = await getAvailableReasonsForTransactionApi(transaction.id);
    
                setReasons(reasons.map(({id, label}) => ({label, value: id})));
                setSelectedReasonId(reasons[0].value);
            } catch(e) {
                console.log(e)
            } finally {
                setLoading(false)
            }
        })();
    }

    async function openDispute() {
        if(!selectedReasonId) return;

        await handleAsyncNotification(async () => {
            await openTransactionDispute({transaction_id: transaction.id, reason_id: selectedReasonId, amount, receipts: fileList.map(el => el.response.receipt_id)});
            onSuccess();
        }, {
            loadingText: "Открытие диспута",
            successText: 'Диспут по операции успешно открыт!',
            errorText: 'Ошибка открытия диспута'
        });

        closeModal();
    }
}

export default OpenDisputeModal;