import getS2SInternalTransfersHistoryApi from "@shared/api/services/merchant/internalTransfers/getS2SInternalTransfersHistoryApi";
import { useEffect, useState } from "react";
import Table from "@shared/ui/Table";
import CopyableText from "@shared/ui/CopyableText";
import { Tag } from "antd";
import formatReadableDate from "@shared/helpers/formateReadableDate";
import Text from "@shared/ui/Text";
import GlassCard from "@shared/ui/GlassCard";
import Vbox from "@shared/ui/layouts/Vbox";


const columns = [
    { title: "ID", dataIndex: "id", width: "1px", render: (value) => <CopyableText text={value} /> },
    { title: "Сумма", dataIndex: "amount", render: (value) => <Tag color={value > 0 ? "success" : "error"}>{value}</Tag> },
    { title: "Магазин", dataIndex: "shop_name" },
    { title: "Дата", dataIndex: "created_at"     }
]


const TransfersHistory = () => {
    const [transfers, setTransfers] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(fetchTransfers, []);

    return <GlassCard>
        <Vbox gap="10px">
            <Text weight="bold">История переводов</Text>
            <Table 
                loading={loading}
                columns={columns}
                dataSource={transfers}
            />
        </Vbox>
    </GlassCard>

    function fetchTransfers() {
        (async () => {
            try {
                setLoading(true);
                const transfers = await getS2SInternalTransfersHistoryApi();
                
                setTransfers(transfers);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }
}

export default TransfersHistory;