import makeRequest from "@shared/api/makeRequest";


export default async function({shop_id, hub_id, payin, payout}) {
    return await makeRequest(`/api/v1/orders/hubs/association/set`, {
        body: {
            shop_id,
            hub_id,
            payin,
            payout
        }
    });
}