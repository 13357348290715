import Vbox from "@shared/ui/layouts/Vbox";
import TableSkeleton from "@shared/ui/skeletons/TableSkeleton";
import Hbox from "@shared/ui/layouts/Hbox";
import DropDownSkeleton from "@shared/ui/skeletons/DropDownSkeleton";


const PagePreloader = () => {
    return <Vbox gap="10px">
        <Hbox customJust="end"><DropDownSkeleton /></Hbox>
        <TableSkeleton columnsAmount={5}/>
    </Vbox>
}

export default PagePreloader;