import Table from "@shared/ui/Table";
import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import Text from "@shared/ui/Text";
import Select from "@shared/ui/Select";
import GlassCard from "@shared/ui/GlassCard"
import ElementCard from "@shared/ui/ElementCard"
import Button from "@shared/ui/buttons/SimpleBtn";
import { useNavigate } from "react-router-dom";



const traders = [
    { name: "testtrader1", status: "Не активен", day_income: "12 USDT", week_income: "123 USDT", month_income: "213 USDT" },
    { name: "testtrader2", status: "Активен", day_income: "15 USDT", week_income: "130 USDT", month_income: "220 USDT" },
    { name: "testtrader3", status: "Не активен", day_income: "10 USDT", week_income: "100 USDT", month_income: "200 USDT" },
    { name: "testtrader4", status: "Активен", day_income: "20 USDT", week_income: "140 USDT", month_income: "250 USDT" },
    { name: "testtrader5", status: "Не активен", day_income: "18 USDT", week_income: "120 USDT", month_income: "230 USDT" },
    { name: "testtrader6", status: "Активен", day_income: "25 USDT", week_income: "160 USDT", month_income: "270 USDT" },
    { name: "testtrader7", status: "Не активен", day_income: "22 USDT", week_income: "150 USDT", month_income: "260 USDT" },
    { name: "testtrader8", status: "Активен", day_income: "30 USDT", week_income: "170 USDT", month_income: "280 USDT" },
    { name: "testtrader9", status: "Не активен", day_income: "35 USDT", week_income: "180 USDT", month_income: "290 USDT" },
    { name: "testtrader10", status: "Активен", day_income: "40 USDT", week_income: "200 USDT", month_income: "300 USDT" },
    { name: "testtrader11", status: "Не активен", day_income: "42 USDT", week_income: "210 USDT", month_income: "310 USDT" },
    { name: "testtrader12", status: "Активен", day_income: "45 USDT", week_income: "220 USDT", month_income: "320 USDT" },
    { name: "testtrader13", status: "Не активен", day_income: "50 USDT", week_income: "230 USDT", month_income: "330 USDT" },
    { name: "testtrader14", status: "Активен", day_income: "55 USDT", week_income: "240 USDT", month_income: "340 USDT" },
    { name: "testtrader15", status: "Не активен", day_income: "60 USDT", week_income: "250 USDT", month_income: "350 USDT" },
    { name: "testtrader16", status: "Активен", day_income: "65 USDT", week_income: "260 USDT", month_income: "360 USDT" },
    { name: "testtrader17", status: "Не активен", day_income: "70 USDT", week_income: "270 USDT", month_income: "370 USDT" },
    { name: "testtrader18", status: "Активен", day_income: "75 USDT", week_income: "280 USDT", month_income: "380 USDT" },
    { name: "testtrader19", status: "Не активен", day_income: "80 USDT", week_income: "290 USDT", month_income: "390 USDT" },
    { name: "testtrader20", status: "Активен", day_income: "85 USDT", week_income: "300 USDT", month_income: "400 USDT" }
];

const Agents = () => {
    const navigate = useNavigate();

    const columns = [
        { title: "Имя пользователя", dataIndex: "name" },
        { title: "Доход за день", dataIndex: "day_income" },
        { title: "Доход за неделю", dataIndex: "week_income" },
        { title: "Доход за месяц", dataIndex: "month_income" },
        { title: "Оборот за день", dataIndex: "day_income" },
        { title: "Оборот за неделю", dataIndex: "week_income" },
        { title: "Оборот за месяц", dataIndex: "month_income" },
        // При переходе на страницу агента, должны показываться трейдеры. В таблице - Трейдер, статус, кол активных рек
        { title: "", dataIndex: "_", render: () => <Button onClick={() => navigate("/agents/klnklnkjlnkjln")}>Перейти</Button>, width: "1px"}
    ]

    return <Vbox>
        <Text size="subheader">Всего приглашено - 10</Text>
        <Hbox customJust="start" gap="10px">
            <GlassCard width="fit-content">
                <Text weight="bold">Трафик</Text>
                <Select label="Фильтр по дате" />
                <Select label="Фильтр по агенту" />
                <Select label="Фильтр по трейдеру агента" />
                <Select label="Фильтр по валюте" />
                <Text>Объем - 20000 USDT</Text>
                <Text>Кешбек приглашенного агента - 20000 USDT</Text>
                <Text>Кешбек агента (мой) - 20000 USDT</Text>
            </GlassCard>
            <GlassCard width="fit-content">
                <Text weight="bold">Реквизиты</Text>
                <Select label="Фильтр по агенту" />
                <Select label="Фильтр по валюте" />
                <Text>Количество активных реквизитов - 233</Text>
            </GlassCard>
        </Hbox>
        
        <Hbox>
            <Select label="Валюта" />
        </Hbox>
        
        <GlassCard>
            <Text>Агенты</Text>
            <Table 
                dataSource={traders}
                columns={columns}
            />
        </GlassCard>
    </Vbox>
}

export default Agents;