import { Empty as AntdEmpty } from "antd";
import styled from "styled-components";


const Container = styled.div`
    display: flex;
    justify-content: center;

    .ant-empty-description {
        color: ${(props) => props.theme.color} !important; /* Устанавливаем цвет текста */
    }
`

const Empty = () => {
    return <Container>
        <AntdEmpty description="Ничего не найдено" />
    </Container>
}

export default Empty;