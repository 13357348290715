import PageWrapper from "@shared/ui/layouts/PageWrapper"
import Hbox from "@shared/ui/layouts/Hbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { useEffect, useMemo, useState } from "react";
import Text from "@shared/ui/Text";
import getUsersByRole from "@shared/api/services/getUsersByRole";
import Table from "@shared/ui/Table";
import { EditOutlined } from "@ant-design/icons";
import GlassCard from "@shared/ui/GlassCard";
import { useNavigate } from "react-router-dom";
import PagePreloader from "./elements/PagePreloader";
import Pagination from "@shared/ui/Pagination";


const Users = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();

    useEffect(fetchUsers, []);
    const columns = useMemo(getColumns, [navigate]);

    return <PageWrapper ContentPreloader={PagePreloader} loading={loading} title="Привязка агентов">
        <GlassCard>
            <Text weight="bold">Список агентов</Text>
            <Table loading={loading} columns={columns} dataSource={users} />
            <Hbox customJust="center"><Pagination value={currentPage} total={totalPages} onChange={setCurrentPage} /></Hbox>
        </GlassCard>
    </PageWrapper>

    function fetchUsers() {
        (async () => {
            setLoading(true);

            try {
                const {data, total_pages} = await getUsersByRole("agent");
                
                setUsers(data);
                setTotalPages(total_pages);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }

    function getColumns() {
        return [
            {
                title: "ID пользователя",
                dataIndex: "id",
                key: "id",
            },
            {
                title: "Имя пользователя",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "",
                dataIndex: "action",
                key: "action",
                render: (_, {id}) => <SimpleButton onClick={() => {navigate('/agents/' + id)}} icon={<EditOutlined />} />,
                width: "20px"
            },
        ]
    }
}

export default Users;