// src/components/sections/Section1.js
import React, { useState, useEffect } from 'react';
import PageWrapper from '@shared/ui/layouts/PageWrapper';
import GlassCard from "@shared/ui/GlassCard";
import ContentSwitcher from "@shared/ui/ContentSwitcher";
import Traders from './elements/Traders';
import Shops from './elements/Shops';
import Withdrawals from './elements/Withdrawals';
import { useSearchParams } from 'react-router-dom';
import Platform from './elements/Platform';


const items = [
    {
        key: "platform",
        label: "Платформа",
        children: <Platform />
    },
    {
        key: "withdrawals",
        label: "Заявки на вывод",
        children: <GlassCard>
            <Withdrawals />
        </GlassCard>
    },
    {
        key: "traders",
        label: "Трейдеры",
        children: <GlassCard>
            <Traders />
        </GlassCard>
    },
    {
        key: "shops",
        label: "Магазины",
        children: <GlassCard>
            <Shops />
        </GlassCard>
    }
]


const Assets = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const section = searchParams.get('section');

    return (
        <PageWrapper title="Активы">
            <ContentSwitcher
                activeKey={section || items[0].key}
                onChange={(value) => {setSearchParams({section: value})}}
                items={items}
            />
        </PageWrapper>
    );
};

export default Assets;