import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Info from "./Info";
import Text from "@shared/ui/Text";
import Table from "@shared/ui/Table";
import {TwoColumns} from "@shared/ui/containers/ListContainers";
import Vbox from "@shared/ui/layouts/Vbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { useNavigate } from "react-router-dom";
import Select from "@shared/ui/Select"
import { useSearchParams } from "react-router-dom";
import Hbox from "@shared/ui/layouts/Hbox";


const columns = [
    { title: "Способ оплаты", dataIndex: "payment_method" },
    { title: "Количество", dataIndex: "amount" },
    { title: "Емкость (осталось)", dataIndex: "capacity" },
    { title: "Ожидаемый объем", dataIndex: "expected_capacity" },
    { title: "Реальный объем (за сегодня)", dataIndex: "real_capacity" },
]

const trading_methods1 = [
    { payment_method: "СБП", amount: "29 шт.", capacity: "200 000 USDT", expected_capacity: "100 000 USDT", real_capacity: "50 000 USDT" }
]

const trading_methods2 = [
    { payment_method: "СБП", amount: "29 шт.", capacity: "200 000 USDT", expected_capacity: "100 000 USDT", real_capacity: "50 000 USDT" },
    { payment_method: "Card to Card", amount: "10 шт.", capacity: "100 000 USDT", expected_capacity: "100 000 USDT", real_capacity: "50 000 USDT" }
]



const hubs = [
    { name: "Хаб 1", trader: "trader1", status: "Активен", min_req_amount: "25 шт.", payment_type: "payin" },
    { name: "Хаб 2", trader: "trader2", status: "Не активен", min_req_amount: "30 шт.", payment_type: "payout" },
    { name: "Хаб 3", trader: "trader3", status: "Активен", min_req_amount: "20 шт.", payment_type: "payin" },
    { name: "Хаб 4", trader: "trader4", status: "Не активен", min_req_amount: "35 шт.", payment_type: "payout" },
    { name: "Хаб 5", trader: "trader5", status: "Активен", min_req_amount: "40 шт.", payment_type: "payin" },
    { name: "Хаб 6", trader: "trader6", status: "Не активен", min_req_amount: "50 шт.", payment_type: "payout" },
    { name: "Хаб 7", trader: "trader7", status: "Активен", min_req_amount: "60 шт.", payment_type: "payin" },
    { name: "Хаб 8", trader: "trader8", status: "Не активен", min_req_amount: "70 шт.", payment_type: "payout" },
    { name: "Хаб 9", trader: "trader9", status: "Активен", min_req_amount: "80 шт.", payment_type: "payin" },
    { name: "Хаб 10", trader: "trader10", status: "Не активен", min_req_amount: "90 шт.", payment_type: "payout" }
];

const requisites_columns = [
    { title: "Валюта", dataIndex: "currency" },
    { title: "Метод", dataIndex: "method" },
    { title: "Банк", dataIndex: "bank" },
    { title: "Реквизит", dataIndex: "requisite" },
    { title: "ФИО", dataIndex: "fio" },
    { title: "Хаб", dataIndex: "hub" },
    { title: "Трейдер", dataIndex: "trader" },
    { title: "Время активности", dataIndex: "time_active" },
    { title: "Объем за все время", dataIndex: "total_volume" },
    { title: "Емкость", dataIndex: "capacity" },
]

const requisites_items = [
    { currency: "RUB", method: "Card to Card", bank: "Sberbank", requisite: "3443 2342 3243 2134", fio: "Руслан Николаевич Попков", hub: "Hub1", trader: "trader1", time_active: "22 часа, 23 минуты", total_volume: "243 324 USDT", capacity: "500 000 RUB" },
    { currency: "USD", method: "Wire Transfer", bank: "Chase", requisite: "1234 5678 9101 1121", fio: "John Doe", hub: "Hub2", trader: "trader2", time_active: "12 часов, 10 минут", total_volume: "123 000 USDT", capacity: "300 000 USD" },
    { currency: "EUR", method: "SEPA", bank: "Deutsche Bank", requisite: "9876 5432 1098 7654", fio: "Hans Müller", hub: "Hub3", trader: "trader3", time_active: "15 часов, 45 минут", total_volume: "150 000 USDT", capacity: "400 000 EUR" },
    { currency: "RUB", method: "Card to Card", bank: "VTB", requisite: "2233 4455 6677 8899", fio: "Анна Сергеевна Смирнова", hub: "Hub4", trader: "trader4", time_active: "8 часов, 30 минут", total_volume: "200 000 USDT", capacity: "600 000 RUB" },
    { currency: "USD", method: "Cash Pickup", bank: "Wells Fargo", requisite: "4455 6677 8899 0011", fio: "Jane Smith", hub: "Hub5", trader: "trader5", time_active: "5 часов, 20 минут", total_volume: "180 000 USDT", capacity: "250 000 USD" },
    { currency: "RUB", method: "Online Transfer", bank: "Alfa-Bank", requisite: "3344 5566 7788 9900", fio: "Иван Алексеевич Петров", hub: "Hub6", trader: "trader6", time_active: "18 часов, 50 минут", total_volume: "220 000 USDT", capacity: "450 000 RUB" },
    { currency: "EUR", method: "SEPA", bank: "BNP Paribas", requisite: "5566 7788 9900 1122", fio: "Marie Dubois", hub: "Hub7", trader: "trader7", time_active: "25 часов, 10 минут", total_volume: "170 000 USDT", capacity: "380 000 EUR" },
    { currency: "USD", method: "Wire Transfer", bank: "Bank of America", requisite: "7788 9900 1122 3344", fio: "Michael Johnson", hub: "Hub8", trader: "trader8", time_active: "30 часов, 5 минут", total_volume: "190 000 USDT", capacity: "310 000 USD" },
    { currency: "RUB", method: "Card to Card", bank: "Tinkoff", requisite: "8899 0011 2233 4455", fio: "Светлана Игоревна Михайлова", hub: "Hub9", trader: "trader9", time_active: "20 часов, 40 минут", total_volume: "240 000 USDT", capacity: "550 000 RUB" },
    { currency: "USD", method: "Cash Pickup", bank: "CitiBank", requisite: "9900 1122 3344 5566", fio: "Chris Evans", hub: "Hub10", trader: "trader10", time_active: "10 часов, 15 минут", total_volume: "210 000 USDT", capacity: "270 000 USD" }
];

const Requisites = () => {
    const navigate = useNavigate();

    const hubs_columns = [
        { title: "Название хаба", dataIndex: "name" },
        { title: "Трейдер", dataIndex: "trader" },
        { title: "Активен / не активен", dataIndex: "status" },
        { title: "Минимальное кол-во рек", dataIndex: "min_req_amount" },
        { title: "Фактическое кол-во рек", dataIndex: "min_req_amount" },
        { title: "Валюта", dataIndex: "currency", render: () => "RUB" },
        { title: "Емкость", dataIndex: "min_req_amount"  },
        { title: "Метод", dataIndex: "_", render: () => "СБП" },
        { title: "Payin / payout", dataIndex: "payment_type" },
        { title: "Объем за сегодня", dataIndex: "today_volume", render: () => "24 000 USDT" },
        { title: "", dataIndex: "_", width: "1px", render: () => <SimpleButton onClick={() => {navigate("/hubs/dwkladnakjwndkjawnd/requisites")}}>Перейти к реквизитам</SimpleButton> }
    ];

    return <Vbox gap="10px">
        <GlassCard>
            <Text weight="bold">Активные реквизиты по каждой валюте (payin)</Text>
            <TwoColumns>
                <ElementCard>
                    <Info 
                        title={<Text weight="bold">Рубль</Text>}
                        value="8 шт. (Общая емкость - 700 000 руб., Общий ожидаемый объем - 600 000 руб.)"
                    />
                    <Table
                        dataSource={trading_methods1}
                        columns={columns}
                    />
                </ElementCard>
                <ElementCard>
                    <Info 
                        title={<Text weight="bold">Доллар</Text>}
                        value="8 шт. (Общая емкость - 700 000 руб., Общий ожидаемый объем - 600 000 руб.)"
                    />
                    <Table
                        dataSource={trading_methods2}
                        columns={columns}
                    />
                </ElementCard>
                <ElementCard>
                    <Info
                        title={<Text weight="bold">Тенге</Text>}
                        value="8 шт. (емкость - 200 000 руб.)"
                    />
                    <Table 
                        dataSource={trading_methods1}
                        columns={columns}
                    />
                </ElementCard>
            </TwoColumns>
        </GlassCard>
        <GlassCard>
            <Text weight="bold">Активные объявления по каждой валюте (payout)</Text>
            <TwoColumns>
                <ElementCard>
                    <Info 
                        title={<Text weight="bold">Рубль</Text>}
                        value="8 шт. (Общая емкость - 700 000 руб., Общий ожидаемый объем - 600 000 руб.)"
                    />
                    <Table
                        dataSource={trading_methods1}
                        columns={columns}                   
                    />
                </ElementCard>
                <ElementCard>
                    <Info 
                        title={<Text weight="bold">Доллар</Text>}
                        value="8 шт. (Общая емкость - 700 000 руб., Общий ожидаемый объем - 600 000 руб.)"
                    />
                    <Table 
                        dataSource={trading_methods2}
                        columns={columns}
                    />
                </ElementCard>
                <ElementCard>
                    <Info 
                        title={<Text weight="bold">Тенге</Text>}
                        value="8 шт. (емкость - 200 000 руб.)"
                    />
                    <Table 
                        dataSource={trading_methods1}
                        columns={columns}
                    />
                </ElementCard>
            </TwoColumns>
        </GlassCard>
        <GlassCard>
            <Text weight="bold">Хабы</Text>
            <Hbox customJust="start" gap="10px">
                <Select 
                    items={[]}
                    label="Валюта"
                />
                <Select 
                    items={[]}
                    label="Метод"
                />
                <Select 
                    items={[]}
                    label="Активный / неактивный"
                />
                <Select 
                    items={[]}
                    label="Трейдер"
                />
            </Hbox>
            <Text size="subheader">Всего 443 шт.</Text>
            <Table 
                columns={hubs_columns}
                dataSource={hubs}
            />
        </GlassCard>
        <GlassCard>
            <Text weight="bold">Реквизиты</Text>
            <Hbox customJust="start" gap="10px">
                <Select 
                    items={[]}
                    label="Валюта"
                />
                <Select 
                    items={[]}
                    label="Метод"
                />
                <Select 
                    items={[]}
                    label="Активный / неактивный"
                />
                <Select
                    items={[]}
                    label="Трейдер"
                />
                <Select 
                    items={[]}
                    label="Банк"
                />
            </Hbox>
            <Text size="subheader">Всего 443 шт.</Text>
            <Text size="subheader">Среднее время жизни реквизита - 5 дня 23 часа 43 минуты</Text>
            <Table
                columns={requisites_columns}
                dataSource={requisites_items}
            />
        </GlassCard>
    </Vbox>
}

export default Requisites;