import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import CopyableText from "@shared/ui/CopyableText";
import formateReadableDate from '@shared/helpers/formateReadableDate'
import { render } from "@testing-library/react";


export default function(statuses) {
    return [
        {
            title: "ID",
            dataIndex: "id",
            width: "1px",
            render: (value) => <CopyableText text={value} />
        },
        {
            title: "Сумма",
            dataIndex: "amount",
            render: (value) => value - 2
        },
        {
            title: 'Hash', 
            dataIndex: 'hash', 
            render: (value) => <a href={`https://tronscan.org/#/transaction/${value}`}>Tronscan</a>},
        {
            title: "Адрес",
            dataIndex: "address",
        },
        {
            title: "Статус",
            dataIndex: "status",
            render: (value) => {
                const foundStatus = statuses.find(status => status.value === value);
                return foundStatus ? foundStatus.label : value; // Возвращаем label, если найден, иначе возвращаем исходное value
            }
        },
        {
            title: "Дата",
            dataIndex: "created_at",
            render: (value) => formateReadableDate(value)
        },
    ]
}