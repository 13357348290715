// src/components/sections/Section1.js
import React, {useEffect, useState} from 'react';
import PageWrapper from "@shared/ui/layouts/PageWrapper";   
import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Text from "@shared/ui/Text";
import makeRequest from '@shared/api/makeRequest'
import TodoWrapper from "@shared/ui/TodoWrapper";
import Select from "@shared/ui/Select";


const Statistics = () => {
    const [activeChapter, setActiveChapter] = useState('Finance');
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const handleClick = (btnName) => {
        if (activeChapter !== btnName) {
            setActiveChapter(btnName);
        }
    };
    
    useEffect(fetchStatistic, [])
    const getValue = (key) => {
        return data && data[key] !== undefined ? data[key] : 'Загрузка...'; // Загрузка или значение по умолчанию
    };

    return (
        <PageWrapper loading={loading} title="Статистика">
            <VBox gap='30px'>
                <TodoWrapper value="Сделать фильтры на беке">
                    <HBox customJust="start" gap="10px">
                        <Select label="Фильтр по дате" options={[]} />
                        <Select label="Фильтр по валюте" options={[]} />
                        <Select label="Фильтр по хабам" options={[]} />
                        <Select label="Фильтр по реквизитам" options={[]} />
                    </HBox>
                </TodoWrapper>
                <GlassCard>
                    <Text weight="bold">Основные показатели</Text>
                    <ElementCard>
                        <HBox wrap gap='10px' width='100%'>
                            <VBox>
                                <Text size="subheader">Принятый объём</Text>
                                <Text>{data.total_received} р</Text>
                            </VBox>
                            <VBox>
                                <Text size="subheader">Отправленный объём</Text>
                                <Text>{data.total_sent} р</Text>
                            </VBox>
                            <VBox>
                                <Text size="subheader">Количество сделок</Text>
                                <Text>{data.total_transactions}</Text>
                            </VBox>
                            <VBox>
                                <Text size="subheader">Успешные сделки</Text>
                                <Text>{data.successful_transactions}</Text>
                            </VBox>
                            <VBox>
                                <Text size="subheader">Конверсия</Text>
                                <Text>{data.conversion_rate}%</Text>
                            </VBox>
                            <VBox>
                                <Text size="subheader">Средний чек</Text>
                                <Text>{data.average_check} р</Text>
                            </VBox>
                            <TodoWrapper value="Добавить поле на беке">
                                <VBox>
                                    <Text size="subheader">Кешбек</Text>
                                    <Text>21343 р</Text>
                                </VBox>
                            </TodoWrapper>
                        </HBox>
                    </ElementCard>
                </GlassCard>
            </VBox>
        </PageWrapper>
    );

    function fetchStatistic() {
        (async () => {
            try {
                setLoading(true);

                const response = await makeRequest('/api/v1/core/statistics/', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setData(response); // Обновляем состояние с полученными данными
            } catch (error) {
                console.error('Error fetching statistics:', error);
            } finally {
                setLoading(false);
            }
        })();
        
    };
};

export default Statistics;
