export const inputs = [
    {
        label: "Пароль",
        value: "password",
        placeholder: "qwerty123",
        passwordType: true
    },
    {
        label: "Подтверждение пароля",
        value: "password2",
        placeholder: "qwerty123",
        passwordType: true
    }
]