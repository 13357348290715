import PageWrapper from "@shared/ui/layouts/PageWrapper";
import SimpleInput from "@shared/ui/Simpleinput";
import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import { useEffect, useState } from "react";
import { SwapOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Text from "@shared/ui/Text";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Select from "@shared/ui/Select";
import getShopsApi from "@shared/api/services/merchant/getShopsApi";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import TransferCreationForm from "./elements/TransferCreationForm";
import TransfersHistory from "./elements/TransfersHistory";
import { useNavigate } from "react-router-dom";


const GlassCard = styled.div`
  background: rgba(255, 255, 255, 0.1); /* Немного прозрачный белый цвет */
  backdrop-filter: blur(10px); /* Эффект размытости, создающий стеклянный вид */
  border-radius: 15px; /* Закругленные углы */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Тень для глубины */
  padding: 10px; /* Внутренние отступы для контента */
  border: 1px solid var(--border); /* Полупрозрачная рамка */
  padding: 20px;
  width: 500px;
`;

const InternalTransfers = () => {
    const [shops, setShops] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(fetchAllShops, []);

    const navigate = useNavigate();

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/assets")}}>Активы</a> },
    ]

    return <PageWrapper breadcrumbs={breadcrumbs} loading={loading} title="Внутренние переводы">
        <Vbox gap="30px">
            <TransferCreationForm shops={shops} loading={loading} />
            <TransfersHistory />
        </Vbox>
    </PageWrapper>

    function fetchAllShops() { // Начинаем с 0 для синхронизации с react-paginate
        (async () => {
            setLoading(true);
            try {
                const shops = await getShopsApi();
                
                setShops(shops);
            } catch (error) {
                console.error('Ошибка при получении магазинов:', error);
                setShops([]);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            }
        })();
    };
};

export default InternalTransfers;