import styled from "styled-components";
import ErrorLabel from "@shared/ui/ErrorLabel"


const Container = styled.div`
    border: solid 1px red;
    padding: 5px;
    width: fit-content;
`


const TodoWrapper = ({children, value = "TODO"}) => {
    return <div>
        {/* <ErrorLabel value={value}>
            <Container> */}
                {children}
            {/* </Container>
        </ErrorLabel> */}
    </div>
}

export default TodoWrapper;