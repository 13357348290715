import React from "react";
import Assets from "@analytic/Assets";
import Statistics from "@analytic/Statistics";
import HubRequisites from "@analytic/HubRequisites";
import RequisiteErrors from "@analytic/RequisiteErrors";


const analyticRoutes = [
    { path: "/assets", element: <Assets /> },
    { path: "/statistics", element: <Statistics /> },
    { path: "/hubs/:hub_id/requisites", element: <HubRequisites /> },
    { path: "/requisite/:requisite_id/errors", element: <RequisiteErrors /> }
]

export default analyticRoutes;

// import { lazy } from "react";


// const Assets = lazy(() => import("@agent/Assets"));
// const Traders = lazy(() => import("@agent/Traders"));
// const Invite = lazy(() => import("@agent/Invite"));
// const Withdraw = lazy(() => import("@agent/Withdraw"));

// const agentRoutes = [
//     { path: "/assets", element: <Assets /> },
//     { path: "/traders", element: <Traders /> },
//     { path: "/invite", element: <Invite /> }
// ]

// export default agentRoutes;