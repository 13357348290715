import makeRequest from "@shared/api/makeRequest";


export default async function({user_id, entity_id, type, payin, payout}) {  
    return await makeRequest(`/api/v1/core/users/${user_id}/association/set`, { 
        body: {
            entity_id,
            type,
            payin,
            payout
        }
    });
}