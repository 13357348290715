import { StarOutlined } from "@ant-design/icons";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import useSearchParams from "@shared/hooks/useSearchParams";
import { useEffect, useState } from "react";
import getAttentionPayinTransactions from '@shared/api/services/getAttentionPayinTransactions';
import { Badge } from "antd";


// Кнопка, включающая только важные ордера
const AttentionButton = () => {
    const {searchParams, setSearchParams} = useSearchParams();
    const [payinTotal, setPayinTotal] = useState(0);
    const section = searchParams.get("section");

    useEffect(initLoopTransactionsFetching, []);
    useEffect(fetchTransactionsAmount, []);

    return <Badge count={payinTotal}>
        <SimpleButton main={section === "attention"} onClick={toggleAttentionSection} icon={<StarOutlined />}>Срочные ордера</SimpleButton>
    </Badge>

    function fetchTransactionsAmount() {
        (async () => {            
            try {
                const {total: payinTotal} = await getAttentionPayinTransactions({skip: 0, limit: 1});

                setPayinTotal(payinTotal);
            } catch (err) {
                console.log(err);
            }      
        })();
    }

    function initLoopTransactionsFetching() {
        const interval = setInterval(fetchTransactionsAmount, 5000);

        return () => {
            clearInterval(interval);
        }
    }

    function toggleAttentionSection() {
        setSearchParams({section: section === "attention" ? "all" : "attention"});
    }
}

export default AttentionButton;