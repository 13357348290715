import React from "react";
import styled from "styled-components";

// Стили для контейнера `Breadcrumb`
const BreadcrumbContainer = styled.nav`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${props => props.theme.color}; /* Белый цвет текста */
`;

// Стили для разделителя
const Separator = styled.span`
  margin: 0 8px;
  color: ${props => props.theme.color}; /* Цвет разделителя */
`;

// Стили для элемента с эффектом при наведении
const Element = styled.div`
  cursor: pointer;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 2px;

  &:hover {
    color: ${props => props.theme.color}; /* Изменение цвета при наведении */
    background: ${(props) => props.theme.hover};
  }
`;

// Собственный `Breadcrumb`
const Breadcrumb = ({ items = [], separator = "/" }) => {
  return (
    <BreadcrumbContainer>
      {items.map((item, index) => {
        const isLast = index === items.length - 1;
        return (
          <Element key={index}>
            {item.title} {/* Рендерим переданный элемент */}
            {!isLast && <Separator>{separator}</Separator>}
          </Element>
        );
      })}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
