import { useEffect, useState } from "react";
import GlassCard from "@shared/ui/GlassCard";
import Text from "@shared/ui/Text";
import makeRequest from "@shared/api/makeRequest";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";


const TelegramLogin = ({ bot }) => {
    const [status, setStatus] = useState(false);
    useEffect(fetchStatus, []);

    const TelegramWidget = () => {
        useEffect(() => {
            if (document.getElementById("telegram-widget")) {
                document.getElementById("telegram-widget").innerHTML = "";
            }
            delete window.handleTelegramAuth;

            window.handleTelegramAuth = ({ id }) => setId(id);

            const script = document.createElement("script");
            script.src = "https://telegram.org/js/telegram-widget.js?22";
            script.async = true;
            script.setAttribute("data-telegram-login", bot);
            script.setAttribute("data-size", "large");
            script.setAttribute("data-onauth", "handleTelegramAuth(user)");
            script.setAttribute("data-request-access", "write");

            document.getElementById("telegram-widget").appendChild(script);

            return () => {
                if (document.getElementById("telegram-widget")) {
                    document.getElementById("telegram-widget").innerHTML = "";
                }
                delete window.handleTelegramAuth;
            };
        }, [bot]);

        return <div id="telegram-widget"></div>;
    };

    return (
        <GlassCard>
            <Text weight="bold">Привязка телеграмм</Text>
            <Text>Статус - {status ? "Телеграмм привязан" : "Телеграмм не привязан"}</Text>
            {status && <SimpleButton onClick={unlinkAccount} main>Отвязать</SimpleButton>}
            {!status && <TelegramWidget />}
        </GlassCard>
    );

    function setId(telegram_id) {
        (async () => {
            try {
                await makeRequest(`/api/v1/core/users/telegram/set`, {
                    body: { telegram_id: telegram_id.toString() },
                });
                fetchStatus();
            } catch (e) {
                console.log(e);
            }
        })();
    }

    function fetchStatus() {
        (async () => {
            try {
                const { telegram } = await makeRequest("/api/v1/core/users/telegram/check", { method: "GET" });
                setStatus(telegram);
            } catch (e) {
                console.log(e);
            }
        })();
    }

    function unlinkAccount() {
        (async () => {
            try {
                await makeRequest("/api/v1/core/users/telegram/remove", { method: "GET" });
                setStatus(false);
            } catch (e) {
                console.log(e);
            }
        })();
    }
};

export default TelegramLogin;

