import makeRequest from "@shared/api/makeRequest";


export default async function({shop_id, payin, payout}) {
    return await makeRequest(`/api/v1/core/shop/comissions/set`, { 
        body: {
            shop_id,
            payin,
            payout
        }
    });
}