import { Controller } from "react-hook-form";
import SimpleInput from "@shared/ui/Simpleinput";


const AmountInput = ({control, error}) => {
    return <Controller
        name="amount"
        control={control}
        rules={{ required: "Обязательное поле", min: { value: 1, message: "Минимум 1" } }}
        render={({ field }) => (
            <SimpleInput
                {...field}
                label="Сумма"
                number
                error={error}
                placeholder="10 000"
            />
        )}
    />
}

export default AmountInput;