// src/components/sections/Section3.js
import React, {useEffect, useState} from 'react';
import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import makeRequest from '@shared/api/makeRequest'
import GlassCard from "@shared/ui/GlassCard";
import Table from '@shared/ui/Table'
import Text from "@shared/ui/Text";
import formatReadableDate from "@shared/helpers/formateReadableDate";
import Pagination from '@shared/ui/Pagination';
import PageWrapper from '@shared/ui/layouts/PageWrapper';
import CopyableText from "@shared/ui/CopyableText";


let columns = [
    { title: "Id ордера", dataIndex: "transaction_id", render: (value) => <CopyableText text={value} />, width: "1px" },
    { title: "Название магазина", dataIndex: "shop_name" },
    { title: "Сумма USDT", dataIndex: "amount" },
    { title: "Дата", dataIndex: "created_at", render: formatReadableDate},
    { title: "Описание", dataIndex: "transaction_type_description" },
];

const Archive = () => {
    const today = new Date();
    const lastWeek = new Date();
    lastWeek.setDate(today.getDate() - 7);

    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [startDate, setStartDate] = useState(lastWeek);
    const [endDate, setEndDate] = useState(today);

    useEffect(fetchArchiveBalance, [currentPage, startDate, endDate]);
    
    return (
        <PageWrapper loading={loading} title="Архив">
            <GlassCard>
                <Text weight="bold">Изменения баланса</Text>
                <VBox gap='30px'>
                    <VBox gap='10px'>
                        <Table loading={loading} columns={columns} dataSource={transactions} />
                        <HBox customJust='center'>
                            {totalPages > 1 && <Pagination total={totalPages} onChange={setCurrentPage} />}
                        </HBox>
                    </VBox>
                </VBox>
            </GlassCard>
            
        </PageWrapper>
    );

    function fetchArchiveBalance() {
        (async () => {
            try {
                setLoading(true);

                const {data, total_pages} = await makeRequest(`/api/v1/wallets/balance_transactions/?page=${currentPage}`, {
                    method: 'GET'
                })      
                const dataTransactions = (data.map(el => ({...el})))
                setTransactions(dataTransactions)
                setTotalPages(total_pages)
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
        
    }
};

export default Archive;
