import Vbox from "@shared/ui/layouts/Vbox";
import TableSkeleton from "@shared/ui/skeletons/TableSkeleton";
import DropDownSkeleton from "@shared/ui/skeletons/DropDownSkeleton";


const ContentPreloader = () => {
    return <Vbox gap="10px">
        <DropDownSkeleton />
        <TableSkeleton />
    </Vbox>
}

export default ContentPreloader;