import Hbox from "@shared/ui/layouts/Hbox";
import Vbox from "@shared/ui/layouts/Vbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { useState } from "react";
import BanksDropDown from "./elements/BanksDropDown";
import SimpleInput from "@shared/ui/Simpleinput";
import DeviceDropDown from "./elements/DeviceDropDown";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import createHubRequisiteApi from "@shared/api/services/hub_requisites/createHubRequisiteApi";
import { useForm, Controller } from 'react-hook-form';
import FioInput from "./elements/FioInput";
import RequisiteInput from "./elements/RequisiteInput";
import { PlusOutlined } from "@ant-design/icons";


const CreateHubRequisiteModal = ({hub_id, onSuccess, closeModal}) => {
    const [loading, setLoading] = useState(false);
    const { handleSubmit, control, formState: { errors } } = useForm();

    return <form onSubmit={handleSubmit(createHubRequisite)}>
        <Vbox gap="10px">
            <FioInput control={control} error={errors.fio?.message} />
            <RequisiteInput control={control} error={errors.requisite_number?.message} />
            <Hbox gap="10px">
                <BanksDropDown control={control} error={errors.bank?.message} />
                <DeviceDropDown control={control} error={errors.device?.message} />
            </Hbox>
            
            <Hbox customJust="end">
                <SimpleButton loading={loading} main icon={<PlusOutlined />} type="submit">Создать</SimpleButton>
            </Hbox>
        </Vbox>
    </form>

    async function createHubRequisite({ fio, requisite_number, bank, device }) {
        setLoading(true);
        closeModal();

        try {
            await handleAsyncNotification(async () => {
                await createHubRequisiteApi(hub_id, {
                    fio,
                    requisite_number,
                    bank,
                    device
                });
                onSuccess();
            }, {
                successText: "Реквизит успешно создан",
                errorText: "Ошибка создания реквизита",
                loadingText: "Создание реквизита"
            });
        } catch(e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
       
    }
}

export default CreateHubRequisiteModal;