import Table from "@shared/ui/Table";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Vbox from "@shared/ui/layouts/Vbox";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import linkShopToHubApi from "@shared/api/services/assosiations/linkShopToHubApi";
import SimpleInput from "@shared/ui/Simpleinput";
import {useModal} from "@shared/ModalContext";
import Hbox from "@shared/ui/layouts/Hbox";
import { getUnlinkedsColumns } from "../lib/getColumns";
import { Controller, useForm } from "react-hook-form";
import EditHubLimitsModal from "@features/EditHubLimitsModal";
import Pagination from "@shared/ui/Pagination";
import Text from "@shared/ui/Text";
import useSearchParams from "@shared/hooks/useSearchParams";
import linkShopToPayoutHubApi from "@shared/api/services/assosiations/linkShopToPayoutHubApi";


const Unlinkeds = ({loading, totalPages, onSuccess, currentPage, setCurrentPage, items}) => {
    const {searchParams} = useSearchParams();
    const {shop_id, trader_id} = useParams();
    const {openModal} = useModal();
    const type = searchParams.get("type") || "payin";

    const columns = useMemo(() => getUnlinkedsColumns(openLinkingModal, openLimitsEditModal), [openLinkingModal, openLimitsEditModal]);

    return <Vbox gap="10px">
        <Text weight="bold">Список непривязанных хабов</Text>
        <Table loading={loading} columns={columns} dataSource={items} />
        <Pagination total={totalPages} value={currentPage} onChange={setCurrentPage} />
    </Vbox>

    function openLinkingModal(hub_id) {
        openModal(<LinkModal type={type} onSuccess={onSuccess} hub_id={hub_id} shop_id={shop_id} />, "Привязка Хаба");
    }

    function openLimitsEditModal(hub_id) {
        openModal(<EditHubLimitsModal onSuccess={onSuccess} hub_id={hub_id} />, "Правка лимитов")
    }
}

function LinkModal({type, hub_id, shop_id, closeModal, onSuccess}) {
    const { handleSubmit, control, formState: { errors } } = useForm();

    return <form onSubmit={handleSubmit(linkShopToHub)}>
        <Vbox gap="10px">
            <Hbox gap="10px">
                <Controller
                    name={type}
                    control={control}
                    rules={{ required: "Обязательное поле", min: { value: 1, message: "Минимум 1 процент" }, max: { value: 100, message: "Максимум 100 процентов" } }}
                    render={({ field }) => (
                        <SimpleInput
                            {...field}
                            label={type}
                            error={errors[type]?.message}
                            number 
                            min={0} 
                            max={100}
                            prefix="%" 
                            placeholder='4'
                        />
                    )}
                />
            </Hbox>
            <Hbox customJust="end">
                <SimpleButton type="submit">Привязать</SimpleButton>
            </Hbox>
        </Vbox>
    </form>
    
    async function linkShopToHub({payin, payout}) {
        closeModal();
        await handleAsyncNotification(async () => {
            type === "payin" ?
                await linkShopToHubApi({shop_id, hub_id, payin})
                :
                await linkShopToPayoutHubApi({shop_id, payout, hub_id});
            onSuccess();
        }, {
            errorText: "Ошибка привязки",
            successText: "Привязка успешна",
            loadingText: "Привязка хаба к магазину"
        });
    }
}

export default Unlinkeds;