import Select from "@shared/ui/Select";
import { Controller } from "react-hook-form";

const options = [
    { value: true, label: "Включен" },
    { value: false, label: "Не включен" },
]

const ModelDropDown = ({error, control}) => {
    return <Controller
        name="turbo_mode"
        control={control}
        defaultValue={false} // Режим выключен по дефолту
        render={({ field }) => (
            <Select
                {...field}
                options={options}
                fullWidth
                label="Режим Турбо"
                error={error}
            />
        )}
    />
}

export default ModelDropDown;