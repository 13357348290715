import Hbox from "@shared/ui/layouts/Hbox";
import Vbox from "@shared/ui/layouts/Vbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import deleteHubRequisiteApi from "@shared/api/services/hub_requisites/deleteHubRequisiteApi";
import { useState } from "react";
import Text from "@shared/ui/Text";
import { DeleteOutlined } from "@ant-design/icons";


const DeleteRequisiteModal = ({hubId, reqId, onSuccess, closeModal}) => {
    const [loading, setLoading] = useState(false);

    return <Vbox gap="10px">
        <Text>Подтвердите удаление реквизита</Text>
        <Text>{reqId}</Text>
        <Hbox><SimpleButton main icon={<DeleteOutlined />} loading={loading} onClick={deleteRequisite}>Удалить</SimpleButton></Hbox>
    </Vbox>
    

    async function deleteRequisite() {
        setLoading(true);

        try {
            await handleAsyncNotification(async () => {
                closeModal();
                await deleteHubRequisiteApi({hub_id: hubId, req_id: reqId});
                onSuccess();
            }, {
                loadingText: "Удаление реквизита",
                successText: "Удаление реквизита успешно",
                errorText: "Ошибка удаления реквизита"
            });
        } catch(e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }
}

export default DeleteRequisiteModal;