import { Controller } from "react-hook-form";
import SimpleInput from "@shared/ui/Simpleinput";
import Textarea from "@shared/ui/Textarea";


const DescriptionInput = ({defaultValue, control, error}) => {
    return <Controller
        name="description"
        defaultValue={defaultValue}
        control={control}
        rules={{ maxLength: { value: 200, message: "Максимум 200 символов" } }}
        render={({ field }) => (
            <Textarea
                {...field}
                label="Описание"
                error={error}
                placeholder="Payout-Hub, используемый для ..."
            />
        )}
    />
}

export default DescriptionInput;