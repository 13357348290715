import React, { useState, useEffect } from 'react';
import VBox from '@shared/ui/layouts/Vbox';
import SimpleInput from '@shared/ui/Simpleinput';


const ChangeShopModal = ({ shops, shopid }) => {
    const [formData, setFormData] = useState({
        name: '',
        websiteurl: '',
        websiteip: '',
        digitalcurrency: '',
        fiatcurrency: '',
        paymentrules: []
    });

    useEffect(() => {
        if (shops && shopid) {
            const shopData = shops.find(shop => shop.shopid === shopid);
            if (shopData) {
                setFormData({
                    name: shopData.name || '',
                    websiteurl: shopData.websiteurl || '',
                    websiteip: shopData.websiteip || '',
                    digitalcurrency: shopData.digitalcurrency || '',
                    fiatcurrency: shopData.fiatcurrency || '',
                    paymentrules: shopData.paymentrules || {}
                });

            }
        }
    }, [shops, shopid]);

    return (
        <VBox gap="10px">
            <SimpleInput label="Название" placeholder='IP' width='400px' value={formData.name} />
            <SimpleInput label="URL сайта" placeholder='IP' width='400px' value={formData.websiteurl} />
            <SimpleInput label="IP сайта" placeholder='IP' width='400px' value={formData.websiteip} />
        </VBox>
    )
};

export default ChangeShopModal;