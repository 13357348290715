import ElementCard from "@shared/ui/ElementCard";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { useEffect, useState } from "react";
import Hbox from "@shared/ui/layouts/Hbox";
import Label from "@shared/ui/Label";
import Text from "@shared/ui/Text";
import { LockOutlined } from "@ant-design/icons";
import ClipboardCopy from "@shared/ui/ClipboardCopy";
import handleAsyncNotification from '@shared/helpers/handleAsyncNotification';
import TronQrWidget from "@shared/ui/widgets/TronQrWidget";
import InputOTP from "@shared/ui/InputOTP";
import makeRequest from "@shared/api/makeRequest";


const TurnOnAuth = ({setStatus}) => {
    const [authData, setAuthData] = useState({});
    const [value, setValue] = useState("");
    const [commitLoading, setCommitLoading] = useState(false);
    const [generateLoading, setGenerateLoading] = useState(false);

    useEffect(() => {
        if(value.length >= 6) {
            commit();
        }
    }, [value]);

    return <>
        <ElementCard>
            <Label value="Статус">
                <Text size="subheader">Выключена</Text>
            </Label>
            <SimpleButton loading={generateLoading} disabled={authData.qr_url} onClick={turnOnAuth} main icon={<LockOutlined />}>Включить</SimpleButton>
        </ElementCard>
        {authData?.qr_url && <ElementCard>
            <Hbox customJust="center">
                <TronQrWidget address={authData.qr_url} />
            </Hbox>
            
            <ClipboardCopy value={authData.fingertype} />
            <Label value="Код подтверждения">
                <InputOTP disabled={commitLoading} value={value} onChange={(value) => {setValue(value)}} />
            </Label>
        </ElementCard>}
    </>

    async function turnOnAuth() {
        (async () => {
            try {
                setGenerateLoading(true);

                const response = await makeRequest("/api/v1/core/2fa/generate");

                setAuthData(response);
            } catch(e) {
                console.log(e);
            } finally {
                setGenerateLoading(false);
            }
        })();
    }

    async function commit() {
        setCommitLoading(true);
        await handleAsyncNotification(async () => {
            await makeRequest("/api/v1/core/2fa/commit", { 
                body: {
                    temp_code: value
                }
            });
            setStatus(true);
        }, {
            successText: "2FA включена",
            errorText: "Ошибка включения 2FA",
            loadingText: "Включение 2FA"
        });
        setCommitLoading(false);
        setAuthData({});
        setValue("");
    }
}

export default TurnOnAuth;