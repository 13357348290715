import Vbox from "@shared/ui/layouts/Vbox";
import SimpleInput from "@shared/ui/Simpleinput";
import Hbox from "@shared/ui/layouts/Hbox";
import PaymentMethodDropDown from "./elements/PaymentMethodDropDown";
import CurrencyDropDown from "./elements/CurrencyDropDown";
import ModelDropDown from "./elements/ModelDropDown";
import HubTypeDropDown from "./elements/HubTypeDropDown";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import createHubApi from "@shared/api/services/hubs/createHubApi";
import { Controller, useForm } from "react-hook-form";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import DescriptionInput from "./elements/DescriptionInput";
import NameInput from "./elements/NameInput";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";


const CreateRecHubModal = ({closeModal, onSuccess}) => {
    const { handleSubmit, control, formState: { errors } } = useForm();
    
    return <form onSubmit={handleSubmit(createRecHub)}>
        <Vbox gap="10px">
            <NameInput control={control} error={errors.name?.message} />
            <DescriptionInput control={control} error={errors.description?.message} />
            <Hbox gap="10px">
                <PaymentMethodDropDown control={control} error={errors.payment_method_id?.message} />
                <CurrencyDropDown control={control} error={errors.currency_id?.message} />
            </Hbox>
            <Hbox gap="10px">
                <ModelDropDown error={errors.turbo_mode?.message} control={control} />
                <HubTypeDropDown error={errors.trafic_mode?.message} control={control} />
            </Hbox>
            <Hbox customJust="end"><SimpleButton main icon={<PlusOutlined />} type="submit">Создать</SimpleButton></Hbox>
        </Vbox>
    </form>

    async function createRecHub({name, description, payment_method_id, currency_id, trafic_mode, turbo_mode}) {
        try {
            closeModal();
            await handleAsyncNotification(async () => {
                await createHubApi({
                    name: name || "Без названия",
                    description: description || "Без описания",
                    payment_method_id,
                    currency_id,
                    trafic_mode,
                    turbo_mode
                });
                onSuccess();
            }, {
                successText: "Хаб успешно создан",
                errorText: "Ошибка создания хаба",
                loadingText: "Создание хаба"
            });
        } catch(e) {
            console.log(e);
        }
    }
}

export default CreateRecHubModal;