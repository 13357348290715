import { useEffect, useState } from "react";
import Switch from "@shared/ui/Switch";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";


function RequestSwitcher({onSuccess = () => {}, defaultValue, request}) {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return <Switch onChange={toggle} loading={loading} value={value} 
        // checkedChildren="Вкл." unCheckedChildren="Выкл." 
    />

    async function toggle() {
        setLoading(true);

        await handleAsyncNotification(async () => {
            await request();
            setValue((value) => !value);
            onSuccess();
        }, {
            successText: "Статус изменен",
            errorText: "Ошибка изменения статуса",
            loadingText: "Изменение статуса"
        });

        setLoading(false);
    }
}

export default RequestSwitcher;