import ElementCard from "@shared/ui/ElementCard";
import makeRequest from "@shared/api/makeRequest";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Image from "@shared/ui/Image";
import {FiveColumns} from "@shared/ui/containers/ListContainers";
import Text from "@shared/ui/Text";
import FileLink from "@shared/ui/FileLink";


const ReceiptsBlock = ({receipts}) => {
    return <>
        <Text weight="bold">Приложенные чеки</Text>
        <ElementCard>
            <FiveColumns>
                {receipts.map(({filename, file_url, created_at}) => <FileLink type={filename.split(".")[1]} createdAt={created_at} name={filename} src={file_url} />)}  
            </FiveColumns>
        </ElementCard>
    </>
}

export default ReceiptsBlock;