import SimpleButton from "@shared/ui/buttons/SimpleBtn"
import useSearchParams from "@shared/hooks/useSearchParams";


const ClearFiltersButton = () => {
    const {setSearchParams} = useSearchParams();

    return <SimpleButton onClick={() => {setSearchParams({})}}>
        Очистить фильтры
    </SimpleButton>
}

export default ClearFiltersButton;