import PageWrapper from "@shared/ui/layouts/PageWrapper";
import makeRequest from "@shared/api/makeRequest";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Info from "@shared/ui/Info";
import Vbox from "@shared/ui/layouts/Vbox";
import GlassCard from "@shared/ui/GlassCard";
import Hbox from "@shared/ui/layouts/Hbox";
import ElementCard from "@shared/ui/ElementCard";
import Text from "@shared/ui/Text";
import styled from "styled-components";
import {TwoColumns} from "@shared/ui/containers/ListContainers";
import ReceiptsBlock from "./elements/ReceiptsBlock";
import Actions from "./elements/Actions";
import Chat from "@features/Chat";
import getRoomsIdsApi from "@shared/api/services/chat/getRoomsIdsApi";
import {useAuth} from "@shared/AuthContext";
import Timeline from "@shared/ui/Timeline";


const Link = styled.a`
    cursor: pointer;
`

const MainContainer = styled.div`
    display: grid;
    grid-template-columns: 5fr 1fr;
    gap: 10px;

    @media(max-width: ${props => props.theme.mediaSizes.small}) {
        grid-template-columns: 1fr;
    }
`


const Order = () => {
    const {order_id} = useParams();
    const {user} = useAuth();
    const [orderData, setOrderData] = useState({});
    const [receipts, setReceipts] = useState([]);
    const [rooms, setRooms] = useState([]);

    useEffect(fetchOrderData, []);
    useEffect(fetchReceiptsBlock, []);
    useEffect(fetchRoomsIds, []);

    const callbacks = useMemo(getCallbacks, [orderData]);

    return <PageWrapper title="Детали ордера">
        <Vbox gap="10px">
            <GlassCard>
                <MainContainer>
                    <TwoColumns>
                        <ElementCard>
                            <Text weight="bold">Основная информация</Text>
                            <Info title="ID" value={orderData.id} />
                            <Info title="Дата создания ордера" value="25.07.2025 23:23" />
                            <Info title="ID ордера у мерчанта" value={orderData.integration?.external_order_id} />
                            <Info title="ID клиента у мерчанта" value={orderData.customers?.external_id} /> 
                            <Info title="ID клиента в системе" value={orderData.customers?.id} />
                        </ElementCard>
                        <Vbox gap="10px">
                            <ElementCard>
                                <Text weight="bold">Реквизиты</Text>
                                <Hbox>
                                    <Info title="Валюта" value={orderData.currency} />
                                    <Info title="Банк" value={orderData.bank_name} />
                                    <Info title="Курс" value={`${orderData.rate} ${orderData.currency}`} />
                                </Hbox>
                                <Hbox>
                                    <Info title="ФИО" value={orderData.requisite?.fio} />
                                    <Info title="Сумма в фиате" value={orderData.amount} />
                                </Hbox>
                                <Hbox>
                                    <Info title="Номер" value={orderData.requisite?.requisite_number} />
                                    <Info title="Сумма в USDT" value={orderData.usdt_amount} />
                                </Hbox>
                            </ElementCard>
                            <ElementCard>
                                <Text weight="bold">Статус</Text>
                                <Info title="Reason" value={orderData.reason?.label} />
                                <Info title="Status" value={orderData.status?.label} />
                                <Info title="Колбек" value={orderData.integration?.callback_url} />
                                <Info title="Описание" value={orderData.status?.description || "-"} />
                            </ElementCard>
                        </Vbox>
                    </TwoColumns>
                    <Vbox gap="10px">
                        <ElementCard>
                            <Actions onSuccess={() => {fetchOrderData(); fetchReceiptsBlock();}} orderData={orderData} />
                        </ElementCard>
                        <ElementCard>
                            <Text weight="bold">История</Text>
                            <Timeline items={callbacks} />
                        </ElementCard>
                    </Vbox>
                </MainContainer>
            </GlassCard>
            <GlassCard>
                <ReceiptsBlock receipts={receipts} />
            </GlassCard>
            <TwoColumns>
                {rooms.map(({room_id, username}) => <Chat room_id={room_id} username={username || "Поддержка"} />)}
            </TwoColumns>
        </Vbox>
    </PageWrapper>

    function fetchOrderData() {
        (async () => {
            try {
                const data = await makeRequest(`/api/v1/orders/transactions/${order_id}`, { method: "GET" });
                
                setOrderData(data);
            } catch(e) {
                console.log(e);
            }
        })();
    }

    function fetchReceiptsBlock() {
        (async () => {
            try {
                const receipts = await makeRequest(`/api/v1/orders/transactions/${order_id}/receipts/get`, { method: "GET" });

                setReceipts(receipts)
            } catch(e) {
                console.log(e)
            }
        })();
    }

    function fetchRoomsIds() {
        (async () => {
            try {
                const {rooms} = await getRoomsIdsApi(order_id);

                setRooms(rooms);
            } catch(e) {
                console.log(e)
            }
        })();
    }

    function getCallbacks() {
        return orderData.callbacks
            ?.map(({reason, created_at, ...props}) => ({
                children: reason, created_at, ...props
            }))
            .sort((el1, el2) => new Date(el1.created_at) - new Date(el2.created_at))
    }
}

export default Order;