import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import Text from "@shared/ui/Text";
import styled from "styled-components";

const Container = styled.div`
    padding: 10px;
    max-width: 300px;
`


const Notification = () => {
    return <Container>
        <Vbox gap="10px">
            <Hbox alignItems="end" gap="10px">
                <Text color="black" weight="bold">Открыт диспут</Text>
                <Text color="black" size="small">25.02.12 12:22</Text>
            </Hbox>
            
            <Text color="black" size="small">
                Открыт диспут от имени мерчанта в 22:22 на сумму 23 USDT
            </Text>
        </Vbox>
    </Container>
}

export default Notification;