import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import Icon from '@shared/ui/Icon';
import Text from '@shared/ui/Text'
import ReqsTrader from './ReqsTrader';
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Devices from './Devices';
import SimpleInput from '@shared/ui/Simpleinput';

const ReqsModel = ({ columnsPayin, secondHandleClick, secondActive, transactions_devices, trader_id }) => (

    <VBox gap='10px'>
        <HBox customJust='unset' gap='10px' padding='10px 0px'>
            <SimpleButton
                main={secondActive === 'All_reqs'}
                onClick={() => secondHandleClick('All_reqs')}>
                Все реквизиты</SimpleButton>

            <SimpleButton
                main={secondActive === 'Devices'}
                onClick={() => secondHandleClick('Devices')}>
                <Icon src='/icons/monitor-mobbile.svg' margin='0' /></SimpleButton>
            <SimpleButton
                main={secondActive === 'Limits'}
                onClick={() => secondHandleClick('Limits')}>
                Лимиты</SimpleButton>
        </HBox>
        {secondActive === 'All_reqs' && (
            <ReqsTrader trader_id={trader_id} />
        )}
        {secondActive === 'Devices' && (
            <Devices trader_id={trader_id} />
        )}
        {secondActive === 'Limits' && (
            <HBox gap='20px'>
                <VBox gap='10px' width='100%'>
                    <VBox gap='10px' >
                        <p style={{ margin: '0' }}>Макс сумма</p>
                        <HBox align='center'>
                            <Text>В час</Text>
                            <SimpleInput placeholder='500 000' />
                        </HBox>
                        <HBox align='center'>
                            <Text>В день</Text>
                            <SimpleInput placeholder='5 000 000' />
                        </HBox>
                        <HBox align='center' borderBottom='1px solid var(--second_primary)' padding='0 0 5px 0'>
                            <Text>В месяц</Text>
                            <SimpleInput placeholder='100 000 000' />
                        </HBox>
                    </VBox>
                    <VBox gap='10px'>
                        <p style={{ margin: '0' }}>Макс кол-во ордеров</p>
                        <HBox align='center'>
                            <Text>В час</Text>
                            <SimpleInput placeholder='150' />
                        </HBox>
                        <HBox align='center'>
                            <Text>В день</Text>
                            <SimpleInput placeholder='1 500' />
                        </HBox>
                        <HBox align='center' borderBottom='1px solid var(--second_primary)' padding='0 0 5px 0'>
                            <Text>В месяц</Text>
                            <SimpleInput placeholder='25 000' />
                        </HBox>
                    </VBox>
                    <HBox gap='30px'>
                        <p style={{ margin: '0' }}>Частота успешных ордеров</p> {/*Не чаще чем в X минут */}
                        <p style={{ margin: '0' }}>70%</p>
                    </HBox>
                </VBox>
            </HBox>
        )}
    </VBox>
)
export default ReqsModel