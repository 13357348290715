import PageWrapper from "@shared/ui/layouts/PageWrapper";
import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Vbox from "@shared/ui/layouts/Vbox";
import Info from "@shared/ui/Info";
import makeRequest from "@shared/api/makeRequest";
import Text from "@shared/ui/Text";
import { useNavigate, useParams } from "react-router-dom";
import Hbox from "@shared/ui/layouts/Hbox";
import { useEffect, useState } from "react";
import styled from "styled-components";


const Card = styled(GlassCard)`
    padding: 5px;
    white-space: nowrap;
`

const Payment = () => {
    const [loading, setLoading] = useState(true);
    const {payment_id} = useParams();
    const [banks, setBanks] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const navigate = useNavigate();

    useEffect(fetchData, []);

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/settings")}}>Настройки</a> },
        { title: <a onClick={() => {navigate("/settings/tradings/payments")}}>Настройки методов</a> }
    ]

    return <PageWrapper breadcrumbs={breadcrumbs} title="Просмотр валюты">
        <GlassCard>
            <Text weight="bold">Привязки</Text>
            <ElementCard>
                <Vbox gap="10px">
                    <Info title="Банки" value={<Hbox wrap customJust="start" gap="10px">{banks.map((el => <Card>{el}</Card>))}</Hbox>} />
                    <Info title="Способы оплаты" value={<Hbox wrap customJust="start" gap="10px">{currencies.map((el => <Card>{el}</Card>))}</Hbox>} />
                </Vbox>
            </ElementCard>
        </GlassCard>
    </PageWrapper>


    function fetchData() {
        (async () => {
            try {
                const {currencies, banks} = await makeRequest(`/api/v1/core/trading_methods/details/${payment_id}?type=methods`, { method: "GET" });

                setBanks(banks);
                setCurrencies(currencies);
            } catch(e) {
                console.log(e);
            }
        })();
    }
}

export default Payment;