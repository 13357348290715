import React from "react";
import styled from "styled-components";

const StyledIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: ${(props) => props.margin || '12px'};
`;

const CustomIcon = ({src, margin}) => {
  return <StyledIcon src={src} margin={margin} />;
}

export default CustomIcon;
