import { useState, useEffect } from "react";


const Timer = ({endDate}) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [endDate]);

    return <div>
        {String(timeLeft.hours).padStart(2, "0")}:{String(timeLeft.minutes).padStart(2, "0")}:{String(timeLeft.seconds).padStart(2, "0")}
    </div>

    function calculateTimeLeft() {
        const end = new Date(endDate);
        const start = new Date();
        const difference = end - start;

        const totalTimeInMinutes = Math.floor((difference / 1000 / 60));
        const hours = Math.floor(totalTimeInMinutes / 60);
        const minutes = Math.floor(totalTimeInMinutes - hours * 60);
        const seconds = Math.floor((difference / 1000) % 60);

        if(difference < 0) return { hours: 0, minutes: 0, seconds: 0 };

        return { hours, minutes, seconds };
    }
}

export default Timer;