import SimpleInput from "@shared/ui/Simpleinput";
import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import { SwapOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Text from "@shared/ui/Text";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Select from "@shared/ui/Select";
import getShopsApi from "@shared/api/services/merchant/getShopsApi";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import createS2SInternalTransferApi from "@shared/api/services/merchant/internalTransfers/createS2SInternalTransferApi";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import { useMemo } from "react";
import GlassCard from "@shared/ui/GlassCard";



const TransferCreationForm = ({shops, loading}) => {
    const { handleSubmit, setValue, control, formState: { errors }, watch } = useForm();
    const normalizedShops = useMemo(() => shops.map(({shop_id, name, total_balance}) => ({value: shop_id, label: `${name} (${total_balance} USDT)`})), [shops]);
    const balance = shops.find(({shop_id}) => shop_id === watch("from_id"))?.total_balance;

    return <GlassCard width="fit-content">
        <form onSubmit={handleSubmit(createTransfer)}>
            <Vbox gap="10px">
                <Text weight="bold">Создать перевод</Text>
                <Hbox gap="30px" customJust="start" alignItems="center">
                    <Controller
                        name="from_id"
                        control={control}
                        rules={{ required: "Обязательное поле" }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                loading={loading}
                                options={normalizedShops}
                                fullWidth
                                showSearch
                                label="Отправитель"
                                error={errors.from_id?.message}
                            />
                        )}
                    />
                    <Button onClick={swapShops} shape="circle" children="" icon={<SwapOutlined />}/>
                    
                    <Controller
                        name="to_id"
                        control={control}
                        rules={{ required: "Обязательное поле" }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                loading={loading}
                                options={normalizedShops.filter(({value}) => value !== watch("from_id"))}
                                fullWidth
                                showSearch
                                label="Получатель"
                                error={errors.to_id?.message}
                            />
                        )}
                    />
                </Hbox>
                <Controller
                    name="amount"
                    control={control}
                    rules={{ required: "Обязательное поле", min: { value: 1, message: "Минимум 1 USDT" } }}
                    render={({ field }) => (
                        <SimpleInput
                            {...field}
                            label="Описание"
                            number
                            error={errors.amount?.message}
                            placeholder="23 000"
                        />
                    )}
                />
                <Text>Доступный баланс <Text weight="bold">{balance || 0} USDT</Text></Text>
                <SimpleButton main type="submit">Подтвердить</SimpleButton>
            </Vbox>
        </form>
    </GlassCard> 

    function swapShops() {
        // Получаем текущие значения sender_id и reciever_id
        const from_id = control._formValues.from_id;
        const to_id = control._formValues.to_id;

        // Меняем значения местами
        setValue("to_id", from_id);
        setValue("from_id", to_id);
    }

    async function createTransfer({amount, from_id, to_id}) {
        await handleAsyncNotification(async () => {
            await createS2SInternalTransferApi({amount, from_id, to_id});
        }, {
            successText: "Заявка создана",
            errorText: "Ошибка создания заявки",
            loadingText: "Создание заявки"
        });
    }
};

export default TransferCreationForm;