import React from "react";
import { Button } from "antd";
import styled from "styled-components";

// Фильтрация нестандартных пропсов
const StyledButton = styled(Button).withConfig({
    shouldForwardProp: (prop) =>
        !["main", "fullWidth"].includes(prop), // Исключаем нестандартные пропсы
})`  
    background: ${(props) => props.main ? props.theme.gradient : props.theme.background}; /* Градиентный фон */
    background-size: 200%; /* Для эффекта смещения */
    background-position: left; /* Начальная позиция */
    color: ${(props) => props.main ? "white" : props.theme.color}; /* Белый текст */
    border: solid 1px ${(props) => props.main ? props.theme.border : props.theme.border} !important; /* Убираем границу */
    transition: background-position 1s ease, transform 0.3s ease, color 0.3s ease;
    border-radius: 4px; /* Скругление углов */
    font-family: Manrope;

    &:hover {
        background: ${(props) => props.main ? props.theme.gradient : props.theme.hover} !important; /* Обратный градиент */
        border: solid 1px ${(props) => props.main ? props.theme.border : props.theme.border} !important; /* Убираем границу */
        color: ${(props) => props.main ? "white" : props.theme.color} !important; /* Белый текст */
    }

    &:disabled {
        background: ${(props) => props.theme.disabledBackground || "gray"} !important; /* Фон для disabled */
        color: ${(props) => props.theme.disabledColor || "#ccc"} !important; /* Цвет текста для disabled */
        border: solid 1px ${(props) => props.theme.disabledBorder || "#999"} !important; /* Граница для disabled */
        cursor: not-allowed; /* Устанавливаем курсор для disabled */
        pointer-events: none; /* Отключаем все hover-эффекты */
    }
`;

const SimpleButton = ({
    loading,
    type,
    main,
    onClick,
    fullWidth = true,
    disabled,
    ...rest
}) => {
    const Component = (
        <StyledButton
            htmlType={type}
            main={main}
            loading={loading}
            disabled={disabled}
            onClick={disabled || loading ? undefined : onClick}
            {...rest}
        />
    );

    if (fullWidth) return Component;
    return <div>{Component}</div>;
};

export default SimpleButton;
