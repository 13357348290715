import React, { useRef, useEffect, useState } from 'react';
import TronQRCode from '@shared/ui/widgets/TronQrWidget';
import GlassCard from "@shared/ui/GlassCard";
import Text from "@shared/ui/Text";
import Loading from '@shared/ui/Loading';
import Select from "@shared/ui/Select";
import RangePicker from "@shared/ui/RangePicker";
import dayjs from "dayjs"
import Table from "@shared/ui/Table";
import makeRequest from "@shared/api/makeRequest";
import Pagination from "@shared/ui/Pagination";
import {ThreeColumns} from "@shared/ui/containers/ListContainers";
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import HBox from '@shared/ui/layouts/Hbox';
import formatReadableDate from '@shared/helpers/formateReadableDate';
import { useNavigate } from 'react-router-dom';
import Label from "@shared/ui/Label";
import Vbox from "@shared/ui/layouts/Vbox";
import ClipboardCopy from '@shared/ui/ClipboardCopy';


const Deposit = () => {
    const formatDate = (dayjsDate) => {
        if(!dayjsDate) return "";
        const date = dayjsDate.toDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const today = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);

    const [statuses, setStatuses] = useState([
        { label: 'Завершенные', value: 'completed' },
        { label: 'Отклонено', value: 'canceled' },
        { label: 'В процессе', value: 'in_processing' }
    ]);
    const [status, setStatus] = useState('completed');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [transactions, setTransactions] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().subtract(7, "day"));
    const [endDate, setEndDate] = useState(dayjs());
    const [loading, setLoading] = useState(true);
    const [walletAddress, setWalletAddress] = useState('');
    const textDivRef = useRef(null);

    useEffect(fetchWalletData, []);
    
    useEffect(fetchDeposits, [status, currentPage]);

    const columns = [
        { title: 'ID', dataIndex: 'transaction_id' },
        { title: 'С кошелька', dataIndex: 'to_wallet_address' },
        { title: 'Сумма', dataIndex: 'amount' },
        { title: 'Hash', dataIndex: 'hash', render: (value) => <a href={`https://tronscan.org/#/transaction/${value}`}>Tronscan</a> },
        { title: 'Статус', dataIndex: 'status' },
        { title: 'Дата & Время', dataIndex: 'created_at' }
    ];

    const handleDateChange = (fromDate, toDate) => {
        setStartDate(formatDate(fromDate));
        setEndDate(formatDate(toDate));
    };

    const acceptDate = async () => {
        setCurrentPage(1);
        setTransactions([])
        fetchDeposits();
    };

    const navigate = useNavigate();


    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/assets")}}>Активы</a> }
    ]

    if (loading) {
        return <Loading />;
    }

    return (
        <PageWrapper breadcrumbs={breadcrumbs} title="Пополнение">           
            <ThreeColumns>
                <GlassCard>
                    <Text weight="bold">Пополнение</Text>
                    <Vbox gap="8px">              
                        <Label value="Тип">
                            <Text>USDT TRC-20 Address</Text>
                        </Label>
                        <Label value="Адрес для пополнения">
                            <ClipboardCopy value={walletAddress} />
                        </Label>
                    </Vbox>
                    <TronQRCode address={walletAddress} />
                </GlassCard>
            </ThreeColumns>
            <GlassCard>
                <Text weight="bold">История пополнений</Text>
                <HBox wrap gap="10px">
                    <Select defaultValue={statuses[0].value} options={statuses} onChange={setStatus} />
                    <div>
                        <RangePicker startDate={startDate} endDate={endDate} onChange={(startDate, endDate) => {setStartDate(startDate); setEndDate(endDate)}} />
                    </div>
                </HBox>
                <Table columns={columns} dataSource={transactions} />
                <Pagination total={totalPages} onChange={setCurrentPage} />
            </GlassCard>
        </PageWrapper>
    );

    function fetchDeposits(page = currentPage, timezone = "UTC") {
        (async () => {
            try {
                setTransactions([]);
                const params = new URLSearchParams({
                    page: currentPage,
                    ...(status && { status }),
                    ...(startDate && { start_date: formatDate(startDate)  }),
                    ...(endDate && { end_date: formatDate(endDate) })
                });
    
                const data = await makeRequest(`/api/v1/wallets/wallet/transactions?${params.toString()}`, {
                    method: 'GET',
                });

                const deposits = data.transactions.map(item => ({
                    ...item,
                    status: statuses.find(s => s.value === item.status)?.label || '-',
                    created_at: formatReadableDate(item.created_at)
                }));
                
                setTotalPages(data.total_pages);
                setTransactions(deposits);
            } catch (error) {
                console.error("Ошибка при получении данных о пополнениях:", error);
            }
        })();
    };

    function fetchWalletData() {
        (async () => {
            try {
                const {wallet_address} = await makeRequest('/api/v1/wallets/wallet/', { method: "GET" });
                setWalletAddress(wallet_address);
            } catch (error) {
                console.error('Ошибка при получении данных о кошельке', error);
            } finally {
                setLoading(false);
            }
        })();        
    }
};

export default Deposit;
