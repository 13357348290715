import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import CopyableText from "@shared/ui/CopyableText";
import switchShopStatusApi from "@shared/api/services/shops/switchShopStatusApi";
import RequestSwitcher from "@features/RequestSwitcher";


export default function(openChangeModal, fetchShops) {
    return [
        {
            title: "ID",
            dataIndex: "id",
            width: "1px",
            fixed: "left",
            render: (value) => <CopyableText text={value} />
        },
        {
            title: "Название",
            dataIndex: "name",
        },
        {
            title: "Payin",
            dataIndex: "payin",
        },
        {
            title: "Payout",
            dataIndex: "payout",
        },
        {
            title: "Цифровая валюта",
            dataIndex: "digital_currency",
        },
        {
            title: "Фиатная валюта",
            dataIndex: "fiat_currency",
        },
        {
            title: "Статус (админ)",
            dataIndex: "admin_enabled",
            width: "1px",
            render: (value) => value ? "Включен" : "Выключен"
        },
        {
            title: "Статус (мерчант)",
            dataIndex: "user_enabled",
            width: "1px",
            render: (value, {id}) => <RequestSwitcher fetchShops={fetchShops} defaultValue={value} request={async () => {await switchShopStatusApi(id)}} />
        },
        {
            title: "",
            dataIndex: "action",
            width: "1px",
            fixed: "right",
            render: (_, {id}) => <SimpleButton onClick={() => {openChangeModal(id)}}>Настроить</SimpleButton>
        }
    ]
}