import { useEffect, useState } from "react";
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useNavigate, useParams } from "react-router-dom";
import getUserInfoApi from "@shared/api/services/admin/getUserInfoApi";
import SimpleInput from "@shared/ui/Simpleinput";
import Hbox from "@shared/ui/layouts/Hbox";
import useDebounce from "@shared/hooks/useDebounce";
import Linkeds from "./elements/Linkeds";
import Unlinkeds from "./elements/Unlinkeds";
import PagePreloader from "./elements/PagePreloader";
import Select from "@shared/ui/Select";
import ElementCard from "@shared/ui/ElementCard";
import GlassCard from "@shared/ui/GlassCard";
import Text from "@shared/ui/Text";


const roles = [
    { label: "Трейдер", value: "trader" },
    { label: "Шоп", value: "shop" },
]

const statuses = [
    { label: "Привязанные", value: "linkeds" },
    { label: "Непривязанные", value: "unlinkeds" },
]

// Страница для просмотра привязанных Трейдеров и (или) Шопов к агенту, чей id указан в квери
const Assosiations = () => {
    const [username, setUsername] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [currentRole, setCurrentRole] = useState(roles[0].value);
    const [usernameLoading, setUsernameLoading] = useState(true);
    const [status, setStatus] = useState(statuses[0].value);
    const {agent_id} = useParams();
    const debouncedValue = useDebounce(searchValue, 1000);
    const navigate = useNavigate();

    useEffect(fetchUsername, [agent_id]);

    const title = `Правка связей агента "${username}"`;
    const breadcrumbs = [ { title: <a onClick={() => {navigate("/agents")}}>Привязка агентов</a> } ]

    return <PageWrapper breadcrumbs={breadcrumbs} ContentPreloader={PagePreloader} loading={usernameLoading} title={title}>
        <GlassCard>
            <Text weight="bold">Список трейдеров</Text>
            <ElementCard>
                <Hbox customJust="start" wrap gap="10px">
                    <div><SimpleInput label="Введите имя трейдера, магазина или его владельца" placeholder="Поиск по названию" value={searchValue} onChange={(e) => {setSearchValue(e.target.value)}}/></div>
                    
                    <Select label="Роль" value={currentRole} options={roles} onChange={setCurrentRole} />
                    <Select label="Статус" value={status} options={statuses} onChange={setStatus} />
                </Hbox>
                {status === "linkeds" && <Linkeds agent_id={agent_id} agentName={username} currentRole={currentRole} searchValue={debouncedValue} />}
                {status === "unlinkeds" && <Unlinkeds agent_id={agent_id} agentName={username} currentRole={currentRole} searchValue={debouncedValue} />}
            </ElementCard>
        </GlassCard>
    </PageWrapper>

    function fetchUsername() {
        (async () => {
            setUsernameLoading(true);

            try {
                const {username} = await getUserInfoApi(agent_id);

                setUsername(username);
            } catch(e) {
                console.log(e);
            } finally {
                setUsernameLoading(false);
            }
        })();
    }
}

export default Assosiations;