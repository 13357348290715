import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '@shared/AuthContext';
import Text from "@shared/ui/Text";
import {
    AreaChartOutlined,
    BarChartOutlined,
    BulbOutlined,
    MehOutlined,
    MergeOutlined,
    MobileOutlined,
    OneToOneOutlined,
    PlusSquareOutlined,
    SaveOutlined,
    SettingOutlined,
    ShopOutlined,
    SmileOutlined
} from '@ant-design/icons';
import Hbox from "@shared/ui/layouts/Hbox";
import LogoIcon from '@shared/ui/icons/LogoIcon';
import Icon from "@shared/ui/icons/Icon";

// Фильтрация нестандартных пропсов
const SidebarContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isVisible'].includes(prop),
})`
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    background-color: ${(props) => props.theme.background};
    padding: 18px;
    border-right: solid 1px ${(props) => props.theme.border};
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 170px;
    justify-content: space-between;
    gap: 20px;
    flex: 1;
    transform: ${({ isVisible }) => (isVisible ? 'translateX(0)' : 'translateX(-100%)')};
    transition: transform 0.3s ease-in-out;

    @media (max-width: ${(props) => props.theme.mediaSizes.small}) {
        right: 0;
        top: 0;
        // transition: opacity 0.3s ease-in-out;
        // opacity: ${({ isVisible }) => (isVisible ? 1 : 0)}; /* Изменяем прозрачность в зависимости от isVisible */
        // visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
        transform: ${({ isVisible }) => (isVisible ? 'translateY(0)' : 'translateY(100%)')};
        transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
`;

const MainButtonsWithLogo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const MainButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Button = styled.button.withConfig({
    shouldForwardProp: (prop) => !['active'].includes(prop),
})`
    display: flex;
    align-items: center;
    width: auto;
    border: solid 1px ${props => props.theme.border};
    padding: 8px 16px;
    background-color: ${({ active, theme }) => (active ? theme.secondary : 'transparent')};
    color: ${({ theme, active }) => (active ? "black" : theme.color)};
    border-radius: 12px;
    border: none;
    text-align: left;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    transition: background-color 0.3s ease, color 0.3s ease, border-radius 0.3s ease;

    &:hover {
        background-color: ${props => props.theme.secondary};
        border-radius: 12px;
        color: #1f1f1f;
    }
`;

const Sidebar = ({ isVisible, closeSidebar }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path || location.pathname.startsWith(`${path}/`);
    };

    const redirect = (path) => {
        navigate(path);
        closeSidebar();
    };

    function getButtons() {
        const commonButtons = [];
        
        const roleSpecificButtons = {
            trader: [
                { Icon: BarChartOutlined, path: '/assets', title: 'Активы' },
                { Icon: BulbOutlined, path: '/orders', title: 'Ордера' },
                { Icon: OneToOneOutlined, path: '/payinhubs', title: 'Payin-Hubs' },
                { Icon: MobileOutlined, path: '/devices', title: 'Устройства' },
                { Icon: MergeOutlined, path: '/payouthubs', title: 'Payout-Hubs' },
                { Icon: AreaChartOutlined, path: '/statistics', title: 'Статистика' },
                { Icon: SettingOutlined, path: '/settings', title: 'Настройки' },
            ],
            merchant: [
                { Icon: BarChartOutlined, path: '/assets', title: 'Активы' },
                { Icon: BulbOutlined, path: '/orders', title: 'Ордера' },
                { Icon: ShopOutlined, path: '/shops', title: 'Магазины' },
                { Icon: SaveOutlined, path: '/archive', title: 'Архив' },
                { Icon: AreaChartOutlined, path: '/statistics', title: 'Статистика' },
                { Icon: SettingOutlined, path: '/settings', title: 'Настройки' },
            ],
            admin: [
                { Icon: BarChartOutlined, path: '/assets', title: 'Активы' },
                { Icon: BulbOutlined, path: '/orders', title: 'Ордера' },
                { Icon: ShopOutlined, path: '/shops', title: 'Магазины' },
                { Icon: SmileOutlined, path: '/traders', title: 'Трейдеры' },
                { Icon: SaveOutlined, path: '/archive', title: 'Архив' },
                { Icon: MehOutlined, path: '/agents', title: 'Агенты' },
                { Icon: AreaChartOutlined, path: '/statistics', title: 'Статистика' },
                { Icon: MergeOutlined, path: '/users', title: 'Пользователи' },
                { Icon: SettingOutlined, path: '/settings', title: 'Настройки' },
            ],
            support: [
                { Icon: BarChartOutlined, path: '/assets', title: 'Активы' },
                { Icon: BulbOutlined, path: '/orders', title: 'Ордера' },
            ],
            agent: [
                { Icon: BarChartOutlined, path: '/assets', title: 'Активы' },
                { Icon: AreaChartOutlined, path: '/statistics', title: 'Статистика' },
                { Icon: PlusSquareOutlined, path: '/invite', title: 'Пригласить' },
                { Icon: SettingOutlined, path: '/settings', title: 'Настройки' },
            ],
            analyst: [
                { Icon: BarChartOutlined, path: '/assets', title: 'Активы' },
                { Icon: AreaChartOutlined, path: '/statistics', title: 'Статистика' },
            ]
        };
        
        function getButtonsForRole(role) {
            const buttons = [...commonButtons, ...(roleSpecificButtons[role] || [])];
            return buttons.map(({ path, ...props }) => <SidebarButton {...props} active={isActive(path)} onClick={() => redirect(path)} key={path} />);
        }
        
        return {
            trader: <>{getButtonsForRole('trader')}</>,
            merchant: <>{getButtonsForRole('merchant')}</>,
            admin: <>{getButtonsForRole('admin')}</>,
            support: <>{getButtonsForRole('support')}</>,
            agent: <>{getButtonsForRole('agent')}</>,
            analyst: <>{getButtonsForRole('analyst')}</>
        };
    }

    const Buttons = useMemo(getButtons, [location.pathname]);

    return (
        <SidebarContainer isVisible={isVisible}>
            <MainButtonsWithLogo>
                <MainButtons>
                    <LogoIcon style={{ fontSize: "148px", marginBottom: "10px" }} />
                    {Buttons[user.role]}
                </MainButtons>
            </MainButtonsWithLogo>
        </SidebarContainer>
    );
};

function SidebarButton({onClick, active, title, ...props}) {
    return <Button onClick={onClick} active={active}>
        <Hbox gap="10px">
            <Icon icon={props.Icon} />
            <Text weight={active ? "bold" : "default"}>{title}</Text>
        </Hbox>
    </Button>
}

export default Sidebar;
