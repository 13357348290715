import Vbox from "@shared/ui/layouts/Vbox";
import GlassCard from "@shared/ui/GlassCard";
import Info from "./Info";
import Select from "@shared/ui/Select";
import RangePicker from "@shared/ui/RangePicker";
import Table from "@shared/ui/Table";
import Hbox from "@shared/ui/layouts/Hbox";
import Text from "@shared/ui/Text";
import {TwoColumns, ThreeColumns} from "@shared/ui/containers/ListContainers";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { useNavigate } from "react-router-dom";


const requisites_items = [
    { currency: "RUB", method: "Card to Card", bank: "Sberbank", requisite: "3443 2342 3243 2134", fio: "Руслан Николаевич Попков", hub: "Hub1", trader: "trader1", time_active: "22 часа, 23 минуты", total_volume: "243 324 USDT", capacity: "500 000 RUB" },
    { currency: "USD", method: "Wire Transfer", bank: "Chase", requisite: "1234 5678 9101 1121", fio: "John Doe", hub: "Hub2", trader: "trader2", time_active: "12 часов, 10 минут", total_volume: "123 000 USDT", capacity: "300 000 USD" },
    { currency: "EUR", method: "SEPA", bank: "Deutsche Bank", requisite: "9876 5432 1098 7654", fio: "Hans Müller", hub: "Hub3", trader: "trader3", time_active: "15 часов, 45 минут", total_volume: "150 000 USDT", capacity: "400 000 EUR" },
    { currency: "RUB", method: "Card to Card", bank: "VTB", requisite: "2233 4455 6677 8899", fio: "Анна Сергеевна Смирнова", hub: "Hub4", trader: "trader4", time_active: "8 часов, 30 минут", total_volume: "200 000 USDT", capacity: "600 000 RUB" },
    { currency: "USD", method: "Cash Pickup", bank: "Wells Fargo", requisite: "4455 6677 8899 0011", fio: "Jane Smith", hub: "Hub5", trader: "trader5", time_active: "5 часов, 20 минут", total_volume: "180 000 USDT", capacity: "250 000 USD" },
    { currency: "RUB", method: "Online Transfer", bank: "Alfa-Bank", requisite: "3344 5566 7788 9900", fio: "Иван Алексеевич Петров", hub: "Hub6", trader: "trader6", time_active: "18 часов, 50 минут", total_volume: "220 000 USDT", capacity: "450 000 RUB" },
    { currency: "EUR", method: "SEPA", bank: "BNP Paribas", requisite: "5566 7788 9900 1122", fio: "Marie Dubois", hub: "Hub7", trader: "trader7", time_active: "25 часов, 10 минут", total_volume: "170 000 USDT", capacity: "380 000 EUR" },
    { currency: "USD", method: "Wire Transfer", bank: "Bank of America", requisite: "7788 9900 1122 3344", fio: "Michael Johnson", hub: "Hub8", trader: "trader8", time_active: "30 часов, 5 минут", total_volume: "190 000 USDT", capacity: "310 000 USD" },
    { currency: "RUB", method: "Card to Card", bank: "Tinkoff", requisite: "8899 0011 2233 4455", fio: "Светлана Игоревна Михайлова", hub: "Hub9", trader: "trader9", time_active: "20 часов, 40 минут", total_volume: "240 000 USDT", capacity: "550 000 RUB" },
    { currency: "USD", method: "Cash Pickup", bank: "CitiBank", requisite: "9900 1122 3344 5566", fio: "Chris Evans", hub: "Hub10", trader: "trader10", time_active: "10 часов, 15 минут", total_volume: "210 000 USDT", capacity: "270 000 USD" }
];

const errors_columns = [
    { title: "Дата возникновения ошибки", dataIndex: "_", render: () => "24.10.2025 23:55" },
    { title: "Причина ошибки", dataIndex: "reason" },
    { title: "Валюта", dataIndex: "currency" },
    { title: "Метод", dataIndex: "method" },
    { title: "Банк", dataIndex: "bank" },
    { title: "Реквизит", dataIndex: "requisite" },
    { title: "ФИО", dataIndex: "fio" },
    { title: "Трейдер", dataIndex: "trader" },
]

const errors_items = [
    { reason: "Не указаны реквизиты", currency: "RUB", method: "SBP", bank: "Sberbank", requisite: "4324 2343 2343 2344", fio: "Сусланов Михаил Петрович", trader: "trader1" },
    { reason: "Ошибка в реквизитах", currency: "USD", method: "Wire Transfer", bank: "Chase", requisite: "1234 5678 9101 1121", fio: "John Doe", trader: "trader2" },
    { reason: "Недостаточно средств", currency: "EUR", method: "SEPA", bank: "Deutsche Bank", requisite: "9876 5432 1098 7654", fio: "Hans Müller", trader: "trader3" },
    { reason: "Неактивный банк", currency: "RUB", method: "SBP", bank: "VTB", requisite: "2233 4455 6677 8899", fio: "Иванов Сергей Александрович", trader: "trader4" },
    { reason: "Ошибка идентификации", currency: "USD", method: "Cash Pickup", bank: "Wells Fargo", requisite: "4455 6677 8899 0011", fio: "Jane Smith", trader: "trader5" },
    { reason: "Реквизиты устарели", currency: "RUB", method: "Online Transfer", bank: "Alfa-Bank", requisite: "3344 5566 7788 9900", fio: "Петров Иван Алексеевич", trader: "trader6" },
    { reason: "Банк отклонил операцию", currency: "EUR", method: "SEPA", bank: "BNP Paribas", requisite: "5566 7788 9900 1122", fio: "Marie Dubois", trader: "trader7" },
    { reason: "Ограничения на перевод", currency: "USD", method: "Wire Transfer", bank: "Bank of America", requisite: "7788 9900 1122 3344", fio: "Michael Johnson", trader: "trader8" },
    { reason: "Не найден хаб", currency: "RUB", method: "SBP", bank: "Tinkoff", requisite: "8899 0011 2233 4455", fio: "Михайлова Светлана Игоревна", trader: "trader9" },
    { reason: "Проблемы с валютой", currency: "USD", method: "Cash Pickup", bank: "CitiBank", requisite: "9900 1122 3344 5566", fio: "Chris Evans", trader: "trader10" }
];


const Automatic = () => {
    const navigate = useNavigate();
    const requisites_columns = [
        { title: "Валюта", dataIndex: "currency" },
        { title: "Метод", dataIndex: "method" },
        { title: "Банк", dataIndex: "bank" },
        { title: "Реквизит", dataIndex: "requisite" },
        { title: "ФИО", dataIndex: "fio" },
        { title: "Хаб", dataIndex: "hub" },
        { title: "Трейдер", dataIndex: "trader" },
        { title: "Конверсия", dataIndex: "_", render: () => "70%" },
        { title: "Закрыто автоматикой", dataIndex: "_", render: () => "256 шт." },
        { title: "Закрыто вручную", dataIndex: "_", render: () => "234 шт." },
        { title: "", dataIndex: "_", width: "1px", render: () => <SimpleButton onClick={() => navigate("/requisite/dwadawdwadawdwa/errors")}>Подробнее</SimpleButton> }
    ]

    return <Vbox gap="10px">
        <ThreeColumns>
            <GlassCard>
                <Hbox gap="10px">
                    <RangePicker label="Период" />
                    <Select items={[]} label="Банк" />
                    <Select items={[]} label="Метод" />
                </Hbox>
                <Hbox>
                    <Text size="subheader">Кол-во ошибок - 21 шт.</Text>
                </Hbox>
                <Info title="Конверсия" value="70%" />
                <Info title="Закрыто автоматикой" value="25 шт." />
                <Info title="Закрыто вручную в успех" value="25 шт." />
                <Info title="Среднее время закрытия ордера" value="5 минут" />
            </GlassCard>
        </ThreeColumns>
        
        <GlassCard>
            <Text weight="bold">Реквизиты</Text>
            <Hbox customJust="start" gap="10px">
                <Select items={[]} label="Валюта" />
                <Select items={[]} label="Метод" />
                <Select items={[]} label="Банк" />
                <Select items={[]} label="Реквизит" />
            </Hbox>
            <Table 
                dataSource={requisites_items}
                columns={requisites_columns}
            />
        </GlassCard>
        <GlassCard>
            <Text weight="bold">Ошибки автоматики</Text>
            <Hbox customJust="start" gap="10px">
                <Select items={[]} label="Валюта" />
                <Select items={[]} label="Метод" />
                <Select items={[]} label="Банк" />
                <Select items={[]} label="Реквизит" />
            </Hbox>
            <Table 
                dataSource={errors_items}
                columns={errors_columns}
            />
        </GlassCard>
    </Vbox>
}

export default Automatic;