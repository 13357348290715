import Switch from "@shared/ui/Switch";
import { useState } from "react";
import switchPHubApi from "@shared/api/services/admin/phubs/switchPHubApi";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";


const SwitchPHubStatus = ({defaultValue, onSuccess, phub_id}) => {
    const [loading, setLoading] = useState(false);

    return <Switch value={defaultValue} onChange={switchStatus} loading={loading} />

    async function switchStatus() {
        setLoading(true);
        await handleAsyncNotification(async () => {
            await switchPHubApi(phub_id);
            onSuccess();
        }, {
            successText: "Статус изменен",
            errorText: "Ошибка изменения статуса",
            loadingText: "Изменение статуса"
        });
        setLoading(false);
    }
}

export default SwitchPHubStatus;