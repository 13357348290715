import PageWrapper from "@shared/ui/layouts/PageWrapper";
import getPHubInfoApi from "@shared/api/services/admin/phubs/getPHubInfoApi";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Vbox from "@shared/ui/layouts/Vbox";
import Text from "@shared/ui/Text";
import Table from "@shared/ui/Table";
import Hbox from "@shared/ui/layouts/Hbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import getPhubsRequestsApi from "@shared/api/services/trader/phubsRequests/getPhubsRequestsApi";
import Pagination from "@shared/ui/Pagination";
import {useModal} from "@shared/ModalContext";
import CreateRequestModal from "./elements/CreateRequestModal";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import GlassCard from "@shared/ui/GlassCard";
import CopyableText from "@shared/ui/CopyableText";
import RequestSwitcher from "@features/RequestSwitcher";
import switchPhubRequestApi from "@shared/api/services/phub_requests/switchPhubRequestApi";
import DeletePHubRequestModal from "./elements/DeleteRequestModal";


const PHubsRequests = () => {
    const {phub_id} = useParams();
    const [dataLoading, setDataLoading] = useState(true);
    const [phubInfo, setPhubInfo] = useState({});
    const [requests, setRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [requestsLoading, setRequestsLoading] = useState(false);
    const {openModal} = useModal();
    const navigate = useNavigate();

    useEffect(fetchHubInfo, []);
    useEffect(fetchPhubsRequests, []);

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/payouthubs")}}>Просмотр Payout-Hubs</a> }
    ]

    const columns = useMemo(getColumns, [])

    return <PageWrapper breadcrumbs={breadcrumbs} loading={dataLoading || requestsLoading} title={`Вывод по Payout-Hub "${phubInfo.name}"`}>
        <Hbox customJust="end"><SimpleButton icon={<PlusOutlined />} main onClick={openCreateRequestModal}>Создать заявку</SimpleButton></Hbox>
        <GlassCard>
            <Text weight="bold">Описание Payout-Hub</Text>
            <Text>{phubInfo.description}</Text>
        </GlassCard>
        <GlassCard>
            <Text weight="bold">Список заявок</Text>
            <Vbox gap="10px">
                <Table columns={columns} dataSource={requests} />
                <Pagination total={totalPages} onChange={setCurrentPage} value={currentPage} />
            </Vbox>
        </GlassCard>
    </PageWrapper>

    function openCreateRequestModal() {
        openModal(<CreateRequestModal onSuccess={fetchPhubsRequests} phub_id={phub_id} />, "Создание заявки")
    }

    function openDeleteRequestModal(request_id) {
        openModal(<DeletePHubRequestModal onSuccess={fetchPhubsRequests} request_id={request_id} />, "Создание заявки")
    }

    function fetchHubInfo() {
        (async () => {
            setDataLoading(true);

            try {
                const phubInfo = await getPHubInfoApi(phub_id);

                setPhubInfo(phubInfo);
            } catch(e) {
                console.log(e);
            } finally {
                setDataLoading(false);
            }
        })();
    }

    function fetchPhubsRequests() {
        (async () => {
            setRequestsLoading(true);

            try {
                const {data, total_pages} = await getPhubsRequestsApi({phub_id, page: currentPage});

                setRequests(data);
                setTotalPages(total_pages);
            } catch(e) {
                console.log(e);
            } finally {
                setRequestsLoading(false);
            }
        })();
    }

    function getColumns() {
        return [
            { title: "ID", dataIndex: "id", render: (value) => <CopyableText text={value} />, width: "1px", fixed: "left" },
            { title: "Нереализовано", dataIndex: "amount_left" },
            { title: "Реализовано", dataIndex: "amount_implemented" },
            { title: "Объем", dataIndex: "amount_sets" },
            { title: "Статус (Админ)", dataIndex: "admin_enabled", render: (value) => value ? 'Включено' : 'Выключено' },
            { title: "Статус (Трейдер)", dataIndex: "user_enabled", render: (value, {id}) => <RequestSwitcher defaultValue={value} request={async () => {await switchPhubRequestApi(id)}} /> },
            { title: "", dataIndex: "delete_action", render: (_, {id}) => <SimpleButton icon={<DeleteOutlined />} onClick={() => {openDeleteRequestModal(id)}} />, width: '1px' }
        ];
    }
}

export default PHubsRequests;