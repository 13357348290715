import React from "react";
import Text from "@shared/ui/Text";
import Hbox from "@shared/ui/layouts/Hbox";
import { LeftMessage, RightMessage } from "./styles";


const Message = ({ type, message, time }) => {
    const Component = type === "right" ? RightMessage : LeftMessage;
  
    return (
      <Hbox
        alignItems="end"
        gap="10px"
        customJust={type === "right" ? "end" : "start"}
      >
        {type === "right" && <Text>{time}</Text>}
        <Component>
          <Text color={type === "right" ? "white" : undefined}>{message}</Text>
        </Component>
        {type === "left" && <Text>{time}</Text>}
      </Hbox>
    );
  };

  export default Message;