import styled from "styled-components";

export const FiveColumns = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;

    @media(max-width: ${props => props.theme.mediaSizes.large}) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media(max-width: ${props => props.theme.mediaSizes.medium}) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media(max-width: ${props => props.theme.mediaSizes.small}) {
        grid-template-columns: 1fr;
    }
`

export const ThreeColumns = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    @media(max-width: ${props => props.theme.mediaSizes.large}) {
        grid-template-columns: 1fr 1fr;
    }

    @media(max-width: ${props => props.theme.mediaSizes.small}) {
        grid-template-columns: 1fr;
    }
`

export const TwoColumns = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    @media(max-width: ${props => props.theme.mediaSizes.medium}) {
        grid-template-columns: 1fr;
    }
`