import React, { useState, useEffect } from 'react';
import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import makeRequest from "@shared/api/makeRequest";
import Select from '@shared/ui/Select'
import RangePicker from "@shared/ui/RangePicker";
import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import Text from "@shared/ui/Text";
import dayjs from "dayjs";


const paymentTypes = [{value: "payin", label: "PayIn"}, {value: "payout", label: "PayOut"}];
const currencies = [{value: "RUB", label: "Российский Рубль"}]

const Statistics = () => {
    const [shops, setShops] = useState([]);
    const [traders, setTraders] = useState([]);
    const [banks, setBanks] = useState([]);
    const [statistics, setStatistics] = useState(null);
    const [start_time, setDateFrom] = useState(dayjs().subtract(7, "day"));
    const [end_time, setDateTo] = useState(dayjs());
    const [paymenttype, setPaymentType] = useState("")
    const [currency, setCurrency] = useState("")
    const [bank_id, setBank] = useState("")
    const [loading, setLoading] = useState(true);
    const [trader_id, setTrader] = useState("")
    const [shop_id, setShop] = useState("")
    

    useEffect(fetchData, []);
    useEffect(fetchStatistics, [start_time, end_time, paymenttype, currency, bank_id, trader_id, shop_id]);
    const renderStatisticsItem = (label, value) => (
        <>
            <Text>{label}</Text>
            <Text size="small">{value}</Text>
        </>
    );

    return (
        <PageWrapper loading={loading} title="Статистика">
            <GlassCard>
                <Text weight="bold">Основные показатели</Text>
                <ElementCard>
                    <VBox gap="30px">
                        <HBox wrap gap="10px">  
                            <div>
                                <HBox customJust="start" wrap gap="10px">
                                    <Select
                                        label="Метод оплаты"
                                        options={[{ label: "Все способы", value: "" }, ...paymentTypes]}
                                        value={paymenttype}
                                        showSearch
                                        onChange={setPaymentType}
                                    />
                                    <Select
                                        label="Магазин"
                                        options={[{ label: "Все магазины", value: "" }, ...shops]}
                                        value={shop_id}
                                        showSearch
                                        onChange={setShop}
                                    />
                                    <Select
                                        label="Трейдер"
                                        options={[{ label: "Все трейдеры", value: "" }, ...traders]}
                                        value={trader_id}
                                        showSearch
                                        onChange={setTrader}
                                    />
                                    <Select
                                        label="Банк"
                                        options={[{ label: "Все банки", value: "" }, ...banks]}
                                        value={bank_id}
                                        showSearch
                                        onChange={setBank}
                                    />
                                    <Select
                                        label="Валюта"
                                        options={[{ label: "Все валюты", value: "" }, ...currencies]}
                                        value={currency}
                                        showSearch
                                        onChange={setCurrency}
                                    />
                                </HBox>
                            </div>                  
                                
                            <div><RangePicker startDate={start_time} endDate={end_time} label="Период" onChange={(startDate, endDate) => {setDateFrom(startDate); setDateTo(endDate)}} /></div>
                        </HBox>
                        <HBox>
                            {statistics && (
                                <VBox gap="10px" width="100%">
                                    {paymenttype === 'payin' && renderStatisticsItem('Принятый объём', statistics.total_received)}
                                    {paymenttype === 'payout' && renderStatisticsItem('Отправленный объём', statistics.total_sent)}
                                    {renderStatisticsItem('Количество сделок', statistics.total_transactions)}
                                    {renderStatisticsItem('Успешные сделки', statistics.successful_transactions)}
                                    {renderStatisticsItem('Конверсия', `${statistics.conversion_rate}%`)}
                                    {renderStatisticsItem('Средний чек', statistics.average_check)}
                                </VBox>)}
                        </HBox>
                    </VBox> 
                </ElementCard>
            </GlassCard>
            
        </PageWrapper>
        
    );
    function fetchStatistics() {
        (async () => {
            try {
                const filteredParams = {
                    start_date: formatDate(start_time),
                    end_date: formatDate(end_time) ,
                    paymenttype,
                    currency,
                    bank_id,
                    trader_id,
                    shop_id
                };
    
                // Удаление пустых или неинициализированных параметров
                const validParams = Object.fromEntries(
                    Object.entries(filteredParams).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
                );
                const queryParams = new URLSearchParams(validParams).toString();
                const data = await makeRequest(`/api/v1/core/statistics/?${queryParams}`, { method: "GET" });

                setStatistics(data);
            } catch (error) {
                console.error('Ошибка при загрузке статистики:', error);
            }
        })();
    };

    function fetchData() {
        setLoading(true);
        fetchShops();
        fetchTraders();
        fetchBanks();
        setLoading(false);
    }

    function fetchShops() {
        (async () => {
            try {
                const response = await makeRequest("/api/v1/core/shop/", { method: "GET" });
                const data = response;  // Преобразование ответа в JSON
                setShops(data.data.map(({id, name}) => ({value: id, label: name})));
            } catch (error) {
                console.error('Ошибка при загрузке магазинов:', error);
            }
        })();
    };

    function fetchBanks() {
        (async () => {
            {
                try {
                    const response = await makeRequest("/api/v1/core/banks/list/all", { method: "GET" });
                    const data = response;  // Преобразование ответа в JSON
                    setBanks(data.map(({id, name}) => ({value: id, label: name})));
                } catch (error) {
                    console.error('Ошибка при загрузке банков:', error);
                }
            };
        })();
    }

    function fetchTraders() {
        (async () => {
            try {
                const response = await makeRequest('/api/v1/core/users/?role=trader', { method: "GET" });
                const data = response;  // Преобразование ответа в JSON
                setTraders(data.users.map(({id, username}) => ({value: id, label: username})));  // Предполагаем, что users находится внутри data
            } catch (error) {
                console.error('Ошибка при загрузке трейдеров:', error);
            }
        })();
    };

    function formatDate(dayjsDate) {
        if(!dayjsDate) return "";
        const date = dayjsDate.toDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        console.log(`${year}-${month}-${day}`)
        return `${year}-${month}-${day}`;
    };
};


export default Statistics;
