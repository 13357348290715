import React, { useState, useEffect } from 'react';
import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import styled from 'styled-components';
import Select from "@shared/ui/Select";
import SimpleButton from '@shared/ui/buttons/SimpleBtn';
import SimpleInput from '@shared/ui/Simpleinput';
import Text from "@shared/ui/Text";
import handleAsyncNotification from '@shared/helpers/handleAsyncNotification';
import PageWrapper from '@shared/ui/layouts/PageWrapper'
import makeRequest from '@shared/api/makeRequest'
import GlassCard from "@shared/ui/GlassCard";
import { useNavigate } from 'react-router-dom';
import Label from "@shared/ui/Label";
import { Controller, useForm } from 'react-hook-form';


const ShopCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border: 1px solid ${props => props.theme.border};
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.theme.secondary};
    width: 100%;
    gap: 15px;
    margin-bottom: 10px;
`;

const ScrollableContainer = styled.div`
    max-height: 400px;
    overflow-y: auto;
    padding-right: 8px;
    margin-bottom: 20px;
    border-radius: 8px;
`;

const Withdrawl = () => {
    const [chosenShops, setChosenShops] = useState([]);
    const [shopWithdrawalAmounts, setShopWithdrawalAmounts] = useState({});
    const [shops, setShops] = useState([]);
    const [shopAmounts, setShopAmounts] = useState({});
    const navigate = useNavigate()
    const blockChainFee = 2;
    
    const get_balance = () => {
        (async () => {
            try {
                const response = await makeRequest(`/api/v1/wallets/balance/shops`, {
                    method: 'GET',
                    credentials: 'include'
                });
                const data = response;
                const shopsWithBalance = data.data
                    .filter(({ total_balance }) => total_balance > 0)
                    .map(({ name, shop_id, total_balance }) => ({
                        value: shop_id,
                        label: name,
                        balance: total_balance || 0
                    }));
                setShops(shopsWithBalance);
            } catch (error) {
                console.error("Ошибка при загрузке баланса магазинов:", error);
            }
        })();        
    };

    useEffect(get_balance, []);

    const selectedShopDetails = chosenShops.map(shopId => 
        shops.find(shop => shop.value === shopId)
    ).filter(Boolean);

    // Функция для расчета общего баланса только для выбранных магазинов
    const calculateSelectedShopsBalance = () => {
        return selectedShopDetails.reduce((total, shop) => total + (shop.balance || 0), 0);
    };

    const handleAmountChange = (shopId, amount) => {
        setShopWithdrawalAmounts(prev => ({
            ...prev,
            [shopId]: amount
        }));
        setShopAmounts(calculateShopAmounts());
    };
    const calculateShopAmounts = () => {
        return Object.keys(shopWithdrawalAmounts).reduce((acc, shopId) => {
            const amount = parseFloat(shopWithdrawalAmounts[shopId]) || 0;
            if (amount > 0) {
                acc[shopId] = amount;
            }
            return acc;
        }, {});
    };
    const calculateTotalAmounts = () => {
        const totalAmount = Object.values(shopWithdrawalAmounts)
            .reduce((sum, amount) => sum + (parseFloat(amount) || 0), 0);
        const amountToReceive = Math.max(totalAmount - blockChainFee, 0);
        return { totalAmount, amountToReceive };
    };

    const { totalAmount, amountToReceive } = calculateTotalAmounts();

    const createOrder = async ({address}) => {
        await handleAsyncNotification(async () => {
            await makeRequest("/api/v1/wallets/withdrawl/withdrawals", {
                body: { 
                    amount: totalAmount, 
                    address, 
                    shops: calculateShopAmounts()
                },
            });
        }, {
            errorText: 'Недостаточный баланс',
            successText: 'Заявка на вывод успешно создана',
            loadingText: 'Заявка формируется'
        });
    };

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/assets")}}>Активы</a> },
    ]

    const { handleSubmit, control, formState: { errors } } = useForm();

    return (
        <PageWrapper breadcrumbs={breadcrumbs} title="Вывод">
            <GlassCard width="fit-content">
                <HBox gap='40px'>
                    <Text>
                        Выберите магазины для вывода
                    </Text>
                    <SimpleButton onClick={() => navigate('history')}>История</SimpleButton>
                </HBox>
                <Select label="Магазины" options={shops} onChange={setChosenShops} mode="multiple" />
                <VBox gap="8px">
                    {selectedShopDetails.length > 0 && (
                        <ScrollableContainer>
                            {/* Используем calculateSelectedShopsBalance для отображения общего баланса выбранных магазинов */}
                            <Text size="subheader">Баланс выбранных магазинов: <Text size="subheader" weight="bold">{calculateSelectedShopsBalance()}</Text>USDT</Text>
                            {selectedShopDetails.map(shop => (
                                <ShopCard key={shop.value}>
                                    <HBox gap="10px">
                                        <Info
                                            title="Название"
                                            value={shop.label}
                                            width='150px'
                                        />
                                        <Info 
                                            title="Баланс"
                                            value={`${shop.balance} USDT`}
                                            width='150px'
                                        />
                                    </HBox>
                                    <SimpleInput
                                        number
                                        placeholder="Сумма для вывода"
                                        value={shopWithdrawalAmounts[shop.value] || ''}
                                        onChange={(e) => handleAmountChange(shop.value, e.target.value)}
                                        style={{ width: '300px' }}
                                    />
                                </ShopCard>
                            ))}
                        </ScrollableContainer>
                    )}
                    <form onSubmit={handleSubmit(createOrder)}>
                        <VBox gap='20px'>
                            <Controller
                                    name="address"
                                    control={control}
                                    rules={{
                                        required: "Обязательное поле",
                                        validate: {
                                            isCorrectLength: (value) => value.length === 34 || `Должно быть 34 символа. Сейчас ${value.length}`,
                                            startsWithT: (value) => value.startsWith("T") || "Должно начинаться с буквы 't'",
                                        },
                                    }}
                                    render={({ field }) => (
                                        <SimpleInput 
                                            label="Wallet Address" 
                                            placeholder="Введите кошелек" 
                                            error={errors.address?.message}
                                            {...field}
                                        />
                                    )}
                                />

                            <HBox gap="30px">
                                <Info 
                                    title="Сумма"
                                    value={`${totalAmount} USDT`}
                                    width='150px'
                                />
                                <Info 
                                    title="Комиссия блокчейна"
                                    value={`${blockChainFee} USDT`}
                                    width='150px'
                                />
                                <Info 
                                    title="Сумма к получению"
                                    value={`${amountToReceive} USDT`}
                                    width='150px'
                                />
                            </HBox>

                            <HBox customJust='unset'>
                                <SimpleButton main type="submit">Подтвердить вывод</SimpleButton>
                            </HBox>
                        </VBox>
                    </form>
                </VBox>
            </GlassCard>
            
        </PageWrapper>
    );
};

function Info({ title, value, width }) {
    return <VBox gap="5px" style={{ width: width }}>
        <Text size="small">{title}</Text>
        <Text>{value || "-"}</Text>
    </VBox>
}

export default Withdrawl;
