import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Select from "@shared/ui/Select";
import Hbox from "@shared/ui/layouts/Hbox";
import Text from "@shared/ui/Text";
import Info from "./Info";
import {TwoColumns} from "@shared/ui/containers/ListContainers";


const Trafic = () => {
    return <GlassCard>
        <Hbox customJust="start" gap="10px">
            <Select items={[]} label="Дата" />
            <Select items={[]} label="Валюта" />
            <Select items={[]} label="Каскад" />
            <Select items={[]} label="Payin / payout" />
            <Select items={[]} label="Активен / неактивен" />
        </Hbox>

        <TwoColumns>
            <ElementCard>
                <Info title="Объем трафика" value="500 000 руб. " />
                <Info title="Количество ордеров" value="500 шт. " />
                <Info title="Количество успешных ордеров" value="500 шт. " />
                <Info title="Количество отмененных ордеров" value="500 шт. " />
            </ElementCard>      
            <ElementCard>
                <Info title="Конверсия автоматики" value="50 %" />
                <Info title="Конверсия в выдачу" value="50 %" />
                <Info title="Конверсия по оплате клиентом" value="50 %" />
                <Info title="Кол-во ошибок" value="50 шт." />   
                <Info title="Скорость выдачи реквизита" value="1 реквизит за 5 минут" /> 
            </ElementCard> 
        </TwoColumns>
    </GlassCard>
}



export default Trafic;