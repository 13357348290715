import styled from "styled-components";


export const HeaderContainer = styled.header`
  min-height: 50px;
  width: 100%; /* Хедер занимает всю ширину */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.background}; /* Фон хедера */
  color: ${(props) => props.theme.color}; /* Цвет текста */
  border-bottom: 1px solid ${(props) => props.theme.border}; /* Нижняя граница */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Легкая тень для эффекта */
`;

export const ExitButton = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 20px 32px;
  cursor: pointer;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const CalendarContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 12px;
  gap: 5px;
  padding: 4px 12px;
  background-color: ${props => props.theme.secondary};
  border: solid 1px ${props => props.theme.border};

  @media(max-width: ${props => props.theme.mediaSizes.small}) {
    display: none;
  }
`;

export const StyledPrice = styled.p`
    display: flex;
    margin-left: 5px;
    font-size: 14px;
    margin: 0;
    color: black;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Username = styled.span`
  margin-left: 10px;
  font-size: 14px;
`;

export const UserButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  margin: 20px 32px;
`;