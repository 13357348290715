import styled from "styled-components";
import Text from "@shared/ui/Text";
import { toast } from "react-toastify";
import SimpleInput from "@shared/ui/Simpleinput";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Hbox from "@shared/ui/layouts/Hbox";
import { message } from "antd";


const ClipboardCopy = ({value}) => {
    return <Hbox gap='10px'>
        <SimpleInput value={value} readOnly />
        <SimpleButton onClick={copyToClipboard}>
            Копировать
        </SimpleButton>
    </Hbox>

    function copyToClipboard() {
        navigator.clipboard.writeText(value);

        message.success("Скопировано в буфер обмена!");
    }
}

export default ClipboardCopy;