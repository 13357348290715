import { Skeleton as AntdSkeleton } from "antd";
import styled from "styled-components";

const StyledSkeletonNode = styled(AntdSkeleton.Node)`
  background: ${props => props.theme.skeleton}; /* Светло-серый фон для контраста с черным */
  border-radius: ${props => props.borderRadius || "4px"}; /* Скругленные углы */
`;

const Skeleton = ({ height, width, customStyle, borderRadius }) => {
  return (
    <StyledSkeletonNode
      active
      borderRadius={borderRadius}
      style={{
        height,
        color: "white",
        width,
        ...customStyle
      }}
    />
  );
};

export default Skeleton;
