import Upload from "@shared/ui/Upload";
import { message } from "antd";
import axios from "axios";


const ReceiptsUploader = ({fileList, setFileList, transaction_id}) => {
    return <Upload
        label="Подтверждающие чеки"
        fileList={fileList}
        transaction_id={transaction_id}
        onChange={setFileList}
        request={customRequest}
    />

    async function customRequest({ file, onSuccess, onError }) {
        const formData = new FormData();
        formData.append('recept', file);

        try {
            const response = await axios.post(process.env.REACT_APP_API_HOST + `/api/v1/orders/transactions/${transaction_id}/receipts/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true
            });
            onSuccess(response.data); // Сообщаем Ant Design об успешной загрузке
            message.success(`${file.name} успешно загружен!`);
        } catch (error) {
            onError(error); // Сообщаем Ant Design об ошибке
            message.error(`Ошибка загрузки ${file.name}.`);
        }
    };
}

export default ReceiptsUploader;