import PageWrapper from "@shared/ui/layouts/PageWrapper"
import Hbox from "@shared/ui/layouts/Hbox";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import getHubRequisitesApi from "@shared/api/services/hub_requisites/getHubRequisitesApi";
import Empty from "@shared/ui/Empty";
import getUserInfoApi from "@shared/api/services/admin/getUserInfoApi";
import Vbox from "@shared/ui/layouts/Vbox";
import getHubDataApi from "@shared/api/services/hubs/getHubDataApi";
import CopyableText from "@shared/ui/CopyableText";
import Text from "@shared/ui/Text";
import getBanksApi from "@shared/api/services/getBanksApi";
import Loading from "@shared/ui/Loading";
import GlassCard from "@shared/ui/GlassCard";
import setRequisiteStatusApi from "@shared/api/services/hub_requisites/setRequisiteStatusApi";
import ElementCard from "@shared/ui/ElementCard";
import RequestSwitcher from "@features/RequestSwitcher";


const Requisites = () => {
    const { hub_id, trader_id } = useParams();
    const [banksLoading, setBanksLoading] = useState(true);
    const [requisites, setRequisites] = useState([]);
    const [requisitesLoading, setRequisitesLoading] = useState(true);
    const [hubNameLoading, setHubNameLoading] = useState(true);
    const [hubData, setHubData] = useState({});
    const [traderUsername, setTradersUsername] = useState("");
    const [banks, setBanks] = useState([]);

    useEffect(fetchBanks, []);
    useEffect(fetchRequisites, [hub_id]);
    useEffect(initRequisitesLoopFetching, []);
    useEffect(fetchHubData, [hub_id]);
    useEffect(fetchTraderUsername, [trader_id]);

    const navigate = useNavigate();

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/traders")}}>Трейдеры</a> },
        { title: <a onClick={() => {navigate(`/traders/${trader_id}`)}}>Трейдер "{traderUsername}"</a> },
        { title: <a onClick={() => {navigate(`/traders/${trader_id}/hubs`)}}>Payin-Hubs трейдера "{traderUsername}"</a> },
    ]

    return <PageWrapper breadcrumbs={breadcrumbs} loading={hubNameLoading || requisitesLoading} title={`Реквизиты Payin-Hub "${hubData.name}"`}>
        <Hbox alignItems="center">
            {banksLoading ? <Loading size="xsmall"/> : <div></div>}
        </Hbox>
        <GlassCard>
                <Text>Описание хаба</Text>
                <ElementCard>
                    {hubData.description}
                </ElementCard>
            </GlassCard>
        <GlassCard>
            <Text weight="bold">Список реквизитов</Text>
            {requisites.length < 1 ?
                <Empty />
                :
                <>
                    {requisites.map(({id, device_id, fio, user_enabled, admin_enabled, bank_id, requisite_number}) => <ElementCard>
                        <Hbox alignItems="center">
                            <Hbox gap="10px">
                                <Info title="ID устройства" value={<CopyableText text={device_id} />}/>
                                <Info title="ФИО" value={fio}/>
                                <Info title="Название банка" value={banks.find(({id}) => id === bank_id)?.name}/>
                                <Info title="Реквизит" value={requisite_number}/>
                                <Info title="Статус (Трейдер)" value={user_enabled ? "Активен" : "Не активен"}/>
                                <Info title="Статус (Админ)" value={<RequestSwitcher onSuccess={fetchRequisites} defaultValue={admin_enabled} request={async () => {await setRequisiteStatusApi(hub_id, id)}} /> }/>
                            </Hbox>
                        </Hbox>
                    </ElementCard>)}
                </>}    
        </GlassCard>
        
    </PageWrapper>

    function fetchRequisites() {
        (async () => {
            if(!hub_id) return;

            setRequisitesLoading(true);

            try {
                const {requisites} = await getHubRequisitesApi(hub_id);

                setRequisites(requisites.data);
            } catch(e) {
                console.log(e);
            } finally {
                setRequisitesLoading(false);
            }
        })();
    }

    function initRequisitesLoopFetching() {
        const interval = setInterval(fetchRequisites, 5000);

        return () => {
            clearInterval(interval);
        };
    }

    function fetchBanks() {
        (async () => {
            try {
                setBanksLoading(true);

                const banks = await getBanksApi();

                setBanks(banks);
            } catch(e) {
                console.log(e);
            } finally {
                setBanksLoading(false);
            }
        })();
    }

    function fetchHubData() {
        (async () => {
            if(!hub_id) return;

            try {
                setHubNameLoading(true);

                const hubData = await getHubDataApi(hub_id);

                setHubData(hubData);
            } catch(e) {
                console.log(e)
            } finally {
                setHubNameLoading(false);
            }
        })();
    }

    function fetchTraderUsername() {
        (async () => {
            try {
                const {username} = await getUserInfoApi(trader_id);

                setTradersUsername(username);
            } catch(e) {
                console.log(e);
            }
        })();
    }
}

function Info({title, value}) {
    return <Vbox gap="5px">
        <Text size="small">{title}</Text>
        <Text>{value || "-"}</Text>
    </Vbox>
}

export default Requisites;