import { Input } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import ErrorLabel from "./ErrorLabel";
import Label from "./Label";

// Глобальные стили для Input.TextArea
const GlobalStyles = createGlobalStyle`
//   .ant-input {
//     background: ${(props) => props.theme.background} !important; /* Темный фон */
//     color: ${(props) => props.theme.color} !important; /* Цвет текста */
//     border: 1px solid ${(props) => props.theme.border} !important; /* Граница */

//     &::placeholder {
//       color: ${(props) => props.theme.label} !important; /* Цвет плейсхолдера */
//       opacity: 1;
//     }

//     &:hover {
//       border-color: ${(props) => props.theme.hover} !important; /* Граница при наведении */
//     }

//     &:focus {
//       box-shadow: 0 0 0 2px ${(props) => props.theme.secondary} !important; /* Подсветка при фокусе */
//       border-color: ${(props) => props.theme.secondary} !important;
//     }
//   }

  .ant-input-status-error {
    border-color: red !important; /* Граница в случае ошибки */
    background: ${(props) => props.theme.background} !important; /* Фон в случае ошибки */
  }
`;

// Стили для TextArea
const StyledTextArea = styled(Input.TextArea)`
  &.ant-input {
    background: ${props => props.theme.background} !important; /* Темный фон */
    color: ${(props) => props.theme.color} !important; /* Цвет текста */
    border: 1px solid ${(props) => props.status === "error" ? "red" : props.theme.border} !important; /* Граница */
    resize: none; /* Отключение ручного изменения размера */

    &::placeholder {
      color: ${(props) => props.theme.label} !important; /* Цвет плейсхолдера */
    }

    &:focus {
      box-shadow: 0 0 0 2px ${(props) => props.theme.border} !important; /* Подсветка при фокусе */
    }
  }
`;

const Textarea = ({ rows = 4, label, error, ...props }) => {
  return (
    <>
      <GlobalStyles />
      <ErrorLabel value={error}>
        <Label value={label}>
          <StyledTextArea
            status={error ? "error" : undefined}
            autoSize={{ minRows: 2, maxRows: 6 }}
            rows={rows}
            {...props}
          />
        </Label>
      </ErrorLabel>
    </>
  );
};

export default Textarea;
