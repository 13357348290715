import { Pagination as AntdPagination } from "antd";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Hbox from "@shared/ui/layouts/Hbox";

// Стили для пагинации
const StyledPagination = styled(AntdPagination)`
  .ant-pagination-item {
    background-color: ${(props) => props.theme.secondary} !important; /* Фон кнопок */
    border: 1px solid ${(props) => props.theme.border} !important; /* Граница кнопок */

    a {
      color: ${(props) => props.theme.label} !important; /* Цвет текста кнопок */
      transition: color 0.3s ease;
    }

    &:hover {
      border-color: ${(props) => props.theme.border} !important; /* Граница при наведении */

      a {
        color: ${(props) => props.theme.color} !important; /* Цвет текста при наведении */
      }
    }
  }

  .ant-pagination-item-active {
    background-color: ${(props) => props.theme.secondary} !important; /* Фон активной кнопки */
    border-color: ${(props) => props.theme.color} !important; /* Граница активной кнопки */

    a {
      color: ${(props) => props.theme.color} !important; /* Цвет текста активной кнопки */
    }
  }

  .ant-pagination-item-link {
    background-color: ${(props) => props.theme.secondary} !important; /* Фон стрелок */
    border-color: ${(props) => props.theme.border} !important; /* Граница стрелок */
    color: ${(props) => props.theme.color} !important; /* Цвет стрелок */

    &:hover {
      border-color: ${(props) => props.theme.hover} !important; /* Граница стрелок при наведении */
      color: ${(props) => props.theme.color} !important; /* Цвет стрелок при наведении */
    }
  }

  .ant-pagination-item-ellipsis {
    color: ${(props) => props.theme.color} !important; /* Цвет кнопок с тремя точками */
  }

  .ant-pagination-item-link-icon {
    color: ${(props) => props.theme.color} !important; /* Цвет иконок стрелок */
  }
`;

const Pagination = ({ total, onChange, value }) => {
  const [isNarrowScreen, setIsNarrowScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 1000);
    };

    // Устанавливаем начальное состояние
    handleResize();

    // Слушаем изменение размера окна
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Hbox customJust="center">
      {total > 1 && (
        <StyledPagination
          showLessItems={isNarrowScreen} // Уменьшаем количество кнопок
          showSizeChanger={false}
          current={value}
          total={total * 10}
          onChange={onChange}
        />
      )}
    </Hbox>
  );
};

export default Pagination;
