import React from 'react';
import { LineChart, Line, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import styled from 'styled-components';

const colors = {
  positive: "#4CAF50",
  negative: "rgb(238, 75, 43)"
};

const Container = styled.div`
  background: ${props => props.theme.background};
  border: solid 1px ${props => props.theme.border};
  border-radius: 10px;
  padding: 10px;
`;

const Graph = ({ data }) => {
  const dynamic = data[0].value > data[data.length - 1].value ? "negative" : "positive";

  return (
    <Container>
      <ResponsiveContainer width="100%" height={150}>
        <LineChart data={data}>
          <CartesianGrid 
            vertical={false} /* Убирает вертикальные линии */
            stroke="#ccc"
          />
          <XAxis 
            dataKey="name"
            tick={{ fontSize: 12, fill: "#666" }} 
            label={{ value: "Дни", position: "insideBottom", offset: -5 }} 
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="value" 
            stroke={colors[dynamic]}
            strokeWidth={2} 
          />
        </LineChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default Graph;
