import makeRequest from "@shared/api/makeRequest";

export default async function({skip, order_id, limit, transactionStatus, card_number, bank, method, amount, date_from, date_to }) {
    return await makeRequest(`/api/v1/orders/transactions/payout?skip=${skip}&limit=${limit}`, {
        body: {
            card_number,
            bank,
            method,
            order_id,
            ...(amount ? {amount} : {}),
            date_from,
            date_to,
            category: transactionStatus,
        }
    });
}