import { FileJpgOutlined, FilePdfOutlined, FileUnknownOutlined } from "@ant-design/icons";
import Icon from "@shared/ui/icons/Icon";
import Text from "@shared/ui/Text";
import formatReadableDate from "@shared/helpers/formateReadableDate";
import styled from "styled-components";


const icons = {
    pdf: FilePdfOutlined,
    jpg: FileJpgOutlined,
}

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column; 
    justify-content: center;
    cursor: pointer;
    border: solid 1px ${props => props.theme.border};
    padding: 10px;
    gap: 10px;
    width: 100%;
    border-radius: 10px;
`

const Title = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
`

const FileLink = ({src, type, name, createdAt}) => {
    const icon = icons[type] || FileUnknownOutlined;

    return <Container onClick={() => {window.open(src)}} gap="10px">
        <Icon style={{fontSize: "50px"}} width="30px" icon={icon} />
        <Title>{name}</Title>
        <Text>{formatReadableDate(createdAt)}</Text>
    </Container>
}

export default FileLink;