import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Text from "@shared/ui/Text";
import Vbox from "@shared/ui/layouts/Vbox";
import Label from "@shared/ui/Label";
import Hbox from "@shared/ui/layouts/Hbox";
import RequestSwitcher from "@features/RequestSwitcher";
import makeRequest from "@shared/api/makeRequest";
import { useEffect, useState } from "react";
import Skeleton from "@shared/ui/Skeleton";


const TogglePayouts = () => {
    const [defaultStatus, setDefaultStatus] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(fetchSurveyStatus, []);

    return <GlassCard>
        <Text weight="bold">Payout трафик</Text>
        <ElementCard>   
            <Vbox>
                <Label value="Статус">
                    <Hbox>
                        {loading ? <Skeleton width="40px" height="20px" /> : <RequestSwitcher request={toggleSwitch} defaultValue={defaultStatus} />}
                    </Hbox>
                </Label>
            </Vbox>
        </ElementCard>
    </GlassCard>

    async function toggleSwitch() {
        await makeRequest("/api/v1/core/users/survey/switch", { method: "GET" })
    }
    
    function fetchSurveyStatus() {
        (async () => {
            try {
                setLoading(true);

                const {status} = await makeRequest("/api/v1/core/users/survey/status", { method: "GET" });

                setDefaultStatus(status);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }
}

export default TogglePayouts;