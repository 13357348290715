import Text from '@shared/ui/Text'
import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';

const InfoModel = () => (
    <VBox gap='30px'>
        <VBox gap='10px'>
            <HBox gap='30px'>
                <Text>Баланс</Text>
                <p style={{ margin: '0' }}>5040</p>
            </HBox>
            <HBox gap='30px'>
                <Text>Страховой Деп</Text>
                <p style={{ margin: '0' }}>2000</p>
            </HBox>
            <HBox gap='30px'>
                <Text>Кол-во активных РекPayOut</Text>
                <p style={{ margin: '0' }}>44</p>
            </HBox>
            <HBox gap='30px' borderBottom='1px solid var(--second_primary)'>
                <Text>Кол-во активных диспутов</Text>
                <p style={{ margin: '0', }}>0</p>
            </HBox>
        </VBox>
        <VBox gap='10px'>
            <HBox gap='30px'>
                <Text>Оборот сегодня</Text>
                <p style={{ margin: '0' }}>60/11</p>
            </HBox>
            <HBox gap='30px'>
                <Text>Оборот средний(сутки)</Text>
                <p style={{ margin: '0' }}>60/11</p>
            </HBox>
            <HBox gap='30px'>
                <Text>Закрыты автоматикой</Text>
                <p style={{ margin: '0' }}>120 (90%)</p>
            </HBox>
            <HBox gap='30px'>
                <Text>Закрыты в ручную</Text>
                <p style={{ margin: '0' }}>12 (10%)</p>
            </HBox>
            <HBox gap='30px'>
                <Text>Конверсия</Text>
                <p style={{ margin: '0' }}>70%</p>
            </HBox>
            <HBox gap='30px'>
                <Text>Диспутов сегодня</Text>
                <p style={{ margin: '0' }}>52</p>
            </HBox>
            <HBox gap='30px'>
                <Text>Ср.скорость обработки диспутов</Text>
                <p style={{ margin: '0' }}>27м</p>
            </HBox>
            <HBox gap='30px'>
                <Text>Активных часов в сутки (Среднее)</Text>
                <p style={{ margin: '0' }}>23.2 ч</p>
            </HBox>
        </VBox>
    </VBox>
)
export default InfoModel;