// src/components/sections/Section1.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Hbox from "@shared/ui/layouts/Hbox";
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import makeRequest from '@shared/api/makeRequest'
import Text from "@shared/ui/Text";
import GlassCard from "@shared/ui/GlassCard";
import InfoCard from '@shared/ui/InfoCard';
import { DollarCircleOutlined, WalletOutlined } from '@ant-design/icons';
import {ThreeColumns} from '@shared/ui/containers/ListContainers';


const Assets = () => {
    const data1 = [
        { name: 'Day 1', value: 1000 },
        { name: 'Day 2', value: 1300 },
        { name: 'Day 3', value: 4000 },
        { name: 'Day 4', value: 1200 },
        { name: 'Day 5', value: 1400 },
        { name: 'Day 6', value: 2000 },
        { name: 'Day 7', value: 1700 },
        { name: 'Day 8', value: 7000 },
    ];
    const navigate = useNavigate()

    const [balance, setBalance] = useState({});
    useEffect(fetchBalance, []);

    const updateInsuranceDeposit = async () => {
        const response = await makeRequest('/api/v1/wallets/balance/insurance_deposit')
        const data = response
    }

    return (
        <PageWrapper title="Активы">
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', gap: '10%'}}>
                <h2 style={{flex: '2'}}></h2>
                <Hbox gap="10px">
                    <SimpleButton icon={<WalletOutlined />} main onClick={() => navigate('/assets/deposit')}>Пополнение</SimpleButton>
                    <SimpleButton icon={<DollarCircleOutlined />} main onClick={() => navigate('/assets/withdrawl')}>Вывод</SimpleButton>
                </Hbox>
            </div>
            <GlassCard>
                <Text weight="bold">Основные балансы</Text>
                <ThreeColumns>
                    <InfoCard data={data1} currency="USDT" title="Баланс" value={balance.total_balance}/>
                    <InfoCard data={data1} currency="USDT" title="Замороженный баланс" value={balance.frozen_balance} />
                    <InfoCard data={data1} currency="USDT" title="Страховой депозит" value={balance.insurance_deposit} />
                </ThreeColumns>
            </GlassCard>            
        </PageWrapper>
    );

    function fetchBalance() {
        (async () => {
            const data = await makeRequest("/api/v1/wallets/balance/", { method: "GET" });
            setBalance(data);
        })();
    }
};

export default Assets;