import React, { useState, useEffect } from 'react';
import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import makeRequest from "@shared/api/makeRequest"
import RangePicker from "@shared/ui/RangePicker";
import Select from '@shared/ui/Select'
import Text from "@shared/ui/Text";
import ElementCard from "@shared/ui/ElementCard";
import Info from "@shared/ui/Info";
import GlassCard from "@shared/ui/GlassCard";
import dayjs from "dayjs"


const Statistics = () => {
    const [shop, setShop] = useState("");
    const [shops, setShops] = useState([]);
    const [currency, setCurrency] = useState("");
    const [paymenttype, setPaymentType] = useState("")
    const [startDate, setStartDate] = useState(dayjs().subtract(7, "day"));
    const [endDate, setEndDate] = useState(dayjs());
    const [statistics, setStatistics] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        get_balance();
    }, []);

    const get_balance = async () => {
        try {
            const response = await makeRequest(`/api/v1/wallets/balance/shops`, {
                method: 'GET',
                credentials: 'include'
            });
            const data = response;
            const shopsWithBalance = data.data.map(({ name, shop_id }) => ({
                value: shop_id,
                label: name,
            }));
            setShops(shopsWithBalance);
        } catch (error) {
            console.error("Ошибка при загрузке баланса магазинов:", error);
        }
    };

    const fetchStatistics = async () => {
        const params = new URLSearchParams({
            paymenttype: paymenttype,
            start_date: formatDate(startDate) ,
            end_date: formatDate(endDate),
            currency: 'RUB'
        });

        if (shop) {
            params.append('shop_id', shop);
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/core/statistics/?${params.toString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error(`Ошибка: ${response.status}`);
            }

            const data = await response.json();
            setStatistics(data);
        } catch (error) {
            console.error('Error fetching statistics:', error);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchStatistics();
        setLoading(false);
    }, [shop, currency, startDate, endDate, paymenttype]);

    return (
        <PageWrapper loading={loading} title="Статистика">
            <GlassCard>
                <Text weight="bold">Основные показатели</Text>
                <ElementCard>
                    <HBox wrap gap='10px'>
                    <div>
                        <HBox wrap customJust="start" gap='10px'>
                            <Select
                                label="Тип ордера"
                                options={[{label: 'Все типы', value: ''}, {label: 'PayIn', value: 'payin'}, {label: 'PayOut', value: 'payout'}]}
                                value={paymenttype}
                                onChange={setPaymentType} // selectedShop — объект { value, label }
                            />
                            <Select
                                label="Магазин"
                                options={[{ label: "Все магазины", value: "" }, ...shops]}
                                value={shop}
                                onChange={setShop} // selectedShop — объект { value, label }
                            />
                            <Select
                                label="Валюта"
                                options={[{ label: "Все валюты", value: "" }, { value: 'RUB', label: "Российский рубль" }]}
                                value={currency}
                                onChange={setCurrency}
                            />
                        </HBox>
                    </div>
                    
                    <div>
                        <RangePicker 
                            label="Период" 
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(startDate, endDate) => {
                                setStartDate(startDate);
                                setEndDate(endDate);
                            }} 
                        />
                    </div>
                </HBox>

                <HBox wrap customJust="start" gap='10px' width='100%'>
                    <Info title="Принятый объем" value={statistics ? `${statistics.total_received}р` : 'Загрузка...'} />
                    <Info title="Количество сделок" value={statistics ? statistics.total_transactions : 'Загрузка...'} />
                    <Info title="Успешные сделки" value={statistics ? statistics.successful_transactions : 'Загрузка...'} />
                    <Info title="Конверсия" value={statistics ? `${statistics.conversion_rate.toFixed(2)}%` : 'Загрузка...'} />
                    <Info title="Средний чек" value={statistics ? `${statistics.average_check.toFixed(2)}р` : 'Загрузка...'} />
                </HBox>

                </ElementCard>
            </GlassCard>
        </PageWrapper>
    );
    function formatDate(dayjsDate) {
        if(!dayjsDate) return "";
        const date = dayjsDate.toDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
};

export default Statistics;
