import { useEffect, useState } from "react";
import getFiatCurrenciesApi from "@shared/api/services/getFiatCurrenciesApi";
import Select from "@shared/ui/Select";
import { Controller } from "react-hook-form";


const CurrencyDropDown = ({control, error}) => {
    const [currencies, setCurrencies] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(fetchCurrencies, []);

    return <Controller
        name="currency_id"
        control={control}
        rules={{ required: "Обязательное поле" }}
        render={({ field }) => (
            <Select
                {...field}
                showSearch
                fullWidth
                options={currencies}
                loading={loading}
                label="Валюта"
                error={error}
            />
        )}
    />

    function fetchCurrencies() {
        (async () => {
            try {
                setLoading(true);

                const currencies = await getFiatCurrenciesApi();

                setCurrencies(currencies.map(normalize));
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();

        function normalize({id, currency}) {
            return {value: id, label: currency}
        }
    }
}

export default CurrencyDropDown;