import { useEffect, useState } from "react";
import getDevicesApi from "@shared/api/services/devices/getDevicesApi";
import Select from "@shared/ui/Select";
import { Controller } from "react-hook-form";


const DeviceDropDown = ({control, error}) => {
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(fetchDevices, []);

    return <Controller
        name="device"
        control={control}
        rules={{ required: "Обязательное поле" }}
        render={({ field }) => (
            <Select
                {...field}
                options={devices}
                fullWidth
                loading={loading}
                showSearch
                label="Устройство"
                error={error}
            />
        )}
    />

    function fetchDevices() {
        (async () => {
            try {
                setLoading(true);

                const devices = await getDevicesApi(1);

                setDevices(devices.map(normalize));
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();

        function normalize({id, name}) {
            return {value: id, label: name}
        }
    }
}

export default DeviceDropDown;