import Text from "@shared/ui/Text";
import Vbox from "@shared/ui/layouts/Vbox";
import styled from "styled-components";

const StyledTitle = styled(Text)`
    color: ${props => props.theme.label};
`

function Info({title, value}) {
    return <Vbox gap="5px">
        <StyledTitle size="small">{title}</StyledTitle>
        <Text>{value !== undefined ? value : "-"}</Text>
    </Vbox>
}

export default Info;