import PageWrapper from "@shared/ui/layouts/PageWrapper"
import Hbox from "@shared/ui/layouts/Hbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { useNavigate, useParams } from "react-router-dom";
import { useModal } from "@shared/ModalContext";
import CreateHubRequisiteModal from "@features/CreateHubRequisiteModal";
import { useEffect, useState } from "react";
import getHubRequisitesApi from "@shared/api/services/hub_requisites/getHubRequisitesApi";
import Empty from "@shared/ui/Empty";
import Vbox from "@shared/ui/layouts/Vbox";
import getHubDataApi from "@shared/api/services/hubs/getHubDataApi";
import Text from "@shared/ui/Text";
import getBanksApi from "@shared/api/services/getBanksApi";
import Loading from "@shared/ui/Loading";
import DeleteRequisiteModal from "@features/DeleteRequisiteModal";
import GlassCard from "@shared/ui/GlassCard";
import setRequisiteStatusApi from "@shared/api/services/hub_requisites/setRequisiteStatusApi";
import ElementCard from "@shared/ui/ElementCard";
import RequestSwitcher from "@features/RequestSwitcher";
import getDevicesApi from "@shared/api/services/devices/getDevicesApi";
import TodoWrapper from "@shared/ui/TodoWrapper";


const PayinHubsRequisites = () => {
    const { hub_id } = useParams();
    const {openModal} = useModal();
    const [banksLoading, setBanksLoading] = useState(true);
    const [requisites, setRequisites] = useState([]);
    const [requisitesLoading, setRequisitesLoading] = useState(true);
    const [hubNameLoading, setHubNameLoading] = useState(true);
    const [devices, setDevices] = useState([]);
    const [hubName, setHubName] = useState("");
    const [banks, setBanks] = useState([]);

    useEffect(fetchBanks, []);
    useEffect(fetchRequisites, [hub_id]);
    useEffect(initRequisitesLoopFetching, []);
    useEffect(fetchDevices, []);
    useEffect(fetchHubName, [hub_id]);

    const navigate = useNavigate();

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/payinhubs")}}>Payin-Hubs</a> }
    ]

    return <PageWrapper breadcrumbs={breadcrumbs} loading={hubNameLoading || requisitesLoading} title={`Реквизиты Payin-Hub "${hubName}"`}>
        <Hbox alignItems="center">
            {banksLoading ? <Loading size="xsmall"/> : <div></div>}
            <SimpleButton main onClick={openCreateHubRequisiteModal}>Создать реквизит</SimpleButton>
        </Hbox>
        <GlassCard>
            <Text weight="bold">Список реквизитов</Text>
            {requisites.length < 1 ?
                <Empty />
                :
                <>
                    {requisites.map(({id, device_id, fio, user_enabled, admin_enabled, bank_id}) => <ElementCard>
                        <Hbox>
                            <div>
                                <Hbox wrap customJust="start" gap="10px">
                                    <div><Info title="Название устройства" value={devices.find(el => el.id === device_id)?.name}/></div>
                                    <div><Info title="ФИО" value={fio}/></div>
                                    <div><Info title="Название банка" value={banks.find(({id}) => id === bank_id)?.name}/></div>
                                    <div><Info title="Статус (Админ)" value={admin_enabled ? "Активен" : "Не активен"}/></div>
                                    <div><Info title="Статус (Трейдер)" value={<RequestSwitcher onSuccess={fetchRequisites} defaultValue={user_enabled} request={async () => {await setRequisiteStatusApi(hub_id, id)}} />} /></div>
                                    <TodoWrapper>
                                        <div><Info title="Реквизит" value="3242342342342"/></div>
                                    </TodoWrapper>
                                </Hbox>
                            </div>
                            <SimpleButton onClick={() => {openDeleteRequisiteModal(hub_id, id)}}>Удалить</SimpleButton>
                        </Hbox>
                    </ElementCard>)}
                </>}    
        </GlassCard>
        
    </PageWrapper>

    function openCreateHubRequisiteModal() {
        openModal(<CreateHubRequisiteModal hub_id={hub_id} onSuccess={fetchRequisites} />, "Создание реквизита");
    }

    function fetchRequisites() {
        (async () => {
            if(!hub_id) return;

            setRequisitesLoading(true);

            try {
                const {requisites} = await getHubRequisitesApi(hub_id);

                setRequisites(requisites.data);
            } catch(e) {
                console.log(e);
            } finally {
                setRequisitesLoading(false);
            }
        })();
    }

    function initRequisitesLoopFetching() {
        const interval = setInterval(fetchRequisites, 5000);

        return () => {
            clearInterval(interval);
        };
    }
    async function openDeleteRequisiteModal(hubId, reqId) {
        openModal(<DeleteRequisiteModal hubId={hubId} reqId={reqId} onSuccess={fetchRequisites} />, 'Удаление реквизита');
    }

    function fetchBanks() {
        (async () => {
            try {
                setBanksLoading(true);

                const banks = await getBanksApi();

                setBanks(banks);
            } catch(e) {
                console.log(e);
            } finally {
                setBanksLoading(false);
            }
        })();
    }

    function fetchHubName() {
        (async () => {
            if(!hub_id) return;

            try {
                setHubNameLoading(true);

                const {name} = await getHubDataApi(hub_id);

                setHubName(name);
            } catch(e) {
                console.log(e)
            } finally {
                setHubNameLoading(false);
            }
        })();
    }

    function fetchDevices() {
        (async () => {
            try {
                const devices = await getDevicesApi();

                setDevices(devices);
            } catch(e) {
                console.log(e);
            }
        })();
    }
}

function Info({title, value}) {
    return <Vbox gap="5px">
        <Text size="small">{title}</Text>
        <Text>{value || "-"}</Text>
    </Vbox>
}

export default PayinHubsRequisites;