import { useForm, Controller } from "react-hook-form";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Hbox from "@shared/ui/layouts/Hbox";
import NameInput from "./elements/NameInput";
import Vbox from "@shared/ui/layouts/Vbox";
import DescriptionInput from "./elements/DescriptionInput";
import PaymentMethodDropDown from "./elements/PaymentMethodDropDown";
import CurrencyDropDown from "./elements/CurrencyDropDown";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import createPHubApi from "@shared/api/services/admin/phubs/createPHubApi";
import { PlusOutlined } from "@ant-design/icons";


const CreatePHubModal = ({trader_id, closeModal, onSuccess}) => {
    const { handleSubmit, formState: {errors}, control } = useForm();

    return <form onSubmit={handleSubmit(createPHub)}>
        <Vbox gap="10px">
            <NameInput control={control} error={errors.name?.message} />
            <DescriptionInput control={control} error={errors.description?.message} />
            <Hbox gap="10px">
                <PaymentMethodDropDown control={control} error={errors.payment_method_id?.message} />
                <CurrencyDropDown control={control} error={errors.currency_id?.message} />
            </Hbox>
            <Hbox customJust="end"><SimpleButton icon={<PlusOutlined />} main type="submit">Создать</SimpleButton></Hbox>
        </Vbox>
    </form>

    async function createPHub({name, description, payment_method_id, currency_id}) {
        closeModal();
        await handleAsyncNotification(async () => {
            await createPHubApi({name, description, payment_method_id, currency_id, trader_id});
            onSuccess();
        }, {
            successText: "Payout-Hub создан",
            errorText: "Ошибка создания Payout-Hub",
            loadingText: "Создание Payout-Hub"
        });
    }
}

export default CreatePHubModal;