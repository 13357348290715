import React, {useEffect, useState} from "react"
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import MiniMenu from "@shared/ui/widgets/MiniChoise"
import { useNavigate } from "react-router-dom";

const fetchUsers = async (traderid, currentPage, inShop, setUsers, setTotalPages) => {
    const page = currentPage || 1;
    
    try {
        // Выбираем правильный endpoint в зависимости от того, ищем ли мы пользователей внутри магазина или вне его
        const endpoint = inShop 
          ? process.env.REACT_APP_API_HOST + `/api/v1/core/traders/${traderid}/agents/` 
          : process.env.REACT_APP_API_HOST + `/api/v1/core/traders/${traderid}/unassociated-agents/`;
  
        const response = await fetch(`${endpoint}?page=${page}`, {
            credentials: "include"
        });
        const data = await response.json();
        const users_data = data.users.map(user => ({
            id: user.id,
            payin: user.payin,
            payout: user.payout,
            name: user.username,
            mail: user.email,
            balance: user.balance,
            frozen: user.frozendeposit,
            btn: <SimpleButton>
                {inShop ? 'Удалить' : 'Добавить'}
            </SimpleButton>
        }));
        console.log(data)
        setTotalPages(data.total_pages);
        setUsers(users_data);
    } catch (error) {
        console.error('Ошибка при загрузке пользователей:', error);
    }
};

async function updateUserRole(action, userId, agentId, username, setCurrent, setAll) {
const url = process.env.REACT_APP_API_HOST +  `/api/v1/core/traders/agent-role`;
const data = {
    action: action,  // 'add' или 'remove'
    trader_id: userId,
    agent_id: agentId,
    name: username
};
console.log(data)
try {
    const response = await fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: "include" // если нужны cookies
    });

    if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
    }
    if (action === 'add') {
        setCurrent(prevAgents => [...prevAgents, { id: agentId, name: username, payin: 0.2, payout: 0.1 }]);
        setAll(prevAgents => prevAgents.filter(agent => agent.id !== agentId));
    } else if (action === 'del') {
        setCurrent(prevAgents => prevAgents.filter(agent => agent.id !== agentId));
        setAll(prevAgents => [...prevAgents, { id: agentId, name: username }]);
    }
    
    
    
    const result = await response.json();
    return result;

} catch (error) {
    console.error('Error:', error);
}
}
const CreateTraders = ({setTraders, openModal, setTotalPages, page}) => {
    

    useEffect(() => {
        const fetchInitialData = async () => {
            await fetchData(); // Выполняем асинхронный запрос
        };

        fetchInitialData();
    }, [page]);

    const navigate = useNavigate();

    const handleToggle = async(traderId, newIsOn, setIsOn) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_HOST + `/api/v1/core/traders/trader/status/${traderId}`, {
                method: 'PATCH',
                credentials: "include"
            });
            console.log('Trader status updated:', response.data);
          } catch (error) {
            console.error('Error updating trader status:', error.response ? error.response.data : error.message);
          }
    }

    const fetchData = async () => {
        const role = 'trader'
        const url = process.env.REACT_APP_API_HOST + `/api/v1/core/users/?role=${role}&page=${page}`
        const response = await fetch(url, {
            credentials: "include"
        })
        if (!response.ok) {
            throw new Error(`Ошибка: ${response.status}`);
        }
        const data = await response.json();
        createDict(data)
    }
    const menuItems = (trader) => [
        { label: 'Настройки', onClick: () => openModal('settings', trader) },
        { label: 'Инфо', onClick: () => openModal('info', trader) },
        { label: 'Payin-Hubs', onClick: () => {navigate(`/traders/${trader.id}/hubs`)} },
        { label: 'Payout-Hubs', onClick: () => {navigate(`/traders/${trader.id}/phubs`)} }
    ];
    const createDict = (data) => {
        const traders = data.users.map(trader => ({
            id: trader.id,
            name: trader.username,
            balance: trader.email,
        }))
        setTraders(traders)
        setTotalPages(data.total_pages)
    }}




export {CreateTraders, updateUserRole, fetchUsers};