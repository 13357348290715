import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useNavigate, useParams } from "react-router-dom";
import getShopApi from "@shared/api/services/shops/getShopApi";
import { useEffect, useState } from "react";
import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Text from "@shared/ui/Text";
import Label from "@shared/ui/Label";
import switchShopStatusApi from "@shared/api/services/shops/switchShopStatusApi";
import Switch from "@shared/ui/Switch";
import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import Configurations from "./elements/Configuration";
import Commisions from "./elements/Comissions";
import Assosiations from "./elements/Assosiations";
import Cascades from "./elements/Cascades"
import ContentSwitcher from "@shared/ui/ContentSwitcher";
import RequestSwitcher from "@features/RequestSwitcher";
import styled from "styled-components";
import {TwoColumns} from "@shared/ui/containers/ListContainers";
import LinkedHubs from "./elements/LinkedHubs";
import { useSearchParams } from "react-router-dom";


const FirstBlock = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    @media(max-width: ${props => props.theme.mediaSizes.medium}) {
        grid-template-columns: 1fr;
    }
`

const SettingsBlock = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    @media(max-width: ${props => props.theme.mediaSizes.small}) {
        grid-template-columns: 1fr;
    }
`


const Shop = () => {
    const navigate = useNavigate();
    const {shop_id} = useParams();
    const [shopData, setShopData] = useState({});
    const [loading, setLoading] = useState(true);
    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/shops")}}>Магазины</a> }
    ]
    const [searchParams, setSearchParams] = useSearchParams();
    const section = searchParams.get("section");

    const title = `Магазин "${shopData.name}"`  
    useEffect(fetchShop, []);

    return <PageWrapper loading={loading} breadcrumbs={breadcrumbs} title={title}>
        <FirstBlock gap="10px">
            <GlassCard>
                <Text weight="bold">Основная информация</Text>
                <ElementCard>
                    <Vbox gap="10px">
                        <Label value="Название"><Text>{shopData.name}</Text></Label>
                        <Label value="URL сайта"><Text>{shopData.website_url}</Text></Label>
                        <Label value="IP сайта"><Text>{shopData.website_ip}</Text></Label>
                        <Label value="API ключ"><Text>{shopData.api_key}</Text></Label>
                    </Vbox>
                </ElementCard>
            </GlassCard>
            <GlassCard>
                <Text weight="bold">Настройки</Text>
                <SettingsBlock gap="10px">
                    <ElementCard>
                        <Text weight="bold">Конфигурации</Text>
                        <Configurations shop={shopData} />
                    </ElementCard>
                    <ElementCard>
                        <Text weight="bold">Комиссии</Text>
                        <Commisions shop={shopData} />
                    </ElementCard>
                </SettingsBlock>
                <Hbox gap="10px">
                    <ElementCard>
                        <Text weight="bold">Статус (Админ)</Text>
                        <Hbox>
                            <RequestSwitcher request={async () => {await switchShopStatusApi(shopData.id)}} defaultValue={shopData.admin_enabled} />
                        </Hbox>
                    </ElementCard>
                    <ElementCard>
                        <Text weight="bold">Статус (Трейдер)</Text>
                        <Hbox>
                            <Switch disabled defaultValue={shopData.user_enabled} />
                        </Hbox>
                    </ElementCard>
                </Hbox>
            </GlassCard>
        </FirstBlock> 
        <GlassCard>
            <Text weight="bold">Управление связями</Text>
            <ContentSwitcher
                activeKey={section || "1"}
                items={[
                    {label: "Payin / Payout Hubs", key: "1", children: <Vbox gap="10px">
                        <Assosiations />
                        <LinkedHubs />
                    </Vbox>},
                    {label: "Каскады", key: "2", children: <Cascades shop_id={shop_id}/>}
                ]}
                onChange={(value) => {setSearchParams({section: value})}}
            />
        </GlassCard>
    </PageWrapper>

    function fetchShop() {
        (async () => {
            try {
                setLoading(true);

                const data = await getShopApi(shop_id);

                setShopData(data);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }
}

export default Shop;