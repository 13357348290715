import makeRequest from "@shared/api/makeRequest";

// Админ настраивает магазин
export default async function({rate_id_payin, rate_id_payout, shop_id, bank_timeout, payment_timeout}) {
    return await makeRequest('/api/v1/orders/shop/settings/set', {
        body: { 
            rate_id_payin,
            rate_id_payout,
            shop_id,
            bank_timeout,
            payment_timeout
        }
    });
}