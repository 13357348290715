import Select from "@shared/ui/Select";
import { useEffect, useState } from "react";
import getBanksApi from "@shared/api/services/getBanksApi";
import { Controller } from "react-hook-form";


const BanksDropDown = ({control, error}) => {
    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(fetchBanks, []);

    return <Controller
        name="bank"
        control={control}
        defaultValue={null}
        render={({ field }) => (
            <Select
                {...field}
                showSearch
                fullWidth
                options={[{ label: "Все банки", value: null }, ...banks]}
                loading={loading}
                label="Банк"
                error={error}
            />
        )}
    />

    function fetchBanks() {
        (async () => {
            try {
                setLoading(true);

                const banks = await getBanksApi();

                setBanks(banks.map(normalize));
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();

        function normalize({id, name}) {
            return {value: id, label: name}
        }
    }
}

export default BanksDropDown;