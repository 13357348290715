import Table from "@shared/ui/Table";
import Vbox from "@shared/ui/layouts/Vbox";
import Select from "@shared/ui/Select";
import Hbox from "@shared/ui/layouts/Hbox";


const columns = [
    { title: "Название магазина", dataIndex: "name" },
    { title: "Статус (активен / неактивен)", dataIndex: "status" },
    { title: "Мерчант", dataIndex: "merchant" },
    { title: "Валюта", dataIndex: "currency" },
    { title: "Объем за вчера", dataIndex: "yesterday_volume" },
    { title: "Предполагаемый объем", dataIndex: "expected_volume" },
]

const items = [
    { name: "shop1", status: "Активен", merchant: "merchant1", currency: "RUB", yesterday_volume: "24 000 RUB", expected_income: "24 000 RUB" },
    { name: "shop2", status: "Не активен", merchant: "merchant2", currency: "USD", yesterday_volume: "30 000 USD", expected_income: "28 000 USD" },
    { name: "shop3", status: "Активен", merchant: "merchant3", currency: "EUR", yesterday_volume: "20 000 EUR", expected_income: "18 000 EUR" },
    { name: "shop4", status: "Активен", merchant: "merchant4", currency: "RUB", yesterday_volume: "15 000 RUB", expected_income: "14 500 RUB" },
    { name: "shop5", status: "Не активен", merchant: "merchant5", currency: "USD", yesterday_volume: "50 000 USD", expected_income: "48 000 USD" },
    { name: "shop6", status: "Активен", merchant: "merchant6", currency: "RUB", yesterday_volume: "10 000 RUB", expected_income: "9 800 RUB" },
    { name: "shop7", status: "Активен", merchant: "merchant7", currency: "EUR", yesterday_volume: "40 000 EUR", expected_income: "38 500 EUR" },
    { name: "shop8", status: "Не активен", merchant: "merchant8", currency: "USD", yesterday_volume: "25 000 USD", expected_income: "24 000 USD" },
    { name: "shop9", status: "Активен", merchant: "merchant9", currency: "RUB", yesterday_volume: "35 000 RUB", expected_income: "34 000 RUB" },
    { name: "shop10", status: "Не активен", merchant: "merchant10", currency: "EUR", yesterday_volume: "12 000 EUR", expected_income: "11 500 EUR" }
];


const Shops = () => {
    return <Vbox gap="10px">
        <Hbox customJust="start" gap="10px">
            <Select items={[]} label="Валюта" />
            <Select items={[]} label="Мерчант" />
        </Hbox>
        <Table
            dataSource={items}
            columns={columns}
        />
    </Vbox>
}

export default Shops;