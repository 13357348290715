import Table from "@shared/ui/Table";
import Pagination from "@shared/ui/Pagination";
import makeRequest from "@shared/api/makeRequest"
import { useEffect, useState } from "react";

const columns = [
    { title: "Ник трейдера", dataIndex: "username" },
    { title: "Баланс", dataIndex: "total_balance" },
    { title: "Доступный баланс", dataIndex: "available_balance" },
    { title: "Замороженный баланс", dataIndex: "frozen_balance" },
    { title: "Страховой депозит", dataIndex: "insurance_deposit"}
];

const Traders = () => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(fetchItems, [currentPage]);

    return <>
        <Table loading={loading} columns={columns} dataSource={items}/>
        <Pagination total={totalPages} onChange={setCurrentPage} />
    </>

    function fetchItems() {
        (async () => {
            try {
                setLoading(true);

                const {data, total_pages} = await makeRequest(`/api/v1/wallets/balance/traders?page=${currentPage}`, {
                    method: 'GET'
                });
    
                setItems(data);
                setTotalPages(total_pages);  // Общее количество страниц
            } catch(e) {
                console.log(e)
            } finally {
                setLoading(false);
            }
        })()
    }
}   

export default Traders;