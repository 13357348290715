import PageWrapper from "@shared/ui/layouts/PageWrapper";
import Table from "@shared/ui/Table";
import { useNavigate } from "react-router-dom";


const errors_columns = [
    { title: "Дата возникновения ошибки", dataIndex: "_", render: () => "24.10.2025 23:55" },
    { title: "Причина ошибки", dataIndex: "reason" },
    { title: "Валюта", dataIndex: "currency" },
    { title: "Метод", dataIndex: "method" },
    { title: "Банк", dataIndex: "bank" },
    { title: "Реквизит", dataIndex: "requisite" },
    { title: "ФИО", dataIndex: "fio" },
    { title: "Трейдер", dataIndex: "trader" },
];

const errors_items = [
    { reason: "Не указаны реквизиты", currency: "RUB", method: "SBP", bank: "Sberbank", requisite: "4324 2343 2343 2344", fio: "Сусланов Михаил Петрович", trader: "trader1" },
    { reason: "Ошибка в реквизитах", currency: "USD", method: "Wire Transfer", bank: "Chase", requisite: "1234 5678 9101 1121", fio: "John Doe", trader: "trader2" },
    { reason: "Недостаточно средств", currency: "EUR", method: "SEPA", bank: "Deutsche Bank", requisite: "9876 5432 1098 7654", fio: "Hans Müller", trader: "trader3" },
    { reason: "Неактивный банк", currency: "RUB", method: "SBP", bank: "VTB", requisite: "2233 4455 6677 8899", fio: "Иванов Сергей Александрович", trader: "trader4" },
    { reason: "Ошибка идентификации", currency: "USD", method: "Cash Pickup", bank: "Wells Fargo", requisite: "4455 6677 8899 0011", fio: "Jane Smith", trader: "trader5" },
    { reason: "Реквизиты устарели", currency: "RUB", method: "Online Transfer", bank: "Alfa-Bank", requisite: "3344 5566 7788 9900", fio: "Петров Иван Алексеевич", trader: "trader6" },
    { reason: "Банк отклонил операцию", currency: "EUR", method: "SEPA", bank: "BNP Paribas", requisite: "5566 7788 9900 1122", fio: "Marie Dubois", trader: "trader7" },
    { reason: "Ограничения на перевод", currency: "USD", method: "Wire Transfer", bank: "Bank of America", requisite: "7788 9900 1122 3344", fio: "Michael Johnson", trader: "trader8" },
    { reason: "Не найден хаб", currency: "RUB", method: "SBP", bank: "Tinkoff", requisite: "8899 0011 2233 4455", fio: "Михайлова Светлана Игоревна", trader: "trader9" },
    { reason: "Проблемы с валютой", currency: "USD", method: "Cash Pickup", bank: "CitiBank", requisite: "9900 1122 3344 5566", fio: "Chris Evans", trader: "trader10" }
];

const RequisiteErrors = () => {
    const navigate = useNavigate();
    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/statistics?section=automatic")}}>Статистика</a> }
    ]

    return <PageWrapper breadcrumbs={breadcrumbs} title="Все ошибки автоматики по этому реквизиту">
        <Table 
            columns={errors_columns}
            dataSource={errors_items}
        />
    </PageWrapper>
}

export default RequisiteErrors;