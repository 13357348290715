import { Controller } from "react-hook-form";
import SimpleInput from "@shared/ui/Simpleinput";


const RequisiteInput = ({control, error}) => {
    return <Controller
        name="requisite_number"
        control={control}
        rules={{ required: "Обязательное поле", minLength: { value: 5, message: "Минимум 5 символов" }, maxLength: { value: 70, message: "Максимум 100 символов" } }}
        render={({ field }) => (
            <SimpleInput
                {...field}
                label="Реквизит"
                error={error}
                placeholder="1234 5678 ..."
            />
        )}
    />
}

export default RequisiteInput;