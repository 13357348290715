import PageWrapper from "@shared/ui/layouts/PageWrapper";
import Table from "@shared/ui/Table";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Switch from "@shared/ui/Switch";

const columns = [
    { title: "Пользователь", dataIndex: "name" },
    { title: "Роль", dataIndex: "role" },
    { title: "Последний раз онлайн", dataIndex: "lastTimeOnline" },
    { title: "", dataIndex: "_", width: "1px", render: () => <SimpleButton>Заблокировать</SimpleButton> },
    { title: "", dataIndex: "_", width: "1px", render: () => <SimpleButton>Установить новый пароль</SimpleButton> },
    { title: "", dataIndex: "_", width: "1px", render: () => <SimpleButton>Включить 2FA</SimpleButton> }
]

const dataSource = [
    { name: "testtrader", role: "trader", lastTimeOnline: "12:23:10 12 января" },
    { name: "testmerchant", role: "merchant", lastTimeOnline: "12:23:10 12 января" },
    { name: "admin", role: "admin", lastTimeOnline: "12:23:10 12 января" },
    { name: "testanalyst", role: "analyst", lastTimeOnline: "12:23:10 12 января" },
    { name: "testsupport", role: "support", lastTimeOnline: "12:23:10 12 января" }
]


const Users = () => {
    return <PageWrapper title="Пользователи">
        <Table columns={columns} dataSource={dataSource} />
    </PageWrapper>
}

export default Users;