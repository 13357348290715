import PageWrapper from "@shared/ui/layouts/PageWrapper";
import makeRequest from "@shared/api/makeRequest";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Vbox from "@shared/ui/layouts/Vbox"
import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Text from "@shared/ui/Text";
import Table from "@shared/ui/Table";
import Button from "@shared/ui/buttons/SimpleBtn";
import {useModal} from "@shared/ModalContext";
import Hbox from "@shared/ui/layouts/Hbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import deleteHubShopAssosiationApi from "@shared/api/services/assosiations/deleteHubShopAssosiationApi";
import Pagination from "@shared/ui/Pagination";
import { namings } from "@app/constants";


const getColumns = (openUnlinkModal) => {
    return [
        { title: "Название", dataIndex: "name" },
        { title: "Турбо", dataIndex: "turbo" , render: (value) => value ? "Включен" : "Не включен" },
        { title: namings.max_hourly_orders, dataIndex: "max_hourly_orders" },
        { title: namings.max_dayly_volume, dataIndex: "max_daily_volume" },
        { title: namings.max_hourly_volume, dataIndex: "max_hourly_volume" },
        { title: "", dataIndex: "_", width: "1px", render: (_, {hub_shop_association_id}) => <Button onClick={() => {openUnlinkModal(hub_shop_association_id)}}>Отвязать</Button>}
    ];
}

const LinkedHubs = () => {
    const {shop_id} = useParams();
    const [linkeds, setLinkeds] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 10;
    const offset = (currentPage - 1) * limit;
    const {openModal} = useModal();
    const columns = useMemo(() => getColumns(openUnlinkModal), []);

    useEffect(fetchLinkedHubs, [limit, offset, shop_id]);

    return <ElementCard>
        <Text weight="bold">Удалить связь</Text>
        <Table
            columns={columns}
            dataSource={linkeds}
        />
        <Pagination 
            total={totalPages}
            value={currentPage}
            onChange={setCurrentPage}
        />
    </ElementCard>

    function fetchLinkedHubs() {
        (async () => {
            try {
                const {data, total} = await makeRequest(`/api/v1/core/hubs/connected/${shop_id}?limit=${limit}&offset=${offset}`, {
                    method: "GET"
                });

                setTotalPages(total);
                setLinkeds(data);
            } catch(e) {
                console.log(e)
            }
        })();
    }

    function openUnlinkModal(assosiation_id) {
        openModal(<UnlinkModal assosiation_id={assosiation_id} onSuccess={fetchLinkedHubs}/>, "Удаление связи");
    }
}

function UnlinkModal({onSuccess, assosiation_id, closeModal}) {
    return <Vbox gap="10px">
        <Text>Подтвердите удаление связи</Text>
        <Hbox>
            <SimpleButton onClick={deleteHubShopAssosiation}>Удалить</SimpleButton>
        </Hbox>
    </Vbox>

    async function deleteHubShopAssosiation() {
        closeModal();
        try {
            await handleAsyncNotification(async () => {
                await deleteHubShopAssosiationApi(assosiation_id);
                onSuccess();
            }, {
                successText: "Связь удалена",
                errorText: "Ошибка удаления связи",
                loadingText: "Удаление связи"
            });
        } catch(e) {
            console.log(e);
        }
    }
}

export default LinkedHubs;