import styled from "styled-components";
import { Timeline as AntdTimeline, Popover, Tree } from "antd";
import Info from "@shared/ui/Info";
import Vbox from "@shared/ui/layouts/Vbox";
import convertUTCToLocal from "@shared/helpers/convertUTCToLocal";
import { transactionStatuses } from "../../app/constants";
import Text from "@shared/ui/Text";
import Hbox from "@shared/ui/layouts/Hbox";


// Стили для Tree через styled-components
const StyledTree = styled(Tree)`
    background-color: ${props => props.theme.secondary} !important; // Ваш цвет фона
    border-radius: 8px; // Опционально: скругление углов
    padding: 10px; // Опционально: отступы
    color: ${props => props.theme.color};
    font-family: Manrope;
`;

const Container = styled.div`
  max-height: 500px;
  overflow-y: scroll;

  /* Стили скролла */
  &::-webkit-scrollbar {
    width: 4px; // Ширина полосы прокрутки
  }

  &::-webkit-scrollbar-track {
    background: #e0e0e0; // Цвет фона трека
    border-radius: 4px; // Скругление трека
  }

  &::-webkit-scrollbar-thumb {
    background: #888; // Цвет бегунка
    border-radius: 4px; // Скругление бегунка
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; // Цвет бегунка при наведении
  }
`

const Timeline = ({ items }) => {
  const treeItems = items?.map(({subreason, created_at, children}, index) => ({
    title: <Vbox><Text size="xsmall">{convertUTCToLocal(created_at)}</Text> {children}</Vbox>,
    key: `0-${index}`,
    children: subreason ? [
      {
        title: subreason?.label,
        key: `0-${index}-0`
      }
    ] : []
  }));

  return <Container>
    <StyledTree
      showLine
      treeData={treeItems}
    />
  </Container>
};

export default Timeline;
