import PageWrapper from "@shared/ui/layouts/PageWrapper";
import ContentSwitcher from "@shared/ui/ContentSwitcher";
import Trafic from "./elements/Trafic";
import Traders from "./elements/Traders";
import Requisites from "./elements/Requisites";
import {useSearchParams} from "react-router-dom";
import Automatic from "./elements/Automatic";
import Agents from "./elements/Cascades";
import Cascades from "./elements/Cascades";
import Shops from "./elements/Shops";


const items = [
    {
        key: "trafic",
        label: "Трафик",
        children: <Trafic />
    },
    {
        key: "traders",
        label: "Трейдеры",
        children: <Traders />
    },
    {
        key: "requisites",
        label: "Реквизиты",
        children: <Requisites />
    },
    {
        key: "automatic",
        label: "Автоматика",
        children: <Automatic />
    },
    {
        key: "cascade",
        label: "Каскады",
        children: <Cascades />
    },
    {
        key: "shops",
        label: "Магазины",
        children: <Shops />
    },
];


const Statistics = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const section = searchParams.get("section");

    return <PageWrapper title="Статистика">
        <ContentSwitcher
            activeKey={section || items[0].key}
            onChange={(value) => {setSearchParams({section: value})}}
            items={items}
        />
    </PageWrapper>
}




export default Statistics;