import { Controller } from "react-hook-form";
import SimpleInput from "@shared/ui/Simpleinput";


const FioInput = ({control, error}) => {
    return <Controller
        name="fio"
        control={control}
        rules={{ required: "Обязательное поле", minLength: { value: 5, message: "Минимум 5 символов" }, maxLength: { value: 70, message: "Максимум 70 символов" } }}
        render={({ field }) => (
            <SimpleInput
                {...field}
                label="ФИО"
                error={error}
                placeholder="Фамилия Имя Отчество"
            />
        )}
    />
}

export default FioInput;